import { Menu, Input, Dropdown, Tabs, message, Badge, Tag } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import request from "superagent";
import { getAuthHeader } from "../../../utils/superagent";
import { logEvent } from "../../../utils/segment/logEvents";
import { ADMIN_EVENTS } from "../../../constants/firebaseEvents";
import { errorMessage } from "../../../utils/errors";
import "./accountSwitcher.css";
import {
  AGENTS_LIMIT_FOR_FIRST_LOAD,
  AGENTS_LIMIT_FOR_SUBSEQUENT_LOADS,
  FACILITIES_LIMIT_FOR_FIRST_LOAD,
  FACILITIES_LIMIT_FOR_SUBSEQUENT_LOADS,
} from "./constants";
import { WORKER_STAGES_ENUM } from "../../../utils/worker";
import { FacilityStatusObj } from "../../../utils/facility";

const { Search } = Input;
const { TabPane } = Tabs;

const AccountSwitcher = ({ className, admin, pseudo, closeMenu }) => {
  const [agentDetails, setAgentDetails] = useState({
    list: [],
    count: 0,
    loading: true,
  });
  const [facilityDetails, setFacilityDetails] = useState({
    list: [],
    count: 0,
    loading: true,
  });
  const [searchName, setSearchName] = useState();

  const [accountsListVisible, setAccountsListVisible] = useState(false);

  useEffect(() => {
    fetchAgentList(0, AGENTS_LIMIT_FOR_FIRST_LOAD);
    fetchFacilityList(0, FACILITIES_LIMIT_FOR_FIRST_LOAD);
  }, [searchName]);

  const fetchAgentList = useCallback(
    async (append = 0, limit = AGENTS_LIMIT_FOR_FIRST_LOAD) => {
      setAgentDetails((details) => ({ ...details, loading: true }));

      try {
        const { body } = await request
          .get(`${global.api}/user/agentSearch`)
          .set(await getAuthHeader())
          .query({
            skip: append,
            limit,
            searchInput: searchName,
          });

        if (append) {
          setAgentDetails((details) => ({
            list: [...details.list, ...body.list],
            count: details.count,
            loading: false,
          }));
        } else {
          setAgentDetails(() => ({ ...body, loading: false }));
        }
      } catch (err) {
        message.error(errorMessage(err));
        setAgentDetails((details) => ({ ...details, loading: false }));
      }
    },
    [searchName]
  );

  const fetchFacilityList = useCallback(
    async (append = 0, limit = FACILITIES_LIMIT_FOR_FIRST_LOAD) => {
      setFacilityDetails((details) => ({ ...details, loading: true }));

      try {
        const { body } = await request
          .get(`${global.api}/user/facilitySearch`)
          .set(await getAuthHeader())
          .query({ skip: append, limit, searchInput: searchName });

        if (append) {
          setFacilityDetails((details) => ({
            list: [...details.list, ...body.list],
            count: details.count,
            loading: false,
          }));
        } else {
          setFacilityDetails(() => ({ ...body, loading: false }));
        }
      } catch (err) {
        message.error(errorMessage(err));
        setFacilityDetails((details) => ({ ...details, loading: false }));
      }
    },
    [searchName]
  );

  const handleOnDropdownVisible = (open) => {
    setAccountsListVisible(open);
    if (!open) return;
    logEvent(ADMIN_EVENTS.OPEN_SEARCH);
  };

  const searchAccounts = debounce((name) => {
    setSearchName(name);
    logEvent(ADMIN_EVENTS.FILTER_SEARCH, { user_input: name });
  }, 800);

  const handleChange = (value, type) => {
    pseudo({ userId: value });
    if (closeMenu) {
      closeMenu();
    }
    if (type === "AGENT") {
      logEvent(ADMIN_EVENTS.SELECT_HCP, {
        id: value,
      });
    } else if (type === "FACILITY") {
      logEvent(ADMIN_EVENTS.SELECT_HCF, {
        id: value,
      });
    }
    setAccountsListVisible(false);
  };

  const optionGroup = (type, userDetails, callback, limit) => {
    const listCount = userDetails?.list.length || 0;
    const moreResults = userDetails.count - listCount;

    if (!userDetails.loading && !listCount) {
      return (
        <p style={{ paddingBottom: "1rem", color: "#c5c7c7" }}>No Records</p>
      );
    }

    const stageDisplay = (user) => {
      const stage = type === "AGENT" ? user.stage : user.status;

      if (
        [
          WORKER_STAGES_ENUM.SUSPENDED,
          WORKER_STAGES_ENUM.DEACTIVATED,
          FacilityStatusObj.ARCHIVED,
          FacilityStatusObj.SUSPENDED,
          FacilityStatusObj.TERMINATED,
        ].includes(stage)
      ) {
        return <Tag color="red">{stage.toUpperCase()}</Tag>;
      }
    };

    const setColor = (user, key) => {
      return user.highlights.includes(key)
        ? "rgba(0, 0, 0, 0.85)"
        : "rgb(197, 199, 199)";
    };

    const userKeys = ["name", "email", "phone", "userId"];

    const SearchAttribute = (user, key) => {
      return user[key] ? (
        <div key={`${user._id}-${key}`} style={{ color: setColor(user, key) }}>
          {user[key]}
        </div>
      ) : null;
    };

    return (
      <div>
        {userDetails?.list.map((user) => (
          <div
            className="account-select"
            onClick={() => handleChange(user.userId, type)}
            key={user._id}
          >
            {userKeys.map((key) => SearchAttribute(user, key))}
            {stageDisplay(user)}
          </div>
        ))}
        {userDetails.loading ? (
          <h4>Loading...</h4>
        ) : (
          <p
            className="account-select"
            style={{ color: "#1890FF" }}
            onClick={() => callback(listCount, limit)}
          >
            {moreResults > 0 && `+${moreResults} more results`}
          </p>
        )}
      </div>
    );
  };

  const menu = () => (
    <Menu style={{ padding: ".75rem 0 0 1rem" }}>
      <div
        className="account-select admin-account"
        onClick={() => handleChange(admin, "ADMIN")}
      >
        ADMINISTRATOR
      </div>
      <div className="account-groups-list">
        <div>
          <h4 className="account-group">Agent</h4>
          <div className="accounts-list">
            {optionGroup(
              "AGENT",
              agentDetails,
              fetchAgentList,
              AGENTS_LIMIT_FOR_SUBSEQUENT_LOADS
            )}
          </div>
        </div>
        <div>
          <h4 className="account-group">Facility</h4>
          <div className="accounts-list">
            {optionGroup(
              "FACILITY",
              facilityDetails,
              fetchFacilityList,
              FACILITIES_LIMIT_FOR_SUBSEQUENT_LOADS
            )}
          </div>
        </div>
      </div>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      overlayClassName="account-dropdown"
      onVisibleChange={handleOnDropdownVisible}
      visible={accountsListVisible}
    >
      <Search
        placeholder="Select Account"
        className={className + " account-switcher"}
        e2e="account-switcher"
        onChange={(e) => {
          setAccountsListVisible(true);
          searchAccounts(e.target.value);
        }}
        loading={agentDetails.loading || facilityDetails.loading}
      />
    </Dropdown>
  );
};

export { AccountSwitcher };
