import { DeleteOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { logout } from "../../../modules/session";
import {
  Button,
  Input,
  message,
  Modal,
  Switch,
  Spin,
  Alert,
  Form,
  Card,
} from "antd";
import { isEmpty, isEqual, omitBy } from "lodash";
import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import validator from "email-validator";

import { errorMessage } from "../../../utils/errors";
import {
  createNewUser,
  removeUser,
  activateUser,
  updateUserInfo,
  findUserWithEmail,
  linkNewUser,
  unLinkUser,
} from "../api";
import { NotificationInput } from "./notificationInput";
import { RolesInput } from "./rolesInput";
import { getActiveUsers } from "./../../../api/facilityUsers";
import { FACILITY_ROLES } from "./../../../constants/facility";

const ModifyUser = ({ userToEdit, onUpdate, facilityId }) => {
  const [loading, setLoading] = useState(false);
  const { userId, admin, user } = useSelector((state) => state.session);
  const [currentEmail, setCurrentEmail] = useState();
  const [foundUser, setFoundUser] = useState();
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [hasFacilityAdmin, setHasFacilityAdmin] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    const getFacilityUsers = async () => {
      await getActiveUsers(facilityId).then(({ body }) => {
        const hasFacilityAdmin = body.users.some(({ roles }) =>
          roles.includes(FACILITY_ROLES.ADMIN)
        );
        setHasFacilityAdmin(hasFacilityAdmin);
      });
    };
    getFacilityUsers();
  }, [facilityId]);

  const createNotifyRoles = (userInfo) => {
    let alertSettings = null;
    let notifySettings = { EMAIL: {}, SMS: {} };

    if (userInfo["granularControl"]) {
      const keys = Object.keys(userInfo["granularControl"]);

      alertSettings = keys.reduce((accOuter, key) => {
        const values = userInfo["granularControl"][key].reduce((acc, item) => {
          if (item.action !== "All" && !notifySettings[key][item.role]) {
            notifySettings[key][item.role] = item.default.enabled;
          }

          return { ...acc, [item.action]: item.default };
        }, {});
        return { ...accOuter, [key]: { ...values } };
      }, {});
    }

    userInfo.alertSettings = alertSettings;
    userInfo.notify = notifySettings;

    return userInfo;
  };

  const shouldLogoutUserAfterSubmit = useMemo(() => {
    if (userToEdit._id === user._id && currentEmail !== userToEdit.email) {
      return true;
    }
    return false;
  }, [userToEdit, user, currentEmail]);

  const createUser = async (state) => {
    setLoading(true);
    try {
      state = createNotifyRoles(state);

      const userInfo = await createNewUser({
        user: state,
        facilityId: facilityId || userId,
        performedBy: admin || user,
      });
      onUpdate(userInfo);
    } catch (error) {
      message.error(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const linkUser = async () => {
    if (!foundUser) {
      return;
    }
    setLoading(true);
    try {
      const userInfo = await linkNewUser({
        userId: foundUser._id,
        facilityId: facilityId || userId,
      });
      onUpdate(userInfo);
    } catch (error) {
      message.error(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (state) => {
    let userInfo = omitBy(state, (value, key) =>
      isEqual(userToEdit[key], value)
    );

    userInfo = createNotifyRoles(userInfo);

    if (isEmpty(userInfo)) {
      onUpdate(state);
      return;
    }

    setLoading(true);
    try {
      const result = await updateUserInfo({
        userId: userToEdit._id,
        firebaseId: userToEdit.firebaseId,
        userInfo,
        facilityId: facilityId || userId,
        performedBy: admin || user,
      });
      onUpdate(result);
      if (shouldLogoutUserAfterSubmit) {
        logout();
      }
    } catch (error) {
      message.error(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const confirmRemoveUser = async () => {
    setLoading(true);
    try {
      const result = await removeUser({
        userId: userToEdit._id,
        firebaseId: userToEdit.firebaseId,
        performedBy: admin || user,
        facilityId: facilityId || userId,
      });
      onUpdate(result);
    } catch (error) {
      message.error(errorMessage(error));
    } finally {
      setLoading(false);
      setShowRemoveUserModal(false);
    }
  };

  const confirmUnLinkUser = async () => {
    setLoading(true);
    try {
      const result = await unLinkUser({
        userId: userToEdit._id,
        facilityId: facilityId || userId,
      });
      onUpdate(result);
    } catch (error) {
      message.error(errorMessage(error));
    } finally {
      setLoading(false);
      setShowRemoveUserModal(false);
    }
  };

  const closeRemoveUserModal = () => {
    setShowRemoveUserModal(false);
  };

  const onRemoveClick = () => {
    if (userToEdit.facilities.length < 2) {
      Modal.confirm({
        title: `Do you want to remove ${userToEdit.name}?`,
        onOk: confirmRemoveUser,
      });
    } else {
      setShowRemoveUserModal(true);
    }
  };

  const confirmActivateUser = async () => {
    setLoading(true);
    try {
      const result = await activateUser({
        userId: userToEdit._id,
        firebaseId: userToEdit.firebaseId,
        performedBy: admin || user,
        facilityId: facilityId || userId,
      });
      onUpdate(result);
    } catch (error) {
      message.error(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onActivateClick = () => {
    Modal.confirm({
      title: `Do you want to activate ${userToEdit.name}?`,
      onOk: confirmActivateUser,
    });
  };

  const onSave = (values) => {
    if (foundUser) {
      return linkUser();
    }

    if (isEmpty(userToEdit)) {
      createUser(values);
    } else {
      updateUser(values);
    }
  };

  const checkRole = async (rule, value) => {
    if (isEmpty(value)) {
      throw new Error("Please select role");
    }
    return;
  };

  useEffect(() => {
    if (userToEdit.email) {
      setCurrentEmail(userToEdit.email);
    }
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      setCheckingEmail(true);
      const user = await findUserWithEmail({
        facilityId: facilityId || userId,
        email: currentEmail,
      });
      setFoundUser(user);
      if (user) {
        form.setFieldsValue({
          name: user.name,
          phone: user.phone,
          designation: user.designation,
          notes: user.notes,
          primary: user.primary,
          roles: user.roles || [],
          notify: user.notify || { SMS: {}, EMAIL: {} },
        });
      }

      setCheckingEmail(false);
    };

    if (validator.validate(currentEmail)) {
      getUserDetails();
    } else {
      setFoundUser(null);
    }
  }, [currentEmail, facilityId, userId]);

  const onEmailChange = (event) => {
    const email = event.target.value;
    setCurrentEmail(email);
  };

  const facilityNames =
    Array.isArray(userToEdit.facilities) &&
    userToEdit.facilities.map((f) => f.name).join(", ");
  const foundUserDetails = foundUser || {};
  return (
    <Fragment>
      <Form
        form={form}
        labelCol={{ xs: { span: 6 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 18 }, sm: { span: 18 } }}
        onFinish={onSave}
        initialValues={{
          email: userToEdit.email,
          name: userToEdit.name || foundUserDetails.name,
          phone: userToEdit.phone || foundUserDetails.phone,
          designation: userToEdit.designation || foundUserDetails.designation,
          notes: userToEdit.notes || foundUserDetails.notes,
          primary: userToEdit.primary || foundUserDetails.primary,
          roles: userToEdit.roles || foundUserDetails.roles || [],
          granularControl: { EMAIL: [], SMS: [] },
          legacyControl: userToEdit.notify ||
            foundUserDetails.notify || { EMAIL: {}, SMS: {} },
        }}
      >
        {foundUser && (
          <Alert
            style={{ marginBottom: "10px" }}
            type="warning"
            message="User with this email address already exists. Do you want to link user to this Facility?"
          />
        )}
        <Form.Item
          label="Email"
          name="email"
          validateTrigger="onBlur"
          onBlur={onEmailChange}
          rules={[
            {
              type: "email",
              message: "Invalid email!",
            },
            {
              required: true,
              message: "Please input email!",
            },
          ]}
        >
          <Input
            onChange={onEmailChange}
            type="email"
            addonBefore={checkingEmail && <Spin size="small" />}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name!",
              whitespace: true,
            },
          ]}
        >
          <Input disabled={foundUser} />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          validateTrigger="onBlur"
          rules={[{ len: 10, message: "Invalid phone!" }]}
        >
          <Input type="tel" maxLength={10} disabled={foundUser} />
        </Form.Item>

        <Form.Item label="Designation" name="designation">
          <Input disabled={foundUser} />
        </Form.Item>
        {!hasFacilityAdmin && (
          <Card
            style={{
              borderColor: "#ff4d4f",
              marginBottom: "20px",
              backgroundColor: "#ff4d4f1a",
            }}
          >
            This account has no user with ADMIN permission and may create
            limitations to the user experience. Please make sure to add admin
            permission to a user or escalate this issue to the appropriate team.
          </Card>
        )}
        <Form.Item
          label="Roles"
          name="roles"
          rules={[{ required: true, validator: checkRole }]}
        >
          <RolesInput disabled={foundUser} />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.roles !== currentValues.roles
          }
        >
          {({ getFieldValue }) => (
            <Form.Item label="Notifications" name="granularControl">
              <NotificationInput
                userGranularSettings={
                  userToEdit.alertSettings ||
                  foundUserDetails.alertSettings || { EMAIL: {}, SMS: {} }
                }
                userLegacySettings={getFieldValue("legacyControl")}
                roles={getFieldValue("roles")}
                disabled={foundUser}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} className="align-center">
          {userToEdit._id && !userToEdit.archived && (
            <Button
              type="danger"
              ghost={true}
              size="large"
              icon={<DeleteOutlined />}
              loading={loading}
              onClick={onRemoveClick}
              style={{ marginRight: "10px" }}
            >
              Remove User
            </Button>
          )}
          {userToEdit.archived && (
            <Button
              type="primary"
              ghost={true}
              size="large"
              icon={<UndoOutlined />}
              loading={loading}
              onClick={onActivateClick}
              style={{ marginRight: "10px" }}
            >
              Activate User
            </Button>
          )}
          {foundUser ? (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<SaveOutlined />}
              loading={loading}
            >
              Link User
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<SaveOutlined />}
              loading={loading}
            >
              Save {shouldLogoutUserAfterSubmit && " & log out"}
            </Button>
          )}
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
          style={{ marginTop: -24 }}
          className="align-center"
        >
          {shouldLogoutUserAfterSubmit && (
            <span style={{ color: "#ff4d4f" }}>
              After editing your own email you must log out and log back in with
              the new email address
            </span>
          )}
        </Form.Item>
      </Form>
      <Modal
        title={`User ${userToEdit.name} is linked to multiple Facilities!`}
        onCancel={closeRemoveUserModal}
        visible={showRemoveUserModal}
        destroyOnClose={true}
        width="60%"
        footer={[
          <Button
            key="cancel"
            size="large"
            loading={loading}
            onClick={closeRemoveUserModal}
          >
            Cancel
          </Button>,
          <Button
            key="unlink"
            type="primary"
            ghost={true}
            size="large"
            icon={<DeleteOutlined />}
            loading={loading}
            onClick={confirmUnLinkUser}
          >
            Unlink User
          </Button>,
          <Button
            key="suspend"
            type="danger"
            ghost={true}
            size="large"
            icon={<DeleteOutlined />}
            loading={loading}
            onClick={confirmRemoveUser}
          >
            Suspend User
          </Button>,
        ]}
      >
        <div>
          <p>
            Linked Facilities :{" "}
            <strong>
              <em>{facilityNames}</em>
            </strong>
          </p>
          <p>
            Do you want suspend user from all the Facilities or unlink it from
            this Facility only
          </p>
        </div>
      </Modal>
    </Fragment>
  );
};

export { ModifyUser };
