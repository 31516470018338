import { createHttpLink } from "@apollo/client";
import meta from "../utils/meta";
import { getAuthHeader } from "../utils/superagent";
import { setContext } from "@apollo/client/link/context";

const { documentServiceUrl } = meta();

const httpLink = createHttpLink({
  uri: documentServiceUrl,
});

const authLink = setContext(async () => {
  const token = await getAuthHeader();
  return {
    headers: {
      ...token,
    },
  };
});

const link = authLink.concat(httpLink);

export { link };
