import { Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import { UserList } from "./userList";

const { TabPane } = Tabs;

const UserTab = (props) => {
  const { admin } = useSelector((state) => state.session);

  if (!admin) {
    return <UserList {...props} isArchived={false} />;
  }

  return (
    <Tabs defaultActiveKey="active">
      <TabPane tab="Active" key="active" data-testid="active-user-list-tab">
        <UserList {...props} isArchived={false} />
      </TabPane>
      <TabPane
        tab="Suspended"
        key="suspended"
        data-testid="suspended-user-list-tab"
      >
        <UserList {...props} isArchived={true} />
      </TabPane>
    </Tabs>
  );
};

export { UserTab };
