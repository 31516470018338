export const workerTypes = [
  {
    label: "CNA",
    value: "CNA",
  },
  {
    label: "LVN / LPN",
    value: "LVN",
  },
  {
    label: "RN",
    value: "RN",
  },
  {
    label: "NP",
    value: "NP",
  },
  {
    label: "SITTER",
    value: "SITTER",
  },
  {
    label: "PACU RN",
    value: "PACU RN",
  },
  {
    label: "OR RN",
    value: "OR RN",
  },
  {
    label: "SPD TECH",
    value: "SPD TECH",
  },
  {
    label: "SURG TECH",
    value: "SURG TECH",
  },
  {
    label: "CRNA",
    value: "CRNA",
  },
  {
    label: "Phlebotomist",
    value: "Phlebotomist",
  },
  {
    label: "MLT",
    value: "MLT",
  },
  {
    label: "Medical Assistant",
    value: "Medical Assistant",
  },
  {
    label: "PT",
    value: "PT",
  },
  {
    label: "PTA",
    value: "PTA",
  },
  {
    label: "HHA",
    value: "HHA",
  },
];

export const timezones = [
  { value: "America/Los_Angeles", display: "San Francisco (Pacific)" },
  { value: "America/Denver", display: "Denver (Mountain)" },
  { value: "America/Chicago", display: "Chicago (Central)" },
  { value: "America/New_York", display: "New York (Eastern)" },
];

export const FacilityTypes = {
  SURGERY_CENTER: "Surgery Center",
  LONG_TERM_CARE: "Long Term Care",
  HOSPICE: "Hospice",
  HOME_HEALTHCARE: "Home Healthcare",
  PSYCH_HOSPITAL: "Psych Hospital",
  MEDICAL_LAB: "Medical Lab",
};

export const facilityTypes = Object.values(FacilityTypes);

export const shiftTypes = ["AM", "PM", "NOC"];

export const facilityDataObject = {
  name: "",
  email: "",
  phone: "",
  type: "Long Term Care",
  fullAddress: {
    streetNumber: "400",
    streetName: "Oak Street",
    city: "San Francisco",
    region: "San Francisco County",
    state: "California",
    stateCode: "CA",
    country: "United States",
    countryCode: "US",
    postalCode: "94102",
    formatted: "400 Oak St, San Francisco, CA 94102, USA",
  },
  geoLocation: { type: "Point", coordinates: [-122.426094, 37.77478199999999] },
  metropolitanStatisticalArea: "San Francisco-Oakland-Fremont, CA",
  tmz: "America/Los_Angeles",
  payOnHoliday: true,
  payOnAllHolidays: true,
  rates: { CNA: "40", LVN: "40", RN: "40", NP: "40", SITTER: "40" },
  requirementIds: [],
  rushFee: { differential: "5", period: "2" },
  lateCancellation: { period: "2", feeHours: "10" },
  netTerms: "5",
  disputeTerms: "5",
  ratesTable: {
    sunday: {},
    monday: {},
    tuesday: {},
    wednesday: {},
    thursday: {},
    friday: {},
    saturday: {},
  },
  holidayFee: { type: "FLAT", increment: 20 },
  hazardCharge: {
    covid: {
      pay: 50,
      charges: { CNA: "5", LVN: "5", RN: "5", NP: "5", SITTER: "5" },
    },
  },
};

export const fakeAddress = {
  manualMsa: false,
  streetNumber: "401",
  streetName: "Oak Street",
  city: "San Francisco",
  region: "San Francisco County",
  state: "California",
  stateCode: "CA",
  country: "United States",
  countryCode: "US",
  postalCode: "94102",
  formatted: "401 Oak St, San Francisco, CA 94102, USA",
  metropolitanStatisticalArea: "San Francisco-Oakland-Fremont, CA",
};

export const defaultEmail = "test.automation@clipboardhealth.com";
