import { Mutex } from "async-mutex";
import { logErrors } from "../components/errorBoundary/api";
import { defaultWorkerTypes } from "./defaultWorkerTypes";
import { defaultWorkPlaceTypes } from "./defaultWorkPlaceTypes";
import request from "superagent";
import { getAuthHeader } from "../utils/superagent";

class WorkerAndWorkPlaceTypesFacade {
  #localStorageName = "WorkerAndWorkPlaceTypes";

  #syncMutex = new Mutex();

  #lastUpdatedAt = Date.now();

  constructor() {
    this.#initialize();
    this.#syncData();
  }

  #initialize() {
    const existingData = JSON.parse(
      localStorage.getItem(this.#localStorageName)
    );
    if (!existingData) {
      this.#saveTypesToLocalStorage(defaultWorkerTypes, defaultWorkPlaceTypes);
    }
  }

  #saveTypesToLocalStorage(workerTypes, workPlaceTypes) {
    localStorage.setItem(
      this.#localStorageName,
      JSON.stringify({
        workerTypes,
        workPlaceTypes,
      })
    );
  }

  get allTypes() {
    try {
      if (this.#shouldSyncData()) {
        this.#runSynchronousSyncData();
      }

      const lastSavedData = JSON.parse(
        localStorage.getItem(this.#localStorageName)
      );
      if (!lastSavedData) {
        return {
          workerTypes: defaultWorkerTypes,
          workPlaceTypes: defaultWorkPlaceTypes,
        };
      }
      return lastSavedData;
    } catch (error) {
      logErrors({
        message: `[FrontendAdmin-WorkerAndWorkPlace-allTypes] - Error getting data from local storage - ${error?.stack}`,
        app: "Admin",
      });
      return {
        workerTypes: defaultWorkerTypes,
        workPlaceTypes: defaultWorkPlaceTypes,
      };
    }
  }

  get workerTypes() {
    try {
      return this.allTypes.workerTypes.map((workerType) => workerType.name);
    } catch (error) {
      logErrors({
        message: `[FrontendAdmin-WorkerAndWorkPlace-workerTypes] - Error computing workerTypes - ${error?.stack}`,
        app: "Admin",
      });
      return [];
    }
  }

  get workPlaceTypes() {
    try {
      return this.allTypes.workPlaceTypes.reduce(
        (acc, workPlaceType) => ({
          ...acc,
          [workPlaceType.name]: workPlaceType.workerTypes.map(
            (workerType) => workerType.name
          ),
        }),
        {}
      );
    } catch (error) {
      logErrors({
        message: `[FrontendAdmin-WorkerAndWorkPlace-workPlaceTypes] - Error computing workPlaceTypes - ${error?.stack}`,
        app: "Admin",
      });
      return {};
    }
  }

  async #runSynchronousSyncData() {
    try {
      if (this.#syncMutex.isLocked()) return;
      await this.#syncMutex.runExclusive(async () => await this.#syncData());
    } catch (error) {
      logErrors({
        message: `[FrontendAdmin-WorkerAndWorkPlace-workPlaceTypes] - Error running runSynchronousSyncData - ${error?.stack}`,
        app: "Admin",
      });
    }
  }

  async #syncData() {
    try {
      const {
        body: { data },
      } = await request
        .get(`${process.env.REACT_APP_BASE_API_URL}/workerAndWorkPlaceTypes`)
        .set(await getAuthHeader())
        .send();

      if (!data) {
        logErrors({
          message: `[FrontendAdmin-WorkerAndWorkPlace-syncData] - Empty data from API - ${data} - ${error?.stack}`,
          app: "Admin",
        });
      }
      const { workerTypes, workPlaceTypes } = data;
      this.#saveTypesToLocalStorage(workerTypes, workPlaceTypes);
      this.#lastUpdatedAt = Date.now();
    } catch (error) {
      logErrors({
        message: `[FrontendAdmin-WorkerAndWorkPlace-syncData] - Error syncing data - ${error?.stack}`,
        app: "Admin",
      });
    }
  }

  #shouldSyncData() {
    try {
      const TTL = Number(
        process.env.REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS || 300
      );
      return (Date.now() - this.#lastUpdatedAt) / 1000 >= TTL;
    } catch (error) {
      logErrors({
        message: `[FrontendAdmin-WorkerAndWorkPlace-shouldSyncData] - Error checking whether to sync data - ${error?.stack}`,
        app: "Admin",
      });
      return false;
    }
  }

  /**
   * @deprecated
   * this is a computed value used by the existing infrastructure
   */
  get workerTypeObj() {
    return this.allTypes.workerTypes.reduce(
      (acc, workerType) => ({
        ...acc,
        [workerType.name.toUpperCase().replace(/ /g, "_")]: workerType.name,
      }),
      {}
    );
  }

  /**
   * @deprecated
   * this is a computed value used by the existing infrastructure
   */
  get workerTypeDefaults() {
    return this.allTypes.workerTypes.reduce(
      (acc, type) => ({ ...acc, [type]: 0 }),
      {}
    );
  }
}

export { WorkerAndWorkPlaceTypesFacade };
