import _ from "lodash";

export const nameFromEmail = (email, includeClipboard = false) => {
  if (_.isEmpty(email)) return "";

  const [name] = email.split("@");
  return `${_.capitalize(name)} ${includeClipboard ? "Clipboard" : ""}`;
};

export const formatPhone = (phone) => {
  if (_.isEmpty(phone)) return "";

  let formattedPhone = phone.replace(/\D+/g, "");

  if (formattedPhone.length !== 10) return phone;

  formattedPhone = formattedPhone.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );

  return formattedPhone;
};

export const capitalizeFirstLetter = (string) => {
  return (string ?? "").charAt(0).toUpperCase() + (string ?? "").slice(1);
};

export const fullNameToFirstLast = (name) => {
  const nameArray = _.split(name, " ");
  const firstName = _.dropRight(nameArray).join(" ");
  const lastName = _.last(nameArray);

  return { firstName, lastName };
};
