import request from "superagent";
import { getAuthHeader } from "../../utils/superagent";

const createNewUser = async (data) => {
  return request
    .post(`${global.api}/facilityUser`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const updateUserInfo = async (data) => {
  return request
    .put(`${global.api}/facilityUser`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const removeUser = async (data) => {
  return request
    .delete(`${global.api}/facilityUser`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const activateUser = async (data) => {
  return request
    .post(`${global.api}/facilityUser/activate`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const fetchUserCount = async (query) => {
  const { body } = await request
    .get(`${global.api}/facilityUser/count`)
    .set(await getAuthHeader())
    .query(query);
  return body.count;
};

const fetchUsers = async (query) => {
  const { body } = await request
    .get(`${global.api}/facilityUser/list`)
    .set(await getAuthHeader())
    .query(query);
  return body;
};

const findUserById = async (id) => {
  const { body } = await request
    .get(`${global.api}/facilityUser/${id}`)
    .set(await getAuthHeader())
    .send();
  return body;
};

const findUserWithEmail = async ({ facilityId, email }) => {
  try {
    const { body } = await request
      .get(`${global.api}/facilityUser/findByEmailAddUser`)
      .set(await getAuthHeader())
      .query({ facilityId, email });
    return body.user;
  } catch (err) {
    console.error("Couldn't find user", err);
    return null;
  }
};

const linkNewUser = async (data) => {
  return request
    .put(`${global.api}/facilityUser/linkNewUser`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const unLinkUser = async (data) => {
  return request
    .put(`${global.api}/facilityUser/unLinkUser`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

export {
  createNewUser,
  updateUserInfo,
  fetchUserCount,
  fetchUsers,
  removeUser,
  activateUser,
  findUserWithEmail,
  findUserById,
  linkNewUser,
  unLinkUser,
};
