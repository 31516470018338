import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Input,
  Select,
  Button,
  DatePicker,
  PageHeader,
  Divider,
  Typography,
  message,
  Alert,
} from "antd";
import { _recordShiftTime } from "./common/fakerService";
import "./css/shift_action.css";
import { SHIFT_ACTION_DEFAULT_YAML_VALUE } from "./constants/shift";
import MonacoEditor from "react-monaco-editor";
import yaml from "yaml";
import ShouldRender from "./components/ShouldRender";
import moment from "moment";
import "moment-timezone";

const { Title } = Typography;

const ShiftAction = ({ selectedRegressionTestData = {} }) => {
  const [loading, setLoading] = useState(false);
  const [respStatus, setRespStatus] = useState(0);
  const [form] = Form.useForm();
  const [shiftActionsYAMLData, setShiftActionsYAMLData] = useState(
    SHIFT_ACTION_DEFAULT_YAML_VALUE
  );

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { shiftActions: shiftActionsData } = yaml.parse(
        selectedRegressionTestData.config
      );
      setShiftActionsYAMLData(
        yaml.stringify({ shiftActions: shiftActionsData })
      );
    } else if (selectedRegressionTestData.id) {
      setShiftActionsYAMLData("shiftActions:\n  -");
    }
  }, [selectedRegressionTestData]);

  const editorDidMount = (editor, monaco) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onSubmit = async (val) => {
    setLoading(true);
    setRespStatus(0);

    try {
      const { shiftActions } = yaml.parse(shiftActionsYAMLData);
      let shiftActionsPromises = [];

      shiftActions.forEach((shiftAction) => {
        const { shiftId, actionTime, action } = shiftAction;
        shiftActionsPromises.push(
          _recordShiftTime(shiftId, actionTime, action)
        );
      });

      let shiftActionsResponses = await Promise.all(shiftActionsPromises);

      shiftActionsResponses.forEach((shiftActionsResponse, index) => {
        let resp = JSON.parse(shiftActionsResponse.text);
        if (resp && resp.success) {
          setRespStatus(1);
          message.success(
            `${shiftActions[index].action} shift action completed successfully`
          );
        } else {
          setRespStatus(2);
          message.error(
            `${shiftActions[index].action}  shift actions did not complete successfully`
          );
        }
      });
    } catch (error) {
      setRespStatus(2);
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="shiftActionPageHeader"
        title="Shift Action"
        subtitle="shift action"
        extra={[
          <Button
            type="secondary"
            onClick={() => {
              window
                .open(
                  `https://dev.to/paulasantamaria/introduction-to-yaml-125f`
                )
                .focus();
            }}
          >
            Intro to YAML
          </Button>,
        ]}
      />
      <Divider className="shiftActionHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <Title level={4}>YAML Config</Title>
            <div>
              <ShouldRender condition={selectedRegressionTestData.name}>
                <Alert
                  style={{ marginBottom: ".5rem" }}
                  message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                  type="info"
                  showIcon
                />
              </ShouldRender>
            </div>
          </Row>
          <Row>
            <MonacoEditor
              height="400"
              language="javascript"
              theme="vs-dark"
              value={shiftActionsYAMLData}
              options={options}
              editorDidMount={editorDidMount}
              onChange={(value) => {
                setShiftActionsYAMLData(value);
              }}
            />
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default ShiftAction;
