import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Button,
  PageHeader,
  Divider,
  Typography,
  message,
} from "antd";
import ShouldRender from "./ShouldRender";
import axios from "axios";

const { Title } = Typography;

const TriggerQfa = () => {
  const [isGetData, setIsGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hcpId, setHcpid] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getAgentProfile();
  }, []);

  const getAgentProfile = async () => {
    setIsGetData(true);
    try {
      if (localStorage.getItem("authToken")) {
        const agent = await axios.get(
          `${process.env.REACT_APP_API_MAIN_URL || ""}/api/agentProfile`,
          {
            headers: {
              Authorization: localStorage.getItem("authToken"),
            },
          }
        );
        if (agent.data) {
          setHcpid(agent.data.userId);
        }
      } else if (JSON.parse(localStorage.getItem("state")).type === "AGENT") {
        let userID = JSON.parse(localStorage.getItem("state")).userId;
        setHcpid(userID);
      } else {
        message.error(`Cannot get HCP id`);
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      setIsGetData(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const resp = await axios.post(
        `${
          process.env.REACT_APP_API_MAIN_URL || ""
        }/api/testHelpers/account/triggerqfa`,
        {
          hcpId: hcpId,
        }
      );
      if (resp.data && resp.data.success) {
        message.success(`Triggered QFA successfully`);
      } else {
        message.error(`Failed to change address and trigger QFA`);
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div>
      <PageHeader
        className="pageHeader"
        title="Trigger QFA"
        subtitle="Trigger QFA"
      />
      <Divider className="TriggerQFAHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <Title level={4}>Trigger QFA</Title>
          </Row>
          <Row>
            {isGetData ? (
              <div>Getting required data...</div>
            ) : (
              <ShouldRender condition={hcpId}>
                <div>
                  This action will change the address once and then back to
                  original to trigger QFA cron job in order to show open shifts
                  for HCP. Sometimes it might still take up to 1 min to see the
                  shifts.
                </div>
              </ShouldRender>
            )}
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default TriggerQfa;
