import meta from "../utils/meta";
const FACILITY_ROLES = {
  ADMIN: "ADM",
  SHIFT_MANAGEMENT: "SFT",
  DOCUMENTS: "DMT",
  INVOICES: "INV",
};
const ALERT_TYPE = {
  EMAIL: "EMAIL",
  SMS: "SMS",
};

const CHARGE_RATE_VISIBLE_EMAIL = "CHARGE_RATE_VISIBLE_EMAIL";

const FACILITY_NOTIFICATIONS = {
  EMAIL: {
    INV_EMAIL: {
      validRoles: [FACILITY_ROLES.INVOICES],
      mandatoryRoles: [FACILITY_ROLES.INVOICES, FACILITY_ROLES.ADMIN],
      alertTypes: [
        {
          default: {
            enabled: true,
            batch: false,
            userModifiableBatch: false,
            userModifiableState: false,
            time: 16,
          },
          type: ALERT_TYPE.EMAIL,
          title: "Invoice Email",
          action: "invoiceEmail",
          role: "INV_EMAIL",
          mandatoryRoles: [FACILITY_ROLES.INVOICES, FACILITY_ROLES.ADMIN],
          validRoles: [FACILITY_ROLES.INVOICES],
        },
      ],
      type: ALERT_TYPE.EMAIL,
    },
    SFT_EMAIL: {
      validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
      alertTypes: [
        {
          default: {
            enabled: true,
            notifyRole: "SFT_EMAIL",
            userModifiableBatch: true,
            userModifiableState: true,
            batch: false,
            time: 16,
          },
          type: ALERT_TYPE.EMAIL,
          key: "SFTASMT",
          title: "Shift Assignment",
          action: "assignmentEmail",
          role: "SFT_EMAIL",
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
        {
          default: {
            enabled: true,
            notifyRole: "SFT_EMAIL",
            userModifiableBatch: true,
            userModifiableState: true,
            batch: false,
            time: 16,
          },
          type: ALERT_TYPE.EMAIL,
          key: "SFTUNA",
          title: "Shift Unassign",
          action: "shiftUnassignmentEmail",
          role: "SFT_EMAIL",
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
        {
          default: {
            enabled: true,
            notifyRole: "SFT_EMAIL",
            batch: false,
            userModifiableBatch: false,
            userModifiableState: true,
            time: 16,
          },
          type: ALERT_TYPE.EMAIL,
          key: "FCSTF",
          title: "Facility Staffing Summary",
          action: "staffingSummary",
          role: "SFT_EMAIL",
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
      ],
      type: ALERT_TYPE.EMAIL,
    },
    VERIFIED_SUMMARY_EMAIL: {
      validRoles: [
        FACILITY_ROLES.SHIFT_MANAGEMENT,
        FACILITY_ROLES.INVOICES,
        FACILITY_ROLES.DOCUMENTS,
      ],
      alertTypes: [
        {
          disableBatching: true,
          mandatoryRoles: [FACILITY_ROLES.ADMIN],
          default: {
            enabled: true,
            userModifiableBatch: false,
            userModifiableState: true,
            batch: false,
            time: 16,
          },
          type: ALERT_TYPE.EMAIL,
          key: "WVSS",
          title: "Weekly Verified Shifts Summary",
          action: "weeklyVerifiedShiftsSummary",
          role: "VERIFIED_SUMMARY_EMAIL",
          validRoles: [
            FACILITY_ROLES.SHIFT_MANAGEMENT,
            FACILITY_ROLES.INVOICES,
            FACILITY_ROLES.DOCUMENTS,
          ],
        },
      ],
      type: ALERT_TYPE.EMAIL,
    },
    CHARGE_RATE_VISIBLE_EMAIL: {
      validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
      alertTypes: [
        {
          disableBatching: true,
          default: {
            enabled: true,
            userModifiableBatch: false,
            userModifiableState: true,
            batch: false,
            time: 16,
          },
          type: ALERT_TYPE.EMAIL,
          key: "CRV",
          title: "Charge Rates Visibility",
          action: "chargeRateVisibility",
          role: CHARGE_RATE_VISIBLE_EMAIL,
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
      ],
      type: ALERT_TYPE.EMAIL,
    },
  },
  SMS: {
    SFT_SMS: {
      validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
      alertTypes: [
        {
          default: {
            enabled: true,
            batch: false,
            notifyRole: "SFT_SMS",
            userModifiableBatch: true,
            userModifiableState: true,
            time: 16,
          },
          type: ALERT_TYPE.SMS,
          key: "SSFTASMT",
          title: "Shift Assignment SMS",
          action: "assignmentSMS",
          role: "SFT_SMS",
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
        {
          default: {
            enabled: true,
            notifyRole: "SFT_SMS",
            batch: false,
            userModifiableBatch: true,
            userModifiableState: true,
            time: 16,
          },
          type: ALERT_TYPE.SMS,
          key: "SSFTUA",
          title: "Shift Unassign SMS",
          action: "shiftUnassignmentSMS",
          role: "SFT_SMS",
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
        {
          default: {
            enabled: true,
            notifyRole: "SFT_SMS",
            batch: false,
            userModifiableBatch: false,
            userModifiableState: false,
            time: 16,
          },
          type: ALERT_TYPE.SMS,
          key: "SSIGCBH",
          title: "Signature Request (By Us)",
          action: "shiftCBHSignatureRequestSMS",
          role: "SFT_SMS",
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
        {
          default: {
            enabled: true,
            notifyRole: "SFT_SMS",
            batch: false,
            userModifiableBatch: false,
            userModifiableState: true,
            time: 16,
          },
          type: ALERT_TYPE.SMS,
          key: "SSCHATMSG",
          title: "Chat messages",
          action: "chatMessages",
          role: "SFT_SMS",
          validRoles: [FACILITY_ROLES.SHIFT_MANAGEMENT],
        },
      ],
      type: ALERT_TYPE.SMS,
    },
  },
};

const FACILITY_ROLE_DESCRIPTIONS = {
  [FACILITY_ROLES.ADMIN]:
    "This permission gives the user access to all permissions and additionally gives the user the ability to edit and remove additional users for this facility.",
  [FACILITY_ROLES.SHIFT_MANAGEMENT]:
    "This permission gives the user the ability to request and cancel shifts.",
  [FACILITY_ROLES.DOCUMENTS]:
    "This permission gives the user the ability to see HCPs' documents and last four of social security number.",
  [FACILITY_ROLES.INVOICES]:
    "This permission means that this user will receive a copy of the invoice via email.",
};

const HOLIDAY_INPUT_PROPS = {
  FLAT_PROPS: {
    description: "How much is that flat increase?",
    regex: /\.(.*?[0-9]*)/g,
    label: " dollars per hour",
  },
  MULTIPLIER_PROPS: {
    description: "How much is that Multiplier increase?",
    regex: "/.dd/",
    label: " times the charge rate",
  },
};

const HOLIDAY_DIFFERENTIAL = {
  FLAT: "FLAT",
  CHARGE_RATE_MULTIPLIER: "CHARGE_RATE_MULTIPLIER",
};

const truncateToOneDecimal = (value) => {
  let decimalValue = 0.0;
  if (typeof value === "number") {
    return value.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
  }
  return decimalValue;
};

const RATES_TABLE = {
  sunday: { am: 0, pm: 0, noc: 0, surg: 0 },
  monday: { am: 0, pm: 0, noc: 0, surg: 0 },
  tuesday: { am: 0, pm: 0, noc: 0, surg: 0 },
  wednesday: { am: 0, pm: 0, noc: 0, surg: 0 },
  thursday: { am: 0, pm: 0, noc: 0, surg: 0 },
  friday: { am: 0, pm: 0, noc: 0, surg: 0 },
  saturday: { am: 0, pm: 0, noc: 0, surg: 0 },
};

const PERMISSIONS = {
  NOTES: { NOTE_EDIT: "NOTE_EDIT" },
};

const INSTANT_BOOK_TYPE = {
  ON: "ON",
  OFF: "OFF",
  PAST_HCPS_ONLY: "PAST_HCPS_ONLY",
};

const batchDescription = {
  EMAIL:
    "Batch all emails in the preceding 24 hours and send to me at this time",
  SMS: "Batch all Text Messages in the preceding 24 hours and send to me at this time",
};

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const FACILITY_PREFERENCE_BASE_URL = `${
  meta().shiftVerificationBaseUrl
}/facilities`;

export {
  FACILITY_ROLES,
  INSTANT_BOOK_TYPE,
  FACILITY_NOTIFICATIONS,
  FACILITY_ROLE_DESCRIPTIONS,
  HOLIDAY_DIFFERENTIAL,
  HOLIDAY_INPUT_PROPS,
  truncateToOneDecimal,
  RATES_TABLE,
  PERMISSIONS,
  batchDescription,
  hours,
  FACILITY_PREFERENCE_BASE_URL,
  CHARGE_RATE_VISIBLE_EMAIL,
};
