import React, { useEffect, useState } from "react";

import { getZendeskJWTMessaging } from "../api/zendesk";

type JwtFnCallback = (token: string) => void;

declare global {
  interface Window {
    zE: Function;
  }
}

const useZendeskMessaging = (
  isFacility: Boolean,
  admin: Boolean
): { webWidgetRendered: Boolean } => {
  const [webWidgetRendered, setWebWidgetRendered] = useState<boolean>(false);

  // Doc ref - https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/#authentication
  useEffect(() => {
    if (!webWidgetRendered) return;
    window.zE("messenger", "loginUser", function (callback: JwtFnCallback) {
      getZendeskJWTMessaging().then(callback);
    });
  }, [webWidgetRendered]);

  useEffect(() => {
    if (!isFacility || admin) return;
    // Doc ref - https://support.zendesk.com/hc/en-us/articles/4500748175258-Installing-the-Web-Widget-in-a-website-or-Help-Center
    const script = document.createElement("script");
    script.id = process.env.REACT_APP_ZENDESK_WEB_WIDGET_ID ?? "";
    script.src = process.env.REACT_APP_ZENDESK_MESSAGING_WIDGET_URL ?? "";
    document.body.appendChild(script);
    script.addEventListener("load", () => setWebWidgetRendered(true));

    return () => {
      document.body.removeChild(script);
      window.zE("messenger", "logoutUser");
    };
  }, [isFacility, admin]);

  return { webWidgetRendered };
};

export default useZendeskMessaging;
