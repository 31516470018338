import { Tag } from "antd";
import _ from "lodash";
import React from "react";

const TableFilterTags = ({ dataSource, onClose }) => (
  <div style={{ padding: " 10px", minHeight: "46px" }}>
    {dataSource.map((source) => (
      <Tag
        color="geekblue"
        closable
        onClose={onClose(source)}
        key={`${source.key}-${source.value}`}
      >
        {_.capitalize(source.key)}: {source.displayValue}
      </Tag>
    ))}
  </div>
);

export { TableFilterTags };
