/**
 * renders the children if condition is met
 * @param {boolean} condition
 * @param  {...any} props
 * @returns React.Component
 */
const ShouldRender = ({ condition, children }) => {
  if (condition) {
    return children;
  }
  return null;
};

export default ShouldRender;
