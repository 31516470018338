export const initBraze = async (userId: string) => {
  const braze = (window as any).braze;
  if (braze && userId) {
    await braze.initialize(process.env.REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY, {
      baseUrl: process.env.REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT,
      manageServiceWorkerExternally: true,
      safariWebsitePushId: process.env.REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID,
    });

    braze.subscribeToInAppMessage(function (inAppMessage) {
      if (inAppMessage instanceof braze.InAppMessage) {
        const keyValuePairs = inAppMessage.extras || {};

        // If this is our push primer message
        // Second check of these conditions
        if (keyValuePairs["msg-id"] === "push-primer") {
          if (
            !braze.isPushSupported() ||
            braze.isPushPermissionGranted() ||
            braze.isPushBlocked()
          ) {
            // do not show the message because user/browser is not eligible
            return;
          }

          // the browser is eligible to request push permission
          // register a callback when the left-button is clicked
          if (inAppMessage.buttons[0] != null) {
            // Prompt the user when the first button is clicked
            inAppMessage.buttons[0].subscribeToClickedEvent(function () {
              braze.requestPushPermission();
            });
          }

          // show the in-app message now
          braze.showInAppMessage(inAppMessage);
        } else {
          // If this is not our push primer message
          braze.showInAppMessage(inAppMessage);
        }
      }
    });

    braze.changeUser(userId);
    braze.openSession();
    if (
      !(
        !braze.isPushSupported() ||
        braze.isPushPermissionGranted() ||
        braze.isPushBlocked()
      )
    ) {
      // We are logging this custom event to display push primer.
      // Push primer is an in-app-message that we display to the user to request the push notification permission softly.
      // https://www.braze.com/docs/developer_guide/platform_integration_guides/web/push_notifications/soft_push_prompt/
      braze.logCustomEvent("prime-for-push");
    }
  } else {
    return;
  }
};
