import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Input,
  message,
  Button,
  PageHeader,
  Divider,
  Typography,
  Spin,
  Alert,
} from "antd";
import axios from "axios";
import "../css/create_hcf.css";
import { HCF_DEFAULT_YAML_VALUE } from "../constants/hcf";
import yaml from "yaml";
import MonacoEditor from "react-monaco-editor";
import ShouldRender from "./ShouldRender";

const { Title } = Typography;

const { TextArea } = Input;
const CreateHcp = ({ selectedRegressionTestData = {} }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [HCFYAMLData, setHCFYAMLData] = useState(HCF_DEFAULT_YAML_VALUE);

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { hcf: hcfData } = yaml.parse(selectedRegressionTestData.config);
      setHCFYAMLData(yaml.stringify({ hcf: hcfData }));
    } else if (selectedRegressionTestData.id) {
      setHCFYAMLData("hcf:\n");
    }
  }, [selectedRegressionTestData]);

  const editorDidMount = (editor, monaco) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const hcfData = yaml.parse(HCFYAMLData);

      axios
        .post(
          `${
            process.env.REACT_APP_API_MAIN_URL || ""
          }/api/testHelpers/createHcf`,
          {
            ...hcfData.hcf,
          }
        )
        .then(() => {
          message.success("HCF Created!");
        })
        .catch(function (e) {
          message.error(`Error: ${e.response.data}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      message.error(`Error adding HCF -  ${error.message}`);
      setLoading(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  return (
    <Spin spinning={loading}>
      <div>
        <PageHeader
          className="createHCFHeader"
          title="Create HCF"
          subtitle="Generate a new HCF"
          extra={[
            <Button
              type="secondary"
              key={"key"}
              onClick={() => {
                window
                  .open(
                    `https://dev.to/paulasantamaria/introduction-to-yaml-125f`
                  )
                  .focus();
              }}
            >
              Intro to YAML
            </Button>,
          ]}
        />
        <Divider className="createHCFHeaderDivider" />
        <Row
          justify="start"
          align="middle"
          style={{ width: "100%", height: "100%" }}
        >
          <Form
            {...layout}
            name="basic"
            form={form}
            onFinish={onSubmit}
            style={{ width: "600px" }}
          >
            <Row>
              <Title level={4}>YAML Config</Title>
              <div>
                <ShouldRender condition={selectedRegressionTestData.name}>
                  <Alert
                    style={{ marginBottom: ".5rem" }}
                    message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                    type="info"
                    showIcon
                  />
                </ShouldRender>
              </div>
            </Row>
            <Row>
              <MonacoEditor
                height="400"
                language="javascript"
                theme="vs-dark"
                value={HCFYAMLData}
                options={options}
                editorDidMount={editorDidMount}
                onChange={(value) => {
                  setHCFYAMLData(value);
                }}
              />
            </Row>

            <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Row>
          </Form>
        </Row>
      </div>
    </Spin>
  );
};

export default CreateHcp;
