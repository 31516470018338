import { Modal } from "antd";
import React from "react";

import { ModifyUser } from "./modifyUser";

const ModifyFacilityUserModal = ({
  visible,
  onUpdate,
  userToEdit,
  onCancel,
  facilityId,
}) => (
  <Modal
    title={userToEdit ? `Update ${userToEdit.name}` : "New User"}
    visible={visible}
    footer={null}
    width="75%"
    bodyStyle={{ height: "70vh", "overflow-y": "auto" }}
    maskClosable={false}
    destroyOnClose={true}
    onCancel={onCancel}
  >
    <ModifyUser
      onUpdate={onUpdate}
      userToEdit={userToEdit || {}}
      facilityId={facilityId}
    />
  </Modal>
);

export { ModifyFacilityUserModal };
