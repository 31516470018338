import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Row,
  Button,
  PageHeader,
  Divider,
  Typography,
  message,
  Checkbox,
} from "antd";

const { Title } = Typography;

const DownloadCSV = () => {
  const [isGetData, setIsGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState("");
  const [selectedInv, setSelected] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    setIsGetData(true);
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_MAIN_URL || ""}/api/invoice`,
        {
          headers: {
            Authorization: localStorage.getItem("AUTH_TOKEN"),
          },
        }
      );
      if (resp.data) {
        setInvoices(resp.data.invoiceList);
      } else {
        message.error(
          `Can't get invoices, make sure you are logged in as Admin`
        );
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(
        `Test panel Error ${error.message} - Can't get invoices, make sure you are logged in as Admin`
      );
    } finally {
      setIsGetData(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const list = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      axios({
        url: `${
          process.env.REACT_APP_API_MAIN_URL || ""
        }/api/testHelpers/downloadCsv`,
        method: "POST",
        data: { invoices, selectedInv },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoice-Summary.csv");
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e) => {
    if (e.target.checked) {
      setSelected([...selectedInv, e.target.name]);
    } else {
      setSelected(selectedInv.filter((i) => i !== e.target.name));
    }
  };

  return (
    <div>
      <PageHeader
        className="pageHeader"
        title="Approve Documents"
        subtitle="Approve Documents"
      />
      <Divider className="ApproveDocsHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <Title level={4}>Get CSV</Title>
          </Row>
          <Row>
            <span>Select invoices:</span>
            {isGetData || !invoices ? (
              <div>Getting required data...</div>
            ) : (
              <div style={list}>
                {invoices.map((i) => {
                  return (
                    <Checkbox
                      onChange={onChange}
                      name={i.invoiceNumber}
                      key={i.invoiceNumber}
                    >
                      #{i.invoiceNumber} - {i.facility.name}
                    </Checkbox>
                  );
                })}
              </div>
            )}
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Download
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default DownloadCSV;
