import { createHttpLink } from "@apollo/client";
import { mockSingleLink } from "@apollo/client/testing";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { defaultEmail } from "../constants";
import fetch from "cross-fetch";

const httpLink =
  // HACK - using the actual createHttpLink() makes Jest tests fail
  process.env.NODE_ENV === "test"
    ? mockSingleLink()
    : createHttpLink({
        uri:
          process.env.REACT_APP_DOCUMENTS_SERVICE_URL ??
          process.env.DOCUMENTS_SERVICE_URL,
        fetch,
      });

const authLink = setContext(async () => {
  const getToken = await axios.post(
    `${
      process.env.REACT_APP_BASE_API_URL ?? process.env.API_BASE_URL
    }/testHelpers/token?email=${defaultEmail}`
  );
  return {
    headers: {
      Authorization: getToken.data.token,
    },
  };
});

const link = authLink.concat(httpLink);

export { link };
