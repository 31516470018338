import "firebase/analytics";
import firebase from "firebase/app";

class FirebaseAppAnalytics {
  analytics;

  init(firebaseConfig) {
    let FirebaseApp;
    if (!firebase.apps.length) {
      FirebaseApp = firebase.initializeApp(firebaseConfig);
    } else {
      FirebaseApp = firebase.app();
    }
    this.analytics = FirebaseApp.analytics();
  }

  setUserProperties(options) {
    this.analytics.setUserId(options.userId);
    this.analytics.setUserProperties({
      name: options.name,
      ...options.userInfo,
    });
  }

  setScreenName(screenName) {
    this.analytics && this.analytics.setCurrentScreen(screenName);
  }

  logEvent(eventName, options) {
    this.analytics.logEvent(eventName, options);
  }
}

const firebaseAppAnalytics = new FirebaseAppAnalytics();

export { firebaseAppAnalytics };
