import { useEffect } from "react";
import { FirebaseAuth } from "../utils/firebase";
import { message } from "antd";

const blankPage = () => {
  useEffect(() => {
    const token = localStorage.getItem("customToken");
    const signIn = async () => {
      if (token) {
        try {
          await FirebaseAuth.signInWithCustomToken(token);
        } catch (error) {
          message.error("Error signing in with custom token");
        }
      }
    };
    signIn();
  }, []);
  return <></>;
};

export { blankPage };
