import { datadogRum } from "@datadog/browser-rum";
import meta from "./meta";

const { datadogRUM } = meta();

export const initDatadogRUM = () => {
  datadogRum.init(datadogRUM);
};

export const startDatadogRUMReplayRecording = () => {
  datadogRum.startSessionReplayRecording();
};

export const removeDatadogRUMUser = () => {
  datadogRum.removeUser();
};

export const identifyDatadogRUMUser = (session) => {
  datadogRum.setUser({
    id: session?.profile?.userId || "",
    name: session?.profile?.name || "",
    email: session?.profile?.email || "",
    userType: session?.type,
  });
};
