import request from "superagent";
import { getAuthHeader } from "../../utils/superagent";

export const logErrors = async (data) => {
  if (!process.env.REACT_APP_LOG_ERRORS) {
    return;
  }
  await request
    .post(`${global.api}/logErrors`)
    .set(await getAuthHeader())
    .send(data);
};
