import React, { useEffect, Fragment } from "react";
import {
  FileFilled,
  ArrowLeftOutlined,
  EyeFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { PageHeader, Button } from "antd";

import {
  SendBirdProvider,
  Channel,
  sendBirdSelectors,
  withSendBird,
  useSendbirdStateContext,
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import SendBird from "sendbird";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useFlags } from "launchdarkly-react-client-sdk";
import "./chatStyle.scss";
import {
  fetchAndSetUpcomingShifts,
  setChannels,
  updateChannel,
  fetchAndSetShiftReviews,
} from "../../modules/chat";
import meta from "../../utils/meta";
import { logEvent } from "../../utils/segment/logEvents";
import { HCF_USER_EVENTS } from "../../constants/firebaseEvents";
import { ReviewSentHomeMessage } from "./ReviewSentHomeMessage";
import { SentHomeResponseMessage } from "./SentHomeResponseMessage";
import CustomChatMessage from "./components/customChatMessage";
import { MessageCustomTypes } from "./chat.types";

const appId = meta().sendBirdAppId;

const CHAT_CHANNEL_QUERY_LIMIT = 100;

const getLastMessageDate = (channel) => {
  const useTimestamp = channel.lastMessage
    ? channel.lastMessage.createdAt
    : channel.createdAt;
  const lastMessage = moment(useTimestamp);

  if (moment().add(-1, "day").startOf("day").isAfter(lastMessage))
    return lastMessage.format("MM/DD/YYYY");
  if (moment().startOf("day").isAfter(lastMessage)) return "Yesterday";

  return lastMessage.format("hh:mm A");
};

const getLastMessageInfo = ({ lastMessage }, currentUserId) => {
  if (lastMessage) {
    const from = lastMessage.sender ? (
      <b>
        {lastMessage.sender.userId === currentUserId
          ? "You"
          : lastMessage.sender.nickname}
        :{" "}
      </b>
    ) : (
      <></>
    );
    if (lastMessage.messageType === "file")
      return (
        <>
          <FileFilled /> {from} {lastMessage.name}{" "}
        </>
      );

    return (
      <>
        {from} {lastMessage.message}
      </>
    );
  }

  return "No messages";
};

const getStatus = (isChatOpen30DaysAfterLastShift, channel) => {
  if (!channel) return "loading";
  if (!channel.shift) {
    // if the feature flag is disabled or there's no metadata
    if (!isChatOpen30DaysAfterLastShift || !channel.metadata.lastBooked) {
      return "nothing";
    }

    const lastBookedDate = moment(channel.metadata.lastBooked);
    if (moment().diff(lastBookedDate, "days") > 30) {
      return "closed_after_30_days";
    } else {
      return "past";
    }
  }

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const tomorrowEndOfDay = moment().add(1, "day").endOf("day");
  const todayEndOfDay = moment().endOf("day");

  if (end.isBefore(moment())) return "past";
  if (start.isAfter(moment())) {
    if (start.isBefore(todayEndOfDay)) return "today";
    if (start.isBefore(tomorrowEndOfDay)) return "tomorrow";
    return "ahead";
  }

  return "in progress";
};

const getChannelShiftTime = (isChatOpen30DaysAfterLastShift, channel) => {
  if (!channel) return "Loading...";
  if (!channel.shift) return "No upcoming shifts";

  const status = getStatus(isChatOpen30DaysAfterLastShift, channel);
  if (["nothing", "closed_after_30_days", "past"].includes(status))
    return "No upcoming shifts";

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const until =
    "until " +
    end.format("h:mm A") +
    ", " +
    end.diff(start, "hours", true) +
    "hrs";
  const at = ` ${start.format("h:mm A")}`;

  if (status === "in progress") return `Shift in progress ${until}`;

  let date = "";
  if (status === "today") date = `today ${at}`;
  if (status === "tomorrow") date = `tomorrow, ${start.format("MMM DD")} ${at}`;
  if (status === "ahead") date = `${start.format("MMM DD, YYYY")}, ${at}`;

  return "Next shift: " + date;
};

const channelTitle = (channel) => {
  const workerName = channel?.name.split("-").map((t) => t.trim())[1];
  return [
    channel?.metadata?.hcpName || channel?.shift?.agent?.name || workerName,
    channel?.metadata?.agentReq,
  ]
    .filter(Boolean)
    .join(", ");
};

/**
 * @param {Object} props
 * @param {SendBird.SendBirdInstance} props.sdk
 */
const ChatInternal = ({ sdk }) => {
  const { userId, user } = useSelector((state) => state.session);

  const history = useHistory();
  const { agentId, facilityId } = useParams();
  const { channels } = useSelector((state) => state.chat);
  const { admin } = useSelector((state) => state.session);
  const currentChannelUrl = facilityId ? facilityId + "_" + agentId : "";
  const currentChannel = channels.find(
    (channel) => channel.url === currentChannelUrl
  );
  const dispatch = useDispatch();
  const ldFlags = useFlags();
  const isChatOpen30DaysAfterLastShift =
    ldFlags["channel-open-30-days-after-last-booked-shift"];
  const channelStatus = getStatus(
    isChatOpen30DaysAfterLastShift,
    currentChannel
  );

  useEffect(() => {
    if (!admin) {
      logEvent(HCF_USER_EVENTS.CHAT_OPENED);
    }

    if (sdk) fetchChannels(sdk, dispatch);
  }, []);

  const channelClick = (newChannelUrl) => {
    const [facilityId, agentId] = newChannelUrl.split("_");
    history.push(`/facility/chat/${facilityId}/${agentId}`);
  };

  return (
    <div
      className={`chat-container chat-style ${
        channelStatus === "nothing" ? "disable-chat" : ""
      } ${
        channelStatus === "closed_after_30_days"
          ? "disable-chat after-30-days"
          : ""
      }`}
    >
      <div className={"chat " + (currentChannelUrl ? "selected-chat" : "")}>
        <div className="channels-container">
          <div className="channels-header">Channels</div>
          {channels.map((channel, index, array) => {
            const classes = ["channel"];

            if (channel.url === currentChannelUrl) classes.push("selected");

            if (index > 0) classes.push("border"); // Not First
            if (index === array.length - 1) classes.push("shadow"); // Last

            return (
              <div
                key={channel.url}
                className={classes.join(" ")}
                onClick={() => channelClick(channel.url)}
              >
                <div className="channel-name">
                  <b>{channelTitle(channel)}</b>
                </div>
                <div className="channel-last">
                  {getLastMessageDate(channel)}
                </div>
                <div className="channel-message">
                  {getLastMessageInfo(channel, sdk?.currentUser?.userId)}
                </div>
                {channel.unreadMessageCount > 0 ? (
                  <div className="channel-unread">
                    {channel.unreadMessageCount}
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="channel-upcoming-shift">
                  {getChannelShiftTime(isChatOpen30DaysAfterLastShift, channel)}
                </div>
              </div>
            );
          })}
        </div>

        <Channel
          renderCustomMessage={(message) => {
            if (message.customType === MessageCustomTypes.FCM_REQUEST)
              return () => (
                <ReviewSentHomeMessage
                  message={message}
                  facilityId={userId}
                  facilityUserId={user._id}
                />
              );
            if (
              message.customType === MessageCustomTypes.FCM_APPROVAL ||
              message.customType === MessageCustomTypes.FCM_REJECT
            )
              return () => <SentHomeResponseMessage message={message} />;

            if (message.customType === MessageCustomTypes.SHIFT_REVIEW)
              return () => (
                <CustomChatMessage
                  message={message}
                  currentChannel={currentChannel}
                ></CustomChatMessage>
              );

            return null;
          }}
          channelUrl={currentChannelUrl}
          renderChatHeader={(props) => (
            <div className="custom-header-title">
              <ArrowLeftOutlined
                className="close-channel"
                onClick={() => history.push(`/facility/chat`)}
              />
              <div>
                {channelTitle(currentChannel)} -{" "}
                {getChannelShiftTime(
                  isChatOpen30DaysAfterLastShift,
                  currentChannel
                )}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const ChatWithSendBird = withSendBird(ChatInternal, (state) => {
  return { sdk: sendBirdSelectors.getSdk(state) };
});

export const ChatModal = ({ channelUrl, closeModal }) => {
  const { channels } = useSelector((state) => state.chat);
  const ldFlags = useFlags();
  const isChatOpen30DaysAfterLastShift =
    ldFlags["channel-open-30-days-after-last-booked-shift"];
  const currentChannel = channels.find((channel) => channel.url === channelUrl);
  return (
    <Fragment>
      <Modal
        className="chat-style"
        footer={<Fragment />}
        visible={channelUrl}
        bodyStyle={{ height: 688, maxHeight: "78vh", paddingTop: 50 }}
        onCancel={() => closeModal()}
      >
        <Channel
          renderCustomMessage={(message) => {
            if (message.customType !== MessageCustomTypes.SHIFT_REVIEW)
              return null;
            return () => (
              <CustomChatMessage
                message={message}
                currentChannel={currentChannel}
              ></CustomChatMessage>
            );
          }}
          channelUrl={channelUrl}
          renderChatHeader={(props) => (
            <div className="custom-header">
              <div className="custom-header-title">
                {channelTitle(currentChannel)}
              </div>
              {getChannelShiftTime(
                isChatOpen30DaysAfterLastShift,
                currentChannel
              )}
            </div>
          )}
        />
      </Modal>
    </Fragment>
  );
};

export const ChatProvider = ({ children }) => {
  const userId = useSelector((store) => store.session.userId);
  const session = useSelector((store) => store.session);
  const sendBirdAccessToken = useSelector(
    (store) => store.session.sendBirdAccessToken
  );
  const ldFlags = useFlags();
  let sendbirdAppId = appId;

  if (
    !ldFlags["chat"] ||
    !session.profile ||
    session.type === "ADMIN" ||
    session.admin
  ) {
    sendbirdAppId = "";
  }

  return (
    <SendBirdProvider
      appId={sendbirdAppId}
      userId={userId}
      nickname={session.profile?.name}
      accessToken={sendBirdAccessToken}
    >
      <ChatProviderWithStore>{children}</ChatProviderWithStore>
    </SendBirdProvider>
  );
};

const ChatProviderWithStore = ({ children }) => {
  const dispatch = useDispatch();
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  useEffect(() => {
    if (!sdk || !sdk.GroupChannel) return;

    const uuid = uuidv4();

    var channelHandler = new sdk.ChannelHandler();

    channelHandler.onChannelChanged = (channel) => {
      updateChannel(dispatch, channel);
    };

    channelHandler.onUserReceivedInvitation = (channel) => {
      updateChannel(dispatch, channel);
      fetchAndSetUpcomingShifts(dispatch);
      fetchAndSetShiftReviews(dispatch);
    };

    channelHandler.onMessageReceived = (channel, message) => {
      if (message.isAdminMessage()) {
        fetchAndSetUpcomingShifts(dispatch);
        fetchAndSetShiftReviews(dispatch);
      }
    };

    sdk.addChannelHandler(uuid, channelHandler);

    return () => sdk?.removeChannelHandler?.(uuid);
  }, [sdk]);

  useEffect(async () => {
    fetchChannels(sdk, dispatch);
  }, [sdk]);

  return children;
};

export const fetchChannels = async (sdk, dispatch) => {
  if (!sdk || !sdk.GroupChannel) return setChannels(dispatch, []);

  const query = sdk.GroupChannel.createMyGroupChannelListQuery();
  query.includeEmpty = true;
  query.memberStateFilter = "joined_only";
  query.order = "latest_last_message";
  query.limit = CHAT_CHANNEL_QUERY_LIMIT;

  const list = await query.next();
  setChannels(dispatch, list);
};

export const ChatPage = ({ match }) => {
  return <ChatWithSendBird match={match} />;
};
