import _ from "lodash";
import moment from "moment-timezone";
import request from "superagent";

import { errorMessage } from "../utils/errors";
import { nameFromEmail } from "../utils/string";
import { FirebaseAuth } from "../utils/firebase";
import { updateFacilityUserAlerts } from "../api/facilityUsers";
import { getAuthHeader } from "../utils/superagent";
import { message } from "antd";
import { firebaseAppAnalytics } from "../utils/firebaseAnalytics";
import meta from "../utils/meta";
import crypto from "crypto-js";
import { identifyDatadogRUMUser } from "../utils/datadogRUM";

export const LOGIN_REQUESTED = "session/LOGIN_REQUESTED";
export const LOGIN_FAILURE = "session/LOGIN_FAILURE";
export const LOGIN_SUCCESS = "session/LOGIN_SUCCESS";
export const REGISTER_FAILURE = "session/REGISTER_FAILURE";
export const LOGOUT = "session/LOGOUT";
export const LOCATION_AGENTS_SUCCESS = "session/LOCATION_AGENTS_SUCCESS";
export const UPDATE_FLAG = "session/UPDATE_FLAG";
export const REACTIVATE_ACCOUNT = "session/REACTIVATE_ACCOUNT";
export const ADMIN_PROFILE = "session/ADMIN_PROFILE";
export const ADMIN_PROFILE_SUCCESS = "session/ADMIN_PROFILE_SUCCESS";
export const FIREBASE_LOGIN_SUCCESS = "FIREBASE_LOGIN_SUCCESS";
export const FIREBASE_LOGOUT = "FIREBASE_LOGOUT";
export const UPDATE_DRAWER = "UPDATE_DRAWER";
export const ADMIN_SESSION_TYPE = "ADMIN";
export const FIREBASE_TOKEN_READY = "FIREBASE_TOKEN_READY";
export const OPEN_SHIFT_CONFIRMATION = "OPEN_SHIFT_CONFIRMATION";
export const SET_BOOKING_ROLLOUT_MSAS = "SET_BOOKING_ROLLOUT_MSAS";
export const HCPS_WITH_PENDING_DOCS = "HCPS_WITH_PENDING_DOCS";

const initialState = {
  userId: "",
  adminUserId: "", // if logged in as admin, enables pseudo account switching
  adminProfile: null,
  loadingAdminProfile: false,
  isLoggingIn: false,
  type: "",
  profile: null,
  loginMessage: "",
  admin: false,
  superadmin: false,
  drawer: {},
  flags: {},
  user: null,
  firebaseToken: null,
  ready: false,
  hcpsWithPendingDocs: null,
};
/*eslint no-case-declarations: 0*/
/*eslint-env es6*/
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        isLoggingIn: true,
        loginMessage: "",
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        loginMessage: action.message || errorMessage(action.data),
      };

    case LOGIN_SUCCESS:
      const user = action.data;

      const enabledStatuses = [
        "Instant Payouts Enabled",
        "Standard Payouts Enabled",
        "No Documents Required",
      ];
      if (
        user.agent &&
        user.agent.employmentStatus !== "W2" &&
        user.agent.paymentAccountInfo &&
        !enabledStatuses.includes(user.agent.paymentAccountInfo.status)
      ) {
        message.warn(
          "This HCP cannot be assigned to a shift until they are enabled for Stripe"
        );
      }
      if (
        user.agent &&
        user.agent.employmentStatus !== "W2" &&
        !user.agent.paymentAccountInfo
      ) {
        message.warn(
          "This HCP cannot be assigned to a shift until they are enabled for Stripe"
        );
      }

      const session = {
        userId: user._id,
        adminUserId: state.adminUserId
          ? state.adminUserId
          : user.admin
          ? user._id
          : "",
        adminProfile: state.adminProfile
          ? state.adminProfile
          : user.admin
          ? user.employee
          : undefined,
        type: user.type,
        profile: user.facility ||
          user.agent ||
          (user.employee && {
            ...user.employee,
            ...(user.employee.name ? {} : { name: nameFromEmail(user.email) }),
          }) || { name: nameFromEmail(user.email) },
        isLoggingIn: false,
        flags: user.flags,
        admin: state.admin ? state.admin : user.admin ? user._id : false, //maintain admin when pseudo
        superadmin: user.superadmin || state.superadmin,
        tz: user.tmz,
        isFirebaseSession: user.isFirebaseSession,
        sendBirdAccessToken: user.sendBirdAccessToken,
        intercomHash: user.intercomHash,
      };

      // Update roles for admin profile without logging out
      if (session.profile && session.profile.roles) {
        session.adminProfile.roles = session.profile.roles;
      }

      // save immutable fields to local storage
      saveToLocalStorage(session);

      moment.tz.setDefault(user.tmz);

      const appDomain = `https://${window.location.hostname}`;
      if (state.admin && !user.admin) {
        //maintain admin when pseudo
        window.FS.identify(state.admin);
      } else if (session.type === "FACILITY") {
        const { user: userInfo } = state;
        if (userInfo) {
          window.FS.identify(userInfo._id, {
            email: userInfo.email,
            type: session.type,
            displayName: user.facility.name,
            facility: user.facility.name,
            profile_url: `${appDomain}/calendar?userId=${userInfo._id}&drawer=Facility`,
          });
        }
      } else {
        // Full story identify - start session
        window.FS.identify(session.userId, {
          email: user.email,
          type: session.type,
          displayName: _.get(
            session,
            "profile.name",
            nameFromEmail(session.email, true)
          ),
          qualification: _.get(session, "profile.qualification", ""),
          profile_url: `${appDomain}/calendar?userId=${session.userId}&drawer=Agent`,
        });
      }

      if (!session.admin && session.type === "FACILITY") {
        const { user: userInfo } = state;
        updateAnalyticsUserProperties({
          userId: userInfo._id,
          name: userInfo.name,
          userInfo: userInfo,
          intercomHash: user.intercomHash,
        });
        window.analytics.group(session.userId, {
          name: session.profile.name,
          groupType: "Workplace",
        });
      } else {
        updateAnalyticsUserProperties({
          userId: session.adminUserId || session.userId,
          name: session.adminUserId
            ? session.adminProfile.name ||
              nameFromEmail(session.adminProfile.email)
            : session.profile.name,
          userInfo: session.adminProfile || session.user,
          intercomHash: user.intercomHash,
        });
      }

      return {
        ...state,
        ...session,
      };

    case FIREBASE_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.data,
      };

    case FIREBASE_LOGOUT:
      if (state.type === "FACILITY") {
        saveToLocalStorage(null);
        // Full story identify - remove session
        window.FS.identify(false);
        return initialState;
      } else {
        return {
          ...state,
        };
      }

    case REGISTER_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        loginMessage: errorMessage(action.data),
      };

    case LOGOUT:
      // reseting redirectUrl to localStorage
      // onIdTokenChanged -> onAuthChanged invokes with null user first when using signinlink
      // TODO - refactor onIdTokenChanged -> onAuthChanged(dispatch) when using signinlink to avoid invoking logout
      const redirectURL = localStorage.getItem("redirectURL");
      localStorage.clear();
      redirectURL && localStorage.setItem("redirectURL", redirectURL);
      // Full story identify - remove session
      window.FS.identify(false);
      return initialState;

    case UPDATE_FLAG:
      const updatedState = {
        ...state,
        flags: {
          ...state.flags,
          [action.data.key]: action.data.value,
        },
      };

      saveToLocalStorage(updatedState);
      return updatedState;

    case REACTIVATE_ACCOUNT:
      return {
        ...state,
        profile: {
          ...state.profile,
          quit: action.data,
        },
      };
    case ADMIN_PROFILE:
      return {
        ...state,
        loadingAdminProfile: true,
      };
    case ADMIN_PROFILE_SUCCESS: {
      const { user } = action.data;
      const session = {
        ...state,
        adminProfile: user.employee,
        ...(state.type === "ADMIN" ? { profile: user.employee } : {}),
        loadingAdminProfile: false,
        intercomHash: user.intercomHash,
      };
      saveToLocalStorage(session);
      return session;
    }
    case UPDATE_DRAWER: {
      return {
        ...state,
        drawer: action.data,
      };
    }
    case FIREBASE_TOKEN_READY: {
      return {
        ...state,
        ready: action.data.ready,
      };
    }

    case HCPS_WITH_PENDING_DOCS:
      return {
        ...state,
        hcpsWithPendingDocs: action.data.hcpsWithPendingDocs,
      };

    default:
      return state;
  }
};

export const logout = () => {
  FirebaseAuth.signOut();
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};

// syntactic sugar for get
export const pseudo = (data, isFirebaseSession) => {
  return (dispatch) => {
    return dispatch(get({ ...data, isFirebaseSession }));
  };
};

// User's session, alternatively takes explicit
export const get = (sessionData) => {
  return async (dispatch, getState) => {
    //TODO dispatch creation requested
    const { userId, admin, superadmin, isFirebaseSession } = sessionData || {};
    const firebaseToken = localStorage.getItem("AUTH_TOKEN");
    if (!userId && firebaseToken) return;

    const { session } = getState();

    if (!userId && !session.userId) {
      return;
    }

    localStorage.setItem("selectedUser", userId);
    dispatch({ type: LOGIN_REQUESTED });
    return request
      .get(`${global.api}/user/get/${userId}`)
      .set(await getAuthHeader())
      .then((res) => {
        if (session.admin && !firebaseToken) {
          dispatch(logout());
          return;
        }

        if (
          !session.admin &&
          res.body.type === "FACILITY" &&
          !(firebaseToken || isFirebaseSession)
        ) {
          dispatch(logout());
          return;
        }

        //add in admin
        if (admin) {
          res.body.admin = admin;
          res.body.superadmin = superadmin;
        }

        dispatch({
          type: LOGIN_SUCCESS,
          data: { ...res.body, isFirebaseSession },
        });
        return;
      })
      .catch((error) => message.error(errorMessage(error)));
  };
};

export const getAdminProfile = () => {
  return async (dispatch) => {
    dispatch({ type: ADMIN_PROFILE });
    const response = await request
      .get(`${global.api}/user/getByEmail`)
      .set(await getAuthHeader());
    dispatch({ type: ADMIN_PROFILE_SUCCESS, data: { user: response.body } });
  };
};

export const updateFlag = (userId, flagInfo) => async (dispatch) => {
  dispatch({ type: UPDATE_FLAG, data: flagInfo });

  await request
    .post(`${global.api}/user/flags`)
    .set(await getAuthHeader())
    .send({ flagInfo, userId });
};

// load state from localStorage
export const loadSession = () => {
  try {
    // "state" in this case is just the session
    const serializedState = localStorage.getItem("state");

    if (serializedState === null || serializedState === "null") {
      return initialState; // reducer will return Redux state, as localstorage is null.
    }
    const session = JSON.parse(serializedState);

    // Workaround to avoid issues with new roles DB field. If user was
    // loggedIn and is an admin, we set the loadingAdminProfile since
    // we are going to fetch the new profile
    const hasSession = session.userId;
    const isAdmin = session.type === "ADMIN";
    const admin = session.admin;
    const adminProfile = session.adminProfile;
    const profile = session.profile;
    if (
      (hasSession && isAdmin && !profile.roles) ||
      (hasSession && !isAdmin && admin && !adminProfile)
    ) {
      session.loadingAdminProfile = true;
    }

    moment.tz.setDefault(session.tz);

    const firebaseToken = localStorage.getItem("AUTH_TOKEN");
    session.firebaseToken = firebaseToken;

    identifyDatadogRUMUser(session);
    return session;
  } catch (err) {
    return initialState;
  }
};

const saveToLocalStorage = (state) => {
  try {
    // remove from from state
    // delete state.user.from;
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // TODO handle error
  }
};

export const updateAnalyticsUserProperties = ({
  userId,
  name,
  userInfo,
  intercomHash,
}) => {
  firebaseAppAnalytics.setUserProperties({ userId, name, userInfo });
  window.analytics.identify(
    userId,
    {},
    {
      integrations: {
        Intercom: {
          user_hash: intercomHash,
        },
      },
    }
  );
};

export const updateDrawer = (drawerLink) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DRAWER,
      data: drawerLink,
    });
  };
};
