import React from "react";
import "./chatStyle.scss";

const SentHomeResponseMessage = ({ message }) => {
  return (
    <div className="fcm-message-container">
      <div className="avatar-container">
        <div className="avatar-wrapper">
          <img src="/bunnyB.png" alt="image" />
        </div>
      </div>
      <div className="message-container">
        <span>Clipboard Health</span>
        <div className="message-wrapper">
          <div>
            <p>{message.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SentHomeResponseMessage };
