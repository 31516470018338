import "./WindowGlobalFix";
import "./App.css";
import "./index.css";

import { ApolloProvider } from "@apollo/client";
import { CloudinaryContext } from "cloudinary-react";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import "./App.css";
import { ErrorBoundary } from "./components/errorBoundary";
import App from "./containers/app";
import { GraphQlClient } from "./graphql/client";
import "./index.css";
import store from "./store";
import meta from "./utils/meta";

import { TestPanel } from "@clipboard/test-helpers/TestPanel";
import { ChatProvider } from "./containers/chat/chat";

if ((process.env.REACT_APP_NODE_ENV || import.meta.env.MODE) !== "production") {
  render(<TestPanel />, document.querySelector("#testPanel"));
}

const target = document.querySelector("#root");

const { api, app, cloudinaryCloudName } = meta();

window.global.api = api;
window.global.app = app;
(async () => {
  const LDProvider = await asyncWithLDProvider({
    user: { anonymous: true },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  });

  render(
    <ErrorBoundary>
      <ApolloProvider client={GraphQlClient}>
        <Provider store={store}>
          <BrowserRouter>
            <CloudinaryContext cloudName={cloudinaryCloudName}>
              <LDProvider>
                <ChatProvider>
                  <App />
                </ChatProvider>
              </LDProvider>
            </CloudinaryContext>
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>,
    target
  );
})();
