export interface ShiftReview {
  additionalFeedback: string;
  createdAt: Date;
  deletedAt?: Date;
  dnrWorker: boolean;
  id: string;
  qualities: string[];
  rating: number;
  shiftId: string;
  updatedAt: Date;
  workerId: string;
  workplaceId: string;
  workplaceUserId: string;
}
export interface ShiftWithReview {
  agent: { name: string };
  agentId: string;
  end: Date;
  externalId: string;
  facility: { name: string };
  facilityId: string;
  shiftReview?: ShiftReview;
  reviewer?: { _id: string; name: string };
  start: Date;
  _id: string;
}
export interface ChatMessage {
  data: string;
  customType: string;
  message: string;
}
export interface ChatChannel {
  url: string;
}
export interface ChatChannelWithReviewList extends ChatChannel {
  shiftWithReviews: ShiftWithReview[];
}

export interface GetShiftFromMessageParams {
  message: ChatMessage;
  currentChannel: ChatChannelWithReviewList;
}

export enum MessageCustomTypes {
  SHIFT_REVIEW = "SHIFT_REVIEW",
  FCM_REQUEST = "FCM_REQUEST",
  FCM_APPROVAL = "FCM_APPROVAL",
  FCM_REJECT = "FCM_REJECT",
}
