import request from "superagent";
import { getAuthHeader } from "../utils/superagent";

export const getZendeskJWTMessaging = async (): Promise<string> => {
  const token = (await getAuthHeader()).Authorization;
  const { text } = await request.get(
    `${process.env.REACT_APP_ZENDESK_JWT_MICROSERVICE_BASE_URL}/zendesk/jwt-messaging/${token}`
  );
  return text;
};
