import request from "superagent";

import { getAuthHeader } from "../../utils/superagent";

const shiftReviewServiceUrl = process.env.REACT_APP_SHIFT_REVIEW_API_URL;

interface ShiftReviewRequest {
  id?: string;
  workplaceUserId: string;
  workerId: string;
  shiftId: string;
  workplaceId: string;
  additionalFeedback?: string;
  rating?: number;
  dnrWorker?: boolean;
  qualities?: string[];
}

interface GetExclusionRequest {
  facilityId: string;
  agentId: string;
}

interface CreateExclusionRequest {
  actionBy: string;
  notes: string;
  reason: string;
  facilityId: string;
  agentId: string;
  adminId: string;
  shiftId?: string;
  shiftReviewId?: string;
}

export const fetchShiftReviews = async (
  queries: Pick<ShiftReviewRequest, "workerId" | "shiftId"> &
    Pick<Partial<ShiftReviewRequest>, "workplaceUserId">
) => {
  const { body } = await request
    .get(`${shiftReviewServiceUrl}/reviews`)
    .set(await getAuthHeader())
    .query(queries);
  return body;
};

export const createShiftReview = async (requestBody: ShiftReviewRequest) => {
  const { body } = await request
    .post(`${shiftReviewServiceUrl}/reviews`)
    .set(await getAuthHeader())
    .send(requestBody);
  return body;
};

export const fetchExclusion = async (requestBody: GetExclusionRequest) => {
  const response = await request
    .get(`${global.api}/exclusion`)
    .set(await getAuthHeader())
    .query(requestBody);
  return response.body;
};

export const createExclusion = async (requestBody: CreateExclusionRequest) => {
  const response = await request
    .post(`${global.api}/exclusion`)
    .set(await getAuthHeader())
    .send(requestBody);
  return response.body;
};

export const deleteExclusion = async (
  exclusionId: string,
  deleteNote?: string
) => {
  const response = await request
    .delete(`${global.api}/exclusion/${exclusionId}`)
    .set(await getAuthHeader())
    .send({ deleteNote });
  return response.body;
};
