import { FormInstance } from "rc-field-form/lib/interface";

export enum DnrAction {
  CREATE = "CREATE",
  DELETE = "DELETE",
}

/**
 * These values are used for analytics purpose to determine, where the component is rendering.
 */
export enum WhoCalledShiftReviewModal {
  /**
   * If the component is rendered, when we are calling it from shift-details screen, select this value.
   */
  SHIFT_DETAILS = "shiftDetails",
  /**
   * If the component is rendered, when we are calling it from chat channel screen, set this value.
   */
  CHAT_CHANNEL = "chatChannel",
}
export interface ShiftReviewProps {
  whoCalledMe: WhoCalledShiftReviewModal;
  shiftId: string;
  workerId: string;
  workplaceUserId: string;
  workplaceId: string;
  workerName: string;
  dnrAction?: DnrAction;
  /**
   * Showed in the shift review modal.
   */
  shiftStartTime: string;
  /**
   * This function is defined in case only when this component is rendered inside a modal to close it.
   */
  closeModal?: () => void;
  /**
   * callback to reload the called dispatch function.
   */
  successCallback?: ({ shiftId: string, rating: number }) => void;
}

export interface ShiftReviewPopConfirmProps {
  /**
   * Helps in conditional rendering text.
   */
  dnrAction: DnrAction;
}

export interface UseShiftReviewProps extends ShiftReviewProps {
  /**
   * To connect the `const [form] = Form.useForm` from `antd`
   * with the rendered `Form`, this is passed from the stateless component.
   */
  form: FormInstance<unknown>;
}

/**
 * enum for only the form fields displayed on the user interface.
 */
export enum ShiftReviewFormInput {
  SHIFT_REVIEW_RATING = "rating",
  SHIFT_REVIEW_ADDITIONAL_FEEDBACK = "additionalFeedback",
  SHIFT_REVIEW_DNR_WORKER = "dnrWorker",
  SHIFT_REVIEW_QUALITIES = "qualities",
}

/**
 * All the reducer actions come under this enum.
 */
export enum ShiftReviewAction {
  UPDATE_RATING = "UPDATE_RATING",
  UPDATE_ADDITIONAL_FEEDBACK = "UPDATE_ADDITIONAL_FEEDBACK",
  UPDATE_DNR_WORKER = "UPDATE_DNR_WORKER",
  UPDATE_DNR_FIELD_TOUCHED = "UPDATE_DNR_FIELD_TOUCHED",
  UPDATE_QUALITIES = "UPDATE_QUALITIES",
  RESET_FORM = "RESET_FORM",
  /**
   * Pre fill the data for shift review update by fetching existing shift-review.
   */
  FILL_FORM_DATA = "FILL_FORM_DATA",
  SUBMITTING_FORM = "SUBMITTING_FORM",
  FORM_SUBMISSION_FAILED = "FORM_SUBMISSION_FAILED",
  UPDATE_FORM_TO_EDIT_MODE = "UPDATE_FORM_TO_EDIT_MODE",
  FORM_SUBMITTED = "FORM_SUBMITTED",
}

export enum ShiftReviewMode {
  /**
   * Only allowed to view the shift review, but not make any update/create actions.
   */
  VIEW = "VIEW",
  /**
   * user can create/update shift review using this action.
   */
  EDIT = "EDIT",
}

export enum ShiftReviewFormSubmissionStage {
  /**
   * Initial state, when no form submission is happening.
   */
  NOT_STARTED = "NOT_STARTED",
  /**
   * Form submission is started.
   */
  STARTED = "STARTED",
  /**
   * Form submission is completed successfully.
   */
  SUCCESS = "SUCCESS",
  /**
   * Form submission failed to complete.
   */
  FAILED = "FAILED",
}

export interface ShiftReviewState {
  rating?: number;
  additionalFeedback: string;
  dnrWorker: boolean;
  qualities: string[];
  showDnr: boolean;
  /**
   * This is updated, whenever user manually touched the dnr checkbox.
   */
  dnrFieldTouched: boolean;
  mode: ShiftReviewMode;
  // If this exists, that mean this worker is already DNRed.
  exclusionId?: string;
  // If we are editing an already existing shift review, this value will be defined.
  shiftReviewId?: string;
  reviewerName?: string;
  isLoading: boolean;
  formSubmissionStage: ShiftReviewFormSubmissionStage;
}
