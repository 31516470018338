const HCF_DEFAULT_YAML_VALUE = `hcf:
    name: Test Facility
    email: testfacility@gmail.com
    phone: 2025550169
    address:
        streetNumber: "400"
        streetName: "Oak Street"
        city: "San Francisco"
        region: "San Francisco County"
        state: "California"
        stateCode: "CA"
        country: "United States"
        countryCode: "US"
        postalCode: "94102"
        formatted: "400 Oak St, San Francisco, CA 94102, USA"
    tmz:  America/Los_Angeles
    msa: 5f8d91e870140340f56a51c9
    type: Home Healthcare
    rates:
        CNA: 20
        LVN: 20
        RN: 20
        NP: 20
        CAREGIVER: 20
        Medical Technician: 21`;

export { HCF_DEFAULT_YAML_VALUE };
