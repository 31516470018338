import { Button, Card, Input, Select } from "antd";
import _ from "lodash";
import React, { useRef } from "react";

import { FilterIcon } from "../components/filterIcon";

const { Option } = Select;

/*
 * General use filter for antd tables
 * See workertable and facilitytable for examples
 * Legacy filter need to be removed soon
 */
export const generalizedFilter = (that, name, onFilter) => ({
  onFilter: onFilter,
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <Card
      actions={[
        <Button type="primary" onClick={confirm} key="search">
          {" "}
          Search
        </Button>,
        <Button onClick={that.handleReset(clearFilters)} key="reset">
          Reset
        </Button>,
      ]}
      size="small"
    >
      <Input
        ref={(ele) => (that.searchInput = ele)}
        placeholder={`Search ${name}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
      />
    </Card>
  ),
  filterIcon: (filtered) => <FilterIcon styled={{ filtered }} />,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => {
        that.searchInput.focus();
      });
    }
  },
});

/*
 * Search filter for tables
 * Compatible with both hooks and class
 */
export const searchFilter = (searchKey, name, onFilter) => {
  let searchInput;

  const SearchInput = (props) => {
    searchInput = useRef(null);

    return <Input ref={searchInput} {...props} />;
  };

  const defaultFilter = (value, record) => {
    const searchValue = _.get(record, searchKey, "");

    return searchValue.toLowerCase().includes(value.toLowerCase());
  };

  const onFilterDropdownVisibleChange = (visible) => {
    if (visible) {
      setTimeout(() => {
        searchInput.current.focus();
      });
    }
  };

  const filterIcon = (filtered) => <FilterIcon styled={{ filtered }} />;

  const filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <Card
      actions={[
        <Button type="primary" onClick={confirm} key="search">
          {" "}
          Search
        </Button>,
        <Button onClick={clearFilters} key="reset">
          Reset
        </Button>,
      ]}
      size="small"
    >
      <SearchInput
        placeholder={`Search ${name}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
      />
    </Card>
  );

  return {
    onFilter: onFilter || defaultFilter,
    filterIcon,
    filterDropdown,
    onFilterDropdownVisibleChange,
  };
};

/*
 * Multiple select filter for tables
 * Compatible with both hooks and class
 */
export const multiSelectFilter = ({ name, dataSource = [], width }) => {
  let searchInput;

  const SelectInput = (props) => {
    searchInput = useRef(null);

    return (
      <Select
        mode="multiple"
        ref={searchInput}
        optionFilterProp="children"
        {...props}
      />
    );
  };

  const onFilterDropdownVisibleChange = (visible) => {
    if (visible) {
      setTimeout(() => {
        searchInput.current.focus();
      });
    }
  };

  const filterIcon = (filtered) => <FilterIcon styled={{ filtered }} />;

  const filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <Card
      actions={[
        <Button type="primary" onClick={confirm} key="search">
          Search
        </Button>,
        <Button onClick={clearFilters} key="reset">
          Reset
        </Button>,
      ]}
      size="small"
    >
      <SelectInput
        style={{ width: width || "200px" }}
        placeholder={`Search ${name}`}
        onChange={setSelectedKeys}
        value={selectedKeys}
      >
        {dataSource.map((data) => (
          <Option key={data._id}>{data.name}</Option>
        ))}
      </SelectInput>
    </Card>
  );

  return {
    filterIcon,
    filterDropdown,
    onFilterDropdownVisibleChange,
  };
};
