import { FACILITY_NOTIFICATIONS } from "../../constants/facility";
import { compact, intersection, map, pick, flatMap } from "lodash";
import {
  FACILITY_ROLES,
  CHARGE_RATE_VISIBLE_EMAIL,
} from "../../constants/facility";

export type FacilityNotificationType = "EMAIL" | "SMS";

type FacilityRolesType = "ADM" | "SFT" | "DMT" | "INV";

export interface FacilityNotificationAlertType {
  default: {
    enabled?: boolean;
    batch?: boolean;
    userModifiableBatch?: boolean;
    userModifiableState?: boolean;
    tempBatchDisable?: boolean;
    time?: number;
  };
  disableBatching?: boolean;
  type: FacilityNotificationType;
  title: string;
  action: string;
  role?: string;
  mandatoryRoles?: string[];
  validRoles?: string[];
}

export interface FacilityNotification {
  alertTypes?: FacilityNotificationAlertType[];
  type: FacilityNotificationType;
}

const addAllToList = (
  values,
  userGranularSettings,
  type
): FacilityNotificationAlertType | boolean => {
  if (
    userGranularSettings &&
    userGranularSettings[type] &&
    userGranularSettings[type]["All"] !== undefined &&
    userGranularSettings[type]["All"].userModifiableState !== undefined &&
    userGranularSettings[type]["All"].userModifiableBatch !== undefined &&
    values[type].length > 0
  ) {
    const val = userGranularSettings[type]["All"];
    return {
      default: {
        tempBatchDisable: val.tempBatchDisable,
        userModifiableState: val.userModifiableState,
        userModifiableBatch: val.userModifiableBatch,
        enabled: val.enabled,
        batch: val.batch,
        time: val.time,
      },
      type,
      title: "All",
      action: "All",
    };
  }
  const addAll = values[type].length > 0;
  const enableNotDisabled = values[type].some(
    (value) => value.default.userModifiableState
  );
  const showBatchEnable = values[type].some(
    (value) => value.default.userModifiableBatch
  );

  const batchState =
    values[type].length === 1
      ? values[type][0].default.batch
      : values[type].every((item) => {
          if (
            item.default.batch === false &&
            item.default.userModifiableBatch === false
          ) {
            return true;
          }
          return item.default.batch;
        });

  const onOffState =
    values[type].length === 1
      ? values[type][0].default.enabled
      : values[type].every((item) => {
          if (
            item.default.enabled === false &&
            item.default.userModifiableState === false
          ) {
            return true;
          }
          return item.default.enabled;
        });

  if (addAll) {
    return {
      default: {
        userModifiableState: enableNotDisabled,
        userModifiableBatch: showBatchEnable,
        enabled: onOffState,
        batch: batchState,
        time: 16,
      },
      type,
      title: "All",
      action: "All",
    };
  }
  return false;
};

const shouldRenderChargeRateVisible = (
  roles: FacilityRolesType[],
  subConfigKey: string
): boolean => {
  if (subConfigKey === CHARGE_RATE_VISIBLE_EMAIL) {
    return (
      intersection(roles, [FACILITY_ROLES.ADMIN, FACILITY_ROLES.INVOICES])
        .length === 0
    );
  } else {
    return true;
  }
};

const restructureFacilityNotifications = (facilityNotifications, roles) => {
  return map(facilityNotifications, (config, key) => {
    return map(config, (subConfig, subConfigKey) => {
      const containsRole = intersection(subConfig.validRoles, roles).length;
      if (containsRole && shouldRenderChargeRateVisible(roles, subConfigKey)) {
        return { ...subConfig };
      }
    });
  }).reduce((acc, item) => [...acc, ...item], []);
};

const findFacilityNotificationByTypeAndAction = (
  type: FacilityNotificationType,
  alertTypeAction: string,
  roles: string[]
) => {
  return flatMap(
    Object.values(FACILITY_NOTIFICATIONS[type]),
    ({ alertTypes }) => [...alertTypes]
  ).find(
    ({ action, validRoles }) =>
      action === alertTypeAction && intersection(validRoles, roles).length // updating values only in case the roles match
  );
};

const overrideFacilityNotificationsAlertSettings = (
  data: FacilityNotification[],
  userGranularSettings,
  roles: string[]
): { EMAIL: FacilityNotification[]; SMS: FacilityNotification[] } => {
  return compact(data).reduce(
    (acc, item) => {
      const alertTypes = item.alertTypes?.map((alertType) => {
        const defaultAlertSettings = findFacilityNotificationByTypeAndAction(
          alertType.type,
          alertType.action,
          roles
        );
        let overRideAlertTypeData = {};
        if (alertType.disableBatching) {
          overRideAlertTypeData["batch"] = false;
          overRideAlertTypeData["userModifiableBatch"] = false;
        }

        if (alertType.mandatoryRoles && alertType.mandatoryRoles.length) {
          if (intersection(alertType.mandatoryRoles, roles).length) {
            overRideAlertTypeData["batch"] = false;
            overRideAlertTypeData["userModifiableBatch"] = false;
            overRideAlertTypeData["userModifiableState"] = false;
            overRideAlertTypeData["enabled"] = true;
          } else {
            overRideAlertTypeData["userModifiableState"] =
              alertType.default.userModifiableState;
          }
        }
        let avInState =
          userGranularSettings &&
          userGranularSettings[alertType.type] &&
          userGranularSettings[alertType.type][alertType.action];
        if (avInState) {
          return {
            ...alertType,
            default: {
              // NOTE:: Do not add `enable` and `batch` parameters to below list.
              ...pick(defaultAlertSettings?.default, [
                "userModifiableState",
                "userModifiableBatch",
                "disableBatching",
                "tempBatchDisable",
                "time",
              ]),
              ...avInState,
              ...overRideAlertTypeData,
            },
          };
        }
        return {
          ...alertType,
          default: {
            ...alertType.default,
            ...overRideAlertTypeData,
          },
        };
      });
      if (!alertTypes) return acc;
      return { ...acc, [item.type]: [...acc[item.type], ...alertTypes] };
    },
    { EMAIL: [], SMS: [] }
  );
};

const fillDefaultAlertSettings = (userGranularSettings, roles) => {
  const restructureData = restructureFacilityNotifications(
    FACILITY_NOTIFICATIONS,
    roles
  );

  const notifyValues = overrideFacilityNotificationsAlertSettings(
    restructureData,
    userGranularSettings,
    roles
  );

  const addAlltoEmail = addAllToList(
    notifyValues,
    userGranularSettings,
    "EMAIL"
  );
  const addAlltoSMS = addAllToList(notifyValues, userGranularSettings, "SMS");

  if (typeof addAlltoEmail === "object" && addAlltoEmail) {
    notifyValues.EMAIL = [{ ...addAlltoEmail }, ...notifyValues.EMAIL];
  }

  if (typeof addAlltoSMS === "object" && addAlltoSMS) {
    notifyValues.SMS = [{ ...addAlltoSMS }, ...notifyValues.SMS];
  }

  return notifyValues;
};

export {
  fillDefaultAlertSettings,
  addAllToList,
  restructureFacilityNotifications,
  overrideFacilityNotificationsAlertSettings,
};
