import React, { memo, useEffect, useState } from "react";
import moment from "moment-timezone";
import { Alert } from "antd";
import { getFacilityRecentSuspensionLog } from "../../api/facility";

const FacilitySuspensionAlert = memo(({ userId }) => {
  const [suspensionTime, setSuspensionTime] = useState(null);
  const [lastLog, setLastLog] = useState(null);
  useEffect(() => {
    if (userId) {
      (async () => {
        const { log } = await getFacilityRecentSuspensionLog(userId);
        setLastLog(log);
        if (log?.createdAt)
          setSuspensionTime(
            moment(log.createdAt).add(72, "hours").format("MM/DD/YYYY h:mm A")
          );
      })();
    }
  }, [userId]);

  const otherSuspensionMessage = (
    <span>
      Your account is SUSPENDED. All future shifts after {suspensionTime} are
      cancelled and you CANNOT post new shifts. Please call us at{" "}
      <a href="tel:(408) 837-0116" target="_blank" rel="noreferrer">
        (408) 837-0116
      </a>{" "}
      or email to{" "}
      <a
        href="mailto:support@clipboardhealth.com"
        target="_blank"
        rel="noreferrer"
      >
        support@clipboardhealth.com
      </a>{" "}
      for assistance.
    </span>
  );
  const billingSuspensionMessage = (
    <span>
      Your account is suspended for missing payments. All future shifts after{" "}
      {suspensionTime} are canceled and you can't post any new shifts. Please{" "}
      <a href="/facility/invoice/history">click here</a> to pay your invoices or
      please contact us at{" "}
      <a
        href="mailto:billing@clipboardhealth.com"
        target="_blank"
        rel="noreferrer"
      >
        billing@clipboardhealth.com
      </a>{" "}
      for assistance.
    </span>
  );

  return (
    <>
      {lastLog && (
        <Alert
          style={{ marginBottom: "10px" }}
          message={
            lastLog.reason == "Current Billing Failure - Hoping to Reconcile"
              ? billingSuspensionMessage
              : otherSuspensionMessage
          }
          type="error"
          showIcon
        />
      )}
    </>
  );
});

export { FacilitySuspensionAlert };
