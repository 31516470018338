import React, { useState, useEffect } from "react";
import { Form, Row, Button, PageHeader, Divider, Input, message } from "antd";
import axios from "axios";

const FetchOTP = () => {
  const [loading, setLoading] = useState(false);
  const [isLoginOtp, setIsLoginOtp] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getAgentProfile();
  }, []);

  const getAgentProfile = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        setIsLoginOtp(true);
        return;
      }

      const agent = await axios.get(
        `${process.env.REACT_APP_API_MAIN_URL}/api/agentProfile`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      if (agent.data) {
        setPhone(agent.data.phone);
      } else {
        message.error(
          `Error - Failed to fetch user information, you'll need to insert phone number manually`
        );
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error}`);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const filteredPhone = phone.replace(/\D/g, "");
      const result = await axios.post(`/api/testHelpers/otp`, {
        filteredPhone,
        isLoginOtp,
      });
      if (result.data) {
        if (result.data.success) setOtp(result.data.otp);
        else message.error(`${result.data.message}`);
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader className="pageHeader" title="Fetch OTP from Mailhog" />
      <Divider className="ApproveDocsHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <p>
              Please insert your phone number if you want to login/sign-up or
              are changing your phone number:
            </p>
            <Input
              onChange={(e) => setPhone(e.target.value)}
              type="tel"
            ></Input>
          </Row>

          <Row>
            <div>
              <p>Most recent OTP:</p>
              <p>{otp}</p>
            </div>
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Get OTP
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default FetchOTP;
