import axios from "axios";

const getSignInLink = async (email) => {
  try {
    const appDomain = window.location.origin;

    let isMobileApp = appDomain.includes("localhost")
      ? appDomain.split(":")[2] === "4200"
      : appDomain.includes("hcp-webapp.");

    const signInEndpoint = isMobileApp
      ? "/api/testHelpers/appSignInEmailLink"
      : "/api/testHelpers/signInEmailLink";

    let signInLink = await axios.post(
      `${process.env.REACT_APP_API_MAIN_URL || ""}${signInEndpoint}`,
      {
        email,
        appDomain,
      }
    );

    if (!isMobileApp) return { link: signInLink.data.newUrl, isMobileApp };

    const signInURL = new URL(signInLink.data.link);
    const mainLink = new URL(signInURL.searchParams.get("link"));

    const newUrl = `${mainLink.searchParams.get(
      "continueUrl"
    )}?apiKey=${mainLink.searchParams.get(
      "apiKey"
    )}&oobCode=${mainLink.searchParams.get(
      "oobCode"
    )}&mode=${mainLink.searchParams.get(
      "mode"
    )}&email=${email}&keepMeLogin=true`;
    localStorage.setItem("emailToVerify", email);

    return { link: newUrl, isMobileApp };
  } catch (e) {
    console.error("Autologin Error: ", e);
    return false;
  }
};

const isLoggedIn = () => {
  return (
    localStorage.getItem("AUTH_TOKEN") &&
    localStorage.getItem("AUTH_TOKEN").length > 0
  );
};

const getIdTokenFromCustomToken = async (customToken) => {
  const response = await axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithCustomToken?key=${process.env.FIREBASE_API_KEY}`,
    { token: customToken, returnSecureToken: true }
  );
  return response.data.idToken;
};

export { getSignInLink, isLoggedIn, getIdTokenFromCustomToken };
