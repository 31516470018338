import request from "superagent";
import { getAuthHeader } from "../utils/superagent";
import { message } from "antd";

const getMetropolitanStatisticalAreas = async () => {
  try {
    const response = await request
      .get(`${global.api}/facilities/metropolitanStatisticalAreas`)
      .set(await getAuthHeader());
    return response.body.metropolitanStatisticalAreas;
  } catch (err) {
    message.error(err);
  }
};

const fetchAllNotes = async (query) => {
  try {
    const res = await request
      .get(`${global.api}/facilityNoteCategory/get-notes/${query.id}`)
      .set(await getAuthHeader());
    return res.body;
  } catch (err) {
    message.error(err);
  }
};

const updateNotes = async (query, data) => {
  try {
    const res = await request
      .patch(`${global.api}/facilityNoteCategory/update-note/${query.id}`)
      .set(await getAuthHeader())
      .send(data);
    return res.body;
  } catch (err) {
    message.error(err);
  }
};

const getFacilityDetails = async (hcfIds, projection) => {
  try {
    const res = await request
      .get(`${global.api}/facilityprofile/details`)
      .set(await getAuthHeader())
      .query({ facilityIds: hcfIds, projection });
    return res.body;
  } catch (err) {
    message.error(err);
    return [];
  }
};

const getFacilityRecentSuspensionLog = async (facilityId) => {
  try {
    const res = await request
      .get(`${global.api}/facilityprofile/recent-suspension-log`)
      .set(await getAuthHeader())
      .query({ facilityId });
    return res.body;
  } catch (error) {
    return {};
  }
};

const updateFacilityProfile = async (data) => {
  return request
    .put(`${global.api}/facilityprofile/put`)
    .set(await getAuthHeader())
    .send(data);
};

export {
  fetchAllNotes,
  getFacilityDetails,
  getMetropolitanStatisticalAreas,
  updateNotes,
  getFacilityRecentSuspensionLog,
  updateFacilityProfile,
};
