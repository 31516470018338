import { Modal, message } from "antd";
import {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  createExclusion,
  createShiftReview,
  deleteExclusion,
  fetchExclusion,
  fetchShiftReviews,
} from "./api";
import { SHIFT_REVIEW_DNR_REASON } from "./constants";
import {
  captureShiftReviewSubmittedEvent,
  shiftReviewRatingFeedbackOptions,
} from "./helpers";
import { initialFormState, reducer } from "./reducer";
import {
  DnrAction,
  ShiftReviewAction,
  ShiftReviewFormInput,
  ShiftReviewFormSubmissionStage,
  ShiftReviewState,
  UseShiftReviewProps,
} from "./shiftReview.types";
import { useComponentWillUnmount } from "../../hooks/useComponentWillUnmount";
import { findUserById } from "../../containers/facilityUser/api";

const useShiftReview = ({
  shiftId,
  workerId,
  workplaceUserId,
  workerName,
  workplaceId,
  closeModal,
  successCallback,
  form,
  whoCalledMe,
}: UseShiftReviewProps) => {
  const { type } = useSelector(
    (state: { session: { type: string } }) => state.session
  );
  const [state, dispatch] = useReducer<Reducer<ShiftReviewState, unknown>>(
    reducer,
    initialFormState
  );

  const dnrAction = useMemo(() => {
    if (state.showDnr && state.dnrWorker && !state.exclusionId) {
      return DnrAction.CREATE;
    } else if (state.showDnr && !state.dnrWorker && state.exclusionId) {
      return DnrAction.DELETE;
    }
  }, [state.showDnr, state.dnrWorker, state.exclusionId]);

  /**
   * Below state is set after first API call to fetch the details and use this values for analytics purpose.
   */
  const [initialState, setInitialState] = useState<
    ShiftReviewState | undefined
  >();

  const captureShiftReviewSubmittedEventWithUseCallback = useCallback(
    (options = {}) =>
      captureShiftReviewSubmittedEvent(state, initialState, {
        shiftId,
        workerId,
        workplaceId,
        workplaceUserId,
        whoCalledMe,
        dnrAction,
        ...options,
      }),
    [
      dnrAction,
      initialState,
      state,
      whoCalledMe,
      shiftId,
      workerId,
      workplaceId,
      workplaceUserId,
    ]
  );

  useComponentWillUnmount(() => {
    if (
      !state.isLoading &&
      state.formSubmissionStage === ShiftReviewFormSubmissionStage.NOT_STARTED
    ) {
      captureShiftReviewSubmittedEventWithUseCallback({
        success: false,
        method: "closedModal",
      });
    }
  }, [captureShiftReviewSubmittedEventWithUseCallback]);

  useEffect(() => {
    // resetting form on close of this component.
    return () => dispatch({ type: ShiftReviewAction.RESET_FORM });
  }, [dispatch]);

  useEffect(() => {
    if (!workerId || !workplaceUserId) return undefined;
    (async () => {
      const [shiftReviews, exclusion] = await Promise.all([
        fetchShiftReviews({ workerId, shiftId }),
        fetchExclusion({ facilityId: workplaceId, agentId: workerId }),
      ]);
      const latestShiftReview = shiftReviews
        ? shiftReviews[shiftReviews.length - 1]
        : undefined;

      const reviewer = latestShiftReview?.workplaceUserId
        ? await findUserById(latestShiftReview?.workplaceUserId)
        : undefined;
      // This value is only used for analytics. so having this state set one after other doesn't cause problems.
      setInitialState(latestShiftReview);
      dispatch({
        type: ShiftReviewAction.FILL_FORM_DATA,
        data: {
          shiftReview: latestShiftReview,
          reviewer,
          exclusion,
        },
      });
    })();
  }, [workerId, workplaceUserId, shiftId, dispatch, setInitialState]);

  const shiftRatingFeedbackOptions = useMemo(
    () =>
      shiftReviewRatingFeedbackOptions(
        state[ShiftReviewFormInput.SHIFT_REVIEW_RATING]
      ),
    [state[ShiftReviewFormInput.SHIFT_REVIEW_RATING]]
  );

  useEffect(() => {
    const value = form.isFieldTouched(
      ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER
    );
    // If DNR fields are touched, we are not going to use the
    dispatch({ type: ShiftReviewAction.UPDATE_DNR_FIELD_TOUCHED, value });
  }, [
    form.isFieldTouched(ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER),
    dispatch,
  ]);

  const onClickToUpdateForm = () => {
    dispatch({ type: ShiftReviewAction.UPDATE_FORM_TO_EDIT_MODE });
  };

  const onConfirmFormSubmission = async () => {
    try {
      dispatch({ type: ShiftReviewAction.SUBMITTING_FORM });
      const shiftReview = await createShiftReview({
        rating: state[ShiftReviewFormInput.SHIFT_REVIEW_RATING],
        dnrWorker: state[ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER],
        additionalFeedback:
          state[ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK],
        qualities: state.qualities,
        workerId,
        workplaceUserId,
        shiftId,
        workplaceId,
        id: state.shiftReviewId,
      });
      // Only update DNRs in case, when the checkbox is shown.
      if (dnrAction === DnrAction.CREATE) {
        await createExclusion({
          actionBy: type,
          reason: SHIFT_REVIEW_DNR_REASON,
          notes: state[ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK],
          facilityId: workplaceId,
          agentId: workerId,
          shiftReviewId: shiftReview.id,
          adminId: workplaceUserId,
        });
        message.success(
          `Worker successfully blocked. Thank you for your feedback!`
        );
      } else if (dnrAction === DnrAction.DELETE && state.exclusionId) {
        // Delete the existing DNR, if DNR option is not selected and their is already a DNR database document created.
        await deleteExclusion(state.exclusionId);
        message.success(
          `Worker successfully unblocked. Thank you for your feedback!`
        );
      }

      // Call the successCallback before `closeModal`, which will clear successCallback.
      if (typeof successCallback === "function") successCallback(shiftReview);

      dispatch({ type: ShiftReviewAction.FORM_SUBMITTED, shiftReview });
      captureShiftReviewSubmittedEventWithUseCallback({
        success: true,
        id: shiftReview?.id,
      });
      // Closing the modal in-case a callback is passed from the modal, where this component is rendered.
      if (typeof closeModal === "function") closeModal();
    } catch (error) {
      message.error(`Submitting your review failed!`);
      dispatch({ type: ShiftReviewAction.FORM_SUBMISSION_FAILED });
      captureShiftReviewSubmittedEventWithUseCallback({
        success: false,
        method: "submitFailed",
        // If this is an update this value will be present.
        id: state.shiftReviewId,
      });
    }
  };

  const onClickSubmit = async () => {
    // NOTE:: Do not add form validation into the try...catch block.
    await form.validateFields();

    if (dnrAction) {
      // Informing user, that making changes to Dnr will block/unblock the user before he committed the change.
      Modal.confirm({
        title: "Are you sure?",
        onOk: () => {
          // Do not await here, because this will add loading state on the confirmation modal.
          onConfirmFormSubmission();
        },
        content:
          dnrAction === DnrAction.CREATE
            ? `When you submit this feedback you will also be blocking the worker from
    your future shifts at your facility.`
            : `When you submit this feedback you will also be unblocking the worker.`,
        okText: `Submit and ${
          dnrAction === DnrAction.CREATE ? "block" : "unblock"
        }`,
        cancelText: "No",
        icon: null,
        centered: true,
        mask: true,
        okButtonProps: { type: "primary", danger: true },
      });
    } else {
      await onConfirmFormSubmission();
    }
  };

  // Need to update the field here because the component rendered inside it is a custom built outside antd.
  useEffect(() => {
    form.setFieldsValue({
      qualities: state.qualities,
    });
  }, [state.qualities.length]);

  const onSelectFeedbackOption = useCallback(
    (event) => {
      const { optionValue } = event.currentTarget.dataset;

      dispatch({
        type: ShiftReviewAction.UPDATE_QUALITIES,
        value: optionValue,
      });
    },
    [dispatch]
  );

  const shiftReviewQualitiesTextFromRating = useMemo(() => {
    if (state.rating === undefined || state.rating < 3) {
      return "What could have been better";
    } else {
      return `What did you like about ${workerName}`;
    }
  }, [state.rating, workerName]);

  return {
    state,
    dispatch,
    form,
    dnrAction,
    onSelectFeedbackOption,
    onClickToUpdateForm,
    onClickSubmit,
    shiftRatingFeedbackOptions,
    shiftReviewQualitiesTextFromRating,
  };
};

export { useShiftReview };
