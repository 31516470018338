import React, { useState, useEffect } from "react";
import { Select } from "antd";
import "./style.css";

import { getFacilityUserAccounts } from "../../api/facilityUsers";
import { useDebounce } from "../../hooks/useDebounce";

const { Option } = Select;

const FacilityAccountSwitcher = ({ userId, pseudo, closeMenu }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    const fetchFacilityUserAccounts = async () => {
      setIsLoading(true);
      try {
        const facilities = await getFacilityUserAccounts({ name: search });
        setFacilities(facilities);
      } catch (error) {
        console.log("error loading facilities", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFacilityUserAccounts();
  }, [search]);

  const searchFacilities = useDebounce((name) => setSearch(name), []);

  const handleChange = (value) => {
    localStorage.setItem("selectedFacility", value);
    pseudo({ userId: value }, true);
    if (closeMenu) {
      closeMenu();
    }
  };

  if (facilities.length < 2 && !search) {
    return null;
  }

  return (
    <Select
      placeholder="Select Facility"
      showSearch
      onSearch={searchFacilities}
      loading={isLoading}
      filterOption={false}
      value={userId}
      onChange={handleChange}
      data-testid="facility-account-switcher"
      className="facility-account-switcher"
    >
      {facilities.map((facility) => (
        <Option key={facility._id} value={facility.userId}>
          {facility.name}
        </Option>
      ))}
    </Select>
  );
};

export { FacilityAccountSwitcher };
