import { forEach, invert, isEmpty, without } from "lodash";
import React, { Fragment, useEffect } from "react";

import { TableFilterTags } from "../../../components/tableFilterTags";
import { FACILITY_ROLES } from "../../../constants/facility";
import { REFRESH, UPDATE_FILTER, UPDATE_PAGE, useUserApi } from "./userApi";
import { UserTable } from "./userTable";

const invertedRoles = invert(FACILITY_ROLES);

const getDisplayValue = (key, value) => {
  if (key === "roles") {
    return invertedRoles[value];
  }
  return value;
};

const UserList = ({ refresh, onEdit, facilityId, isArchived }) => {
  const [
    { loading, total, userList, filterState },
    { dispatch, setFacilityId },
  ] = useUserApi(isArchived);

  useEffect(() => {
    if (facilityId) {
      setFacilityId(facilityId);
    }
  }, [facilityId, setFacilityId]);

  useEffect(() => {
    dispatch({ type: REFRESH });
  }, [refresh, dispatch]);

  const updateState = (type) => (data) => dispatch({ type, data });

  const getTableFilterTags = () => {
    const tagList = [];

    forEach(filterState.filter, (values, key) => {
      if (isEmpty(values)) return;

      values.forEach((value) => {
        tagList.push({
          key,
          value,
          displayValue: getDisplayValue(key, value),
        });
      });
    });

    return tagList;
  };

  const onTagClose = (source) => () => {
    let updatedValue = without(filterState.filter[source.key], source.value);

    dispatch({
      type: UPDATE_FILTER,
      data: {
        [source.key]: updatedValue,
      },
    });
  };

  return (
    <Fragment>
      <TableFilterTags dataSource={getTableFilterTags()} onClose={onTagClose} />
      <UserTable
        loading={loading}
        dataSource={userList}
        page={filterState.page}
        filter={filterState.filter}
        setPage={updateState(UPDATE_PAGE)}
        setFilter={updateState(UPDATE_FILTER)}
        total={total}
        onEdit={onEdit}
      />
    </Fragment>
  );
};

export { UserList };
