import mixpanel from "mixpanel-browser";

const initLogging = (token) => {
  mixpanel.init(token);
};

const identifyAndSetUser = (userId, properties) => {
  const { name = "", email, ...profile } = properties;
  mixpanel.identify(userId);
  mixpanel.people.set({
    userId,
    $name: name,
    $email: email,
    ...profile,
  });
};

export { initLogging, identifyAndSetUser };
