import styled from "styled-components";

const ButtonCheckboxContainer = styled.span`
  button {
    margin: 0 5px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  .ant-select {
    width: 300px;
    margin-right: 20px;
  }
`;

export { ButtonCheckboxContainer, FilterContainer };
