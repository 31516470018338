import { logEvent } from "../../utils/segment/logEvents";
import { HCF_USER_EVENTS } from "../../constants/firebaseEvents";

import {
  SHIFT_REVIEW_QUALITY_OPTIONS_FOR_RATING_EQUAL_TO_THREE,
  SHIFT_REVIEW_QUALITY_OPTIONS_FOR_RATING_LESS_THAN_THREE,
  SHIFT_REVIEW_QUALITY_OPTIONS_RATING_GREATER_THAN_THREE,
} from "./constants";
import {
  DnrAction,
  ShiftReviewProps,
  ShiftReviewState,
} from "./shiftReview.types";
import { capitalizeFirstLetter } from "../../utils/string";

const RATING_ZERO = 0;
const RATING_THREE = 3;

export const showDnrCheckboxBasedOnRating = (rating?: number) => {
  // If rating is not refined, do not show the DNR option.
  if (!rating || isNaN(rating)) return false;
  return rating <= RATING_THREE && rating >= RATING_ZERO;
};

export const shiftReviewRatingFeedbackOptions = (rating?: number) => {
  if (typeof rating !== "number") return [];
  if (rating === RATING_THREE) {
    return SHIFT_REVIEW_QUALITY_OPTIONS_FOR_RATING_EQUAL_TO_THREE;
  } else if (rating < RATING_THREE) {
    return SHIFT_REVIEW_QUALITY_OPTIONS_FOR_RATING_LESS_THAN_THREE;
  } else {
    return SHIFT_REVIEW_QUALITY_OPTIONS_RATING_GREATER_THAN_THREE;
  }
};

const buildObjectWithHistoricValues = (
  propertyName,
  currentValue,
  initialValue
) => {
  const object = { [propertyName]: currentValue[propertyName] };
  if (initialValue?.[propertyName] !== currentValue[propertyName]) {
    return {
      ...object,
      [`previous${capitalizeFirstLetter(propertyName)}`]:
        initialValue?.[propertyName],
    };
  }
  return object;
};

/**
 * We are triggering same event thrice in the code, so moving it here to avoid duplication.
 */
export const captureShiftReviewSubmittedEvent = (
  state: ShiftReviewState,
  initialState: Partial<ShiftReviewState> = {},
  {
    shiftId,
    workerId,
    workplaceId,
    workplaceUserId,
    dnrAction,
    whoCalledMe,
    ...options
  }: Pick<
    ShiftReviewProps,
    | "shiftId"
    | "workerId"
    | "workplaceId"
    | "workplaceUserId"
    | "dnrAction"
    | "whoCalledMe"
  >
) => {
  logEvent(HCF_USER_EVENTS.SHIFT_REVIEW_SUBMITTED, {
    location: whoCalledMe,
    trigger: !!state.shiftReviewId ? "viewOrEditReview" : "addReview",
    shiftId,
    workerId,
    workplaceId,
    workplaceUserId,
    ...buildObjectWithHistoricValues("qualities", state, initialState),
    ...buildObjectWithHistoricValues("additionalFeedback", state, initialState),
    ...buildObjectWithHistoricValues("rating", state, initialState),
    ...buildObjectWithHistoricValues("dnrWorker", state, {
      ...initialState,
      dnrWorker: !!state.exclusionId,
    }),
    isDnrManuallyToggled: state.dnrFieldTouched,
    ...options,
  });
};
