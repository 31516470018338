const HCP_CLOCK_OUT_DEFAULT_YAML_VALUE = (
  props = { shiftId: "", actionTime: "" }
) => {
  const { shiftId, actionTime } = props;
  return `hcpClockOut:
  shiftId: ${shiftId ? shiftId : "6101cb5d9872710166fed9bc"}
  action: CLOCK_OUT
  actionTime: ${actionTime ? actionTime : "2021-08-01T15:32:59.899Z"}`;
};

export { HCP_CLOCK_OUT_DEFAULT_YAML_VALUE };
