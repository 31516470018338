import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Input,
  Select,
  Button,
  DatePicker,
  PageHeader,
  Divider,
  Typography,
  message,
  Alert,
} from "antd";
import MonacoEditor from "react-monaco-editor";
import yaml from "yaml";
import ShouldRender from "./ShouldRender";
import { SHIFT_TIME_TYPES } from "../constants/SHIFT_TIME_TYPES";
import { ASSIGN_SHIFT_YAML_DATA } from "../constants/ASSIGN_SHIFT";
import axios from "axios";
import moment from "moment";
import "moment-timezone";

const { Title } = Typography;

const AssignShift = ({ selectedRegressionTestData = {} }) => {
  const [loading, setLoading] = useState(false);
  const [respStatus, setRespStatus] = useState(0);
  const [form] = Form.useForm();
  const [shiftActionsYAMLData, setShiftActionsYAMLData] = useState(
    ASSIGN_SHIFT_YAML_DATA.CUSTOM_TIME
  );
  const [shiftTimeType, setShiftTimeType] = useState("CUSTOM_TIME");

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { shiftActions: shiftActionsData } = yaml.parse(
        selectedRegressionTestData.config
      );
      setShiftActionsYAMLData(
        yaml.stringify({ shiftActions: shiftActionsData })
      );
    } else if (selectedRegressionTestData.id) {
      setShiftActionsYAMLData("shiftActions:\n  -");
    }
  }, [selectedRegressionTestData]);

  useEffect(() => {
    setShiftActionsYAMLData(ASSIGN_SHIFT_YAML_DATA[shiftTimeType]);
    console.info(shiftTimeType, ASSIGN_SHIFT_YAML_DATA[shiftTimeType]);
  }, [shiftTimeType]);

  useEffect(() => {
    console.info(shiftTimeType);
  }, [shiftTimeType]);
  const editorDidMount = (editor, monaco) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onSubmit = async (val) => {
    setLoading(true);

    try {
      const { shift } = yaml.parse(shiftActionsYAMLData);
      console.info(shift);

      axios
        .post(
          `${
            process.env.REACT_APP_API_MAIN_URL || ""
          }/api/testHelpers/shift/createAssign`,
          { shift: { ...shift, timeType: shiftTimeType } }
        )
        .then((res) => {
          message.success("Success");
        })
        .catch(function (e) {
          message.error(`Error: ${e.response.data}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      message.error(`Error assigning shift -  ${error.message}`);
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="shiftActionPageHeader"
        title="Shift Action"
        subtitle="shift action"
        extra={[
          <Button
            type="secondary"
            onClick={() => {
              window
                .open(
                  `https://dev.to/paulasantamaria/introduction-to-yaml-125f`
                )
                .focus();
            }}
          >
            Intro to YAML
          </Button>,
        ]}
      />
      <Divider className="shiftActionHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <h4>Select Shift Time Type</h4>
            <Select
              placeholder="Please select shift type"
              className="scenariosBox"
              onChange={(value) => setShiftTimeType(value)}
              value={shiftTimeType}
              allowClear
            >
              {Object.keys(SHIFT_TIME_TYPES).map((shiftTimeType) => {
                return (
                  <Select.Option key={shiftTimeType} value={shiftTimeType}>
                    {SHIFT_TIME_TYPES[shiftTimeType]}
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
          <Row>
            <Title level={4}>YAML Config</Title>
            <div>
              <ShouldRender condition={selectedRegressionTestData.name}>
                <Alert
                  style={{ marginBottom: ".5rem" }}
                  message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                  type="info"
                  showIcon
                />
              </ShouldRender>
            </div>
          </Row>
          <Row>
            <MonacoEditor
              height="400"
              language="javascript"
              theme="vs-dark"
              value={shiftActionsYAMLData}
              options={options}
              editorDidMount={editorDidMount}
              onChange={(value) => {
                setShiftActionsYAMLData(value);
              }}
            />
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default AssignShift;
