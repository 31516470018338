import { GetShiftFromMessageParams, ShiftWithReview } from "./chat.types";
export const getShiftFromMessage = ({
  currentChannel,
  message,
}: GetShiftFromMessageParams): ShiftWithReview | undefined => {
  if (currentChannel?.shiftWithReviews) {
    const currentShift = currentChannel?.shiftWithReviews?.find(
      (shiftWithReview) =>
        shiftWithReview.externalId === message.data ||
        shiftWithReview._id === message.data
    );
    return currentShift;
  }
  return undefined;
};
