const adminWebPrefix = "Admin Web:";
const workPlaceWebPrefix = "Workplace Web:";

export const ADMIN_EVENTS = {
  OPEN_SEARCH: `${adminWebPrefix} Search Opened`,
  FILTER_SEARCH: `${adminWebPrefix} Search Filtered`,
  SELECT_HCP: `${adminWebPrefix} HCP Selected`,
  SELECT_HCF: `${adminWebPrefix} HCF Selected`,
  VIEW_ADMIN_TOOLBOX: `${adminWebPrefix} Shift Admin Toolkit Viewed`,
  VIEW_HCPS_TO_ASSIGN: `${adminWebPrefix} Assign HCP List Viewed`,
  ASSIGN_HCP: `${adminWebPrefix} Worker Assigned`,
  CONFIRM_HCP: `${adminWebPrefix} HCP Confirmed`,
  OPEN_HCF_DRAWER: `${adminWebPrefix} HCF Drawer Opened`,
  OPEN_HCP_DRAWER: `${adminWebPrefix} HCP Drawer Opened`,
  VIEW_SHIFT_MENU_OPTIONS: `${adminWebPrefix} Shift Menu Options Viewed`,
  VIEW_SHIFTS_TO_VERIFY: `${adminWebPrefix} Shifts to Verify Viewed`,
  VIEW_UNVERIFIED_SHIFTS: `${adminWebPrefix} Unverified Shifts Viewed`,
  VERIFY_SHIFT: `${adminWebPrefix} Shift Verified`,
  SELECT_SIGNATORY: `${adminWebPrefix} Signatory Selected`,
  ACCESSED_HCF_TUTORIALS: `${adminWebPrefix} HCF Tutorials Accessed`,
  CONFIRM_VERIFICATION: `${adminWebPrefix} Verification Confirmed`,
  OPENED_ADMIN_PROFILE_LINK: `${adminWebPrefix} Admin Profile Link Opened`,
  INVOICE_VIEWED: `${adminWebPrefix} Invoice Viewed`,
  INVOICE_PDF_DOWNLOADED: `${adminWebPrefix} Invoice PDF Downloaded`,
  TIMESHEET_VIEWED: `${adminWebPrefix} Timesheet Viewed`,
  TIMESHEET_PDF_DOWNLOADED: `${adminWebPrefix} Timesheet PDF Downloaded`,
  INVOICE_NUMBER_SEARCHED: `${adminWebPrefix} Invoice Number Searched`,
  INVOICES_FILTERED: `${adminWebPrefix} Invoices filtered`,
  SEARCH_FACILITY: `${adminWebPrefix} Specific Facility Searched`,
  FETCH_FACILITIES: `${adminWebPrefix} List of Facilities Fetched`,
  SHIFT_TIME_CHANGE: `${adminWebPrefix} Shift Time Changed`,
  SHIFT_CHARGE_RATE_CHANGE: `${adminWebPrefix} Shift Charge Rate Changed`,
  SHIFT_PAY_RATE_CHANGE: `${adminWebPrefix} Shift Pay Rate Changed`,
  SHIFT_NOTE_CHANGE: `${adminWebPrefix} Shift Note Changed`,
  INVOICED_REASSIGN_HCP: `${adminWebPrefix} HCP Shift Reassigned`,
  FACILITY_CANCEL: `${adminWebPrefix} Facility Cancelled`,
  WORKER_CANCEL: `${adminWebPrefix} Worker Cancelled`,
  NO_CALL_NO_SHOW: `${adminWebPrefix} No Call No Show Happened`,
  SHIFT_UNASSIGN: `${adminWebPrefix} Shift Unassigned`,
  SHIFT_DELETE: `${adminWebPrefix} Shift Deleted`,
  INVOICED_SHIFT_RATE_CHANGE: `${adminWebPrefix} Shift Rate Change Invoiced`,
  SHIFT_SUCCESS_CHANGE: `${adminWebPrefix} Invoiced Shift Completed`,
  SHIFT_CANCEL_CHANGE: `${adminWebPrefix} Invoiced Shift Cancelled`,
  INVOICE_STATUS_CHANGED: `${adminWebPrefix} Invoice Status Changed`,
  FACILITY_STATE_CHANGED: `${adminWebPrefix} Facility State Changed`,
  ADMIN_EDIT_SHIFT: `${adminWebPrefix} Shift Edited`,
  ACCESSED_HCF_ARTICLES: `${adminWebPrefix} HCF Articles Accessed`,
  NOTIFICATION_PREFERENCES_EDITED: `${adminWebPrefix} Notification Preferences Edited`,
  NOTIFICATION_PREFERENCES_UPDATED: `${adminWebPrefix} Notification Preferences Updated`,
};

export const HCF_USER_EVENTS = {
  MASS_UPLOAD_CALENDAR_BUTTION_CLICKED: `${workPlaceWebPrefix} Mass Upload Calendar Button Clicked`,
  MASS_UPLOAD_POPUP_CANCELLED: `${workPlaceWebPrefix} Mass Upload Popup Cancelled`,
  MASS_UPLOAD_FILE_OPENED: `${workPlaceWebPrefix} Mass Upload File Opened`,
  MASS_UPLOAD_FILE_SUBMISSION_CONFIRMED: `${workPlaceWebPrefix} Mass Upload File Submission Confirmed`,
  MASS_UPLOAD_FILE_SUBMISSION_SUCCEEDED: `${workPlaceWebPrefix} Mass Upload File Submission Succeeded`,
  MASS_UPLOAD_FILE_SUBMISSION_FAILED: `${workPlaceWebPrefix} Mass Upload File Submission Failed`,
  SHIFT_CREATED: `${workPlaceWebPrefix} Shift Created`,
  INVOICE_DISPUTE_CLICKED: `${workPlaceWebPrefix} Invoice Dispute clicked`,
  UPCOMING_CHARGES_VIEWED: `${workPlaceWebPrefix} Upcoming Charges Viewed`,
  INVOICE_HISTORY_VIEWED: `${workPlaceWebPrefix} Invoice History Viewed`,
  CALENDAR_VIEWED: `${workPlaceWebPrefix} Calendar Viewed`,
  USERS_VIEWED: `${workPlaceWebPrefix} Users Viewed`,
  WORKER_INSTRUCTIONS_VIEWED: `${workPlaceWebPrefix} Worker Instructions Viewed`,
  COVID_SETTINGS_VIEWED: `${workPlaceWebPrefix} COVID Settings Viewed`,
  CHAT_OPENED: `${workPlaceWebPrefix} Chat opened`,
  SHIFT_REVIEW_SUBMITTED: `${workPlaceWebPrefix} Shift Review Submitted`,
  BLOCK_WORKER: `${workPlaceWebPrefix} Block / DNR Worker`,
  UPCOMING_CHARGES_DATE_RANGE_CHANGED: `${workPlaceWebPrefix} Upcoming  Charges Dates Changed`,
  SHIFT_DETAILS_CTA_CLICKED: `${workPlaceWebPrefix} Shift Details CTA Clicked`,
  SENT_HOME_CONFIRMATION_SUBMITTED: `${workPlaceWebPrefix} Sent Home Confirmation Submitted`,
  FACILITY_CANCELLED_ME_CONFIRMATION_SUBMITTED: `${workPlaceWebPrefix} Facility Cancelled Me Confirmation Submitted`,
};

export const COMMON_EVENTS = {
  SELECT_DAY: `${adminWebPrefix} Day Selected`,
  VIEW_PAGENAME: `Invoice By Number`,
};
