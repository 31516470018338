import "./style.scss";

import {
  Layout,
  Row,
  Typography,
  Space,
  Rate,
  Divider,
  Input,
  Checkbox,
  Button,
  Form,
  Tag,
  Skeleton,
} from "antd";
import moment from "moment-timezone";
import { Fragment } from "react";
import { CheckOutlined } from "@ant-design/icons";
import {
  ShiftReviewProps,
  ShiftReviewAction,
  ShiftReviewMode,
  ShiftReviewFormInput,
  ShiftReviewFormSubmissionStage,
} from "./shiftReview.types";
import { useShiftReview } from "./useShiftReview";

const { Title, Text } = Typography;
const { Header, Content, Footer } = Layout;

const ShiftReview = ({
  shiftId,
  workerId,
  workplaceUserId,
  workerName,
  shiftStartTime,
  workplaceId,
  closeModal,
  whoCalledMe,
  successCallback,
}: ShiftReviewProps) => {
  const [form] = Form.useForm();

  const {
    state,
    dispatch,
    onClickSubmit,
    onClickToUpdateForm,
    onSelectFeedbackOption,
    shiftRatingFeedbackOptions,
    shiftReviewQualitiesTextFromRating,
  } = useShiftReview({
    shiftId,
    workerId,
    workplaceUserId,
    workerName,
    shiftStartTime,
    workplaceId,
    closeModal,
    successCallback,
    form,
    whoCalledMe,
  });

  if (state.isLoading) {
    return (
      <Fragment>
        <Skeleton loading active></Skeleton>
        <Skeleton loading active></Skeleton>
        <Skeleton loading active></Skeleton>
      </Fragment>
    );
  } else if (
    state.formSubmissionStage === ShiftReviewFormSubmissionStage.STARTED
  ) {
    return (
      <Fragment>
        <Row justify="center">
          <Text>Submitting your review. Please wait..,</Text>
        </Row>
        <Skeleton loading active></Skeleton>
      </Fragment>
    );
  }

  const isInViewMode = state.mode === ShiftReviewMode.VIEW;

  return (
    <Layout className="shift-review-layout">
      <Header className="shift-review-header">
        <Title level={4} data-testid="shift-review-header-title">
          Shift feedback
        </Title>
      </Header>
      <Form layout="vertical" form={form} requiredMark={false}>
        <Content className="shift-review-content">
          <Text type="secondary">
            {workerName} completed a shift that started on{" "}
            {moment(shiftStartTime).format("MM/DD/YYYY")} at{" "}
            {moment(shiftStartTime).format("h:mm a")}
          </Text>
          <Form.Item
            name={ShiftReviewFormInput.SHIFT_REVIEW_RATING}
            className="shift-review-rating"
            rules={[
              {
                validator(_, value) {
                  if (typeof value === "number" && value > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please select a star rating");
                },
              },
            ]}
            initialValue={state[ShiftReviewFormInput.SHIFT_REVIEW_RATING]}
            label={
              isInViewMode ? (
                <Text className="shift-review-input-title" strong>
                  Rating
                </Text>
              ) : (
                <Text
                  className="shift-review-input-title"
                  data-testid="shift-review-shift-experience-text"
                  strong
                >
                  How was your shift experience with {workerName}
                </Text>
              )
            }
          >
            <Rate
              value={state[ShiftReviewFormInput.SHIFT_REVIEW_RATING]}
              disabled={isInViewMode}
              onChange={(value) =>
                dispatch({ type: ShiftReviewAction.UPDATE_RATING, value })
              }
            />
          </Form.Item>
          {!!shiftRatingFeedbackOptions.length && (
            <Fragment>
              <Divider className="shift-review-divider" />
              <Form.Item
                name={ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES}
                className="shift-review-qualities"
                rules={[
                  {
                    validator() {
                      if (state.qualities.length) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please select at least one quality")
                      );
                    },
                  },
                ]}
                label={
                  <Text className="shift-review-input-title" strong>
                    {shiftReviewQualitiesTextFromRating}
                  </Text>
                }
              >
                {isInViewMode ? (
                  state.qualities.join(", ")
                ) : (
                  <Row>
                    {shiftRatingFeedbackOptions.map((option: string, key) => {
                      const selected = state.qualities.includes(option);
                      return (
                        <Tag
                          key={key}
                          className="shift-review-feedback-tag"
                          data-option-value={option}
                          onClick={onSelectFeedbackOption}
                          icon={selected ? <CheckOutlined /> : null}
                          color={selected ? "blue" : undefined}
                        >
                          {option}
                        </Tag>
                      );
                    })}
                  </Row>
                )}
              </Form.Item>
            </Fragment>
          )}
          <Divider className="shift-review-divider" />
          <Form.Item
            name={ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK}
            initialValue={
              state[ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK]
            }
            rules={[
              {
                required: state[ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER],
                message:
                  "Additional feedback is required when blocking a worker",
              },
            ]}
            label={
              <Text className="shift-review-input-title" strong>
                Additional feedback
              </Text>
            }
          >
            {isInViewMode ? (
              // When string is empty, this shows a "-".
              <Text>
                {state[ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK] ||
                  "-"}
              </Text>
            ) : (
              <Input.TextArea
                placeholder="Click here to type..."
                value={
                  state[ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK]
                }
                onChange={(event) =>
                  dispatch({
                    type: ShiftReviewAction.UPDATE_ADDITIONAL_FEEDBACK,
                    value: event.target.value,
                  })
                }
              />
            )}
          </Form.Item>
          {state.showDnr ? (
            <Fragment>
              <Divider className="shift-review-divider" />
              <Space align="center">
                <Form.Item
                  name={ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER}
                  htmlFor="shift-review-dnr"
                >
                  <Checkbox
                    checked={
                      state[ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER]
                    }
                    disabled={isInViewMode}
                    onChange={(event) =>
                      dispatch({
                        type: ShiftReviewAction.UPDATE_DNR_WORKER,
                        value: event.target.checked,
                      })
                    }
                  >
                    <Text className="shift-review-input-title">
                      Block this worker from booking future shifts at your
                      facility
                    </Text>
                  </Checkbox>
                </Form.Item>
              </Space>
            </Fragment>
          ) : null}
          <Footer className="shift-review-footer">
            <Row justify="space-between">
              <Text type="secondary">
                {state.reviewerName && `Last edited by ${state.reviewerName}`}
              </Text>
              {isInViewMode ? (
                <Button
                  type="primary"
                  data-testid="shift-review-form-submit-button"
                  onClick={onClickToUpdateForm}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  type="primary"
                  data-testid="shift-review-form-submit-button"
                  onClick={onClickSubmit}
                >
                  {state.shiftReviewId ? "Update" : "Submit"}
                </Button>
              )}
            </Row>
          </Footer>
        </Content>
      </Form>
    </Layout>
  );
};

export { ShiftReview };
