import React from "react";
import { Button, Typography } from "antd";
import { SHOW_SHIFT_REVIEW_MODAL } from "frontend-admin/src/modules/shifts";
import { EyeOutlined } from "@ant-design/icons";
import { updateChannel } from "../../../modules/chat";
import { useDispatch, useSelector } from "react-redux";
import { getShiftFromMessage } from "../chat.utils";
import moment from "moment-timezone";
import { FACILITY_ROLES } from "frontend-admin/src/constants/facility";
import { ChatMessage, ChatChannelWithReviewList } from "../chat.types";

import { StyledReviewMessage } from "./CustomChatMessage.styles";

const CustomChatMessage = ({
  message,
  currentChannel,
}: {
  message: ChatMessage;
  currentChannel: ChatChannelWithReviewList;
}) => {
  const dispatch = useDispatch();
  const { user, admin } = useSelector(
    (state: {
      session: {
        admin: boolean;
        user: { access: string[]; _id: string };
      };
    }) => state.session
  );
  const currentShift = getShiftFromMessage({
    currentChannel,
    message,
  });

  const canReviewShift =
    currentShift?.agentId &&
    !admin &&
    user.access.includes(FACILITY_ROLES.SHIFT_MANAGEMENT) &&
    currentShift.end &&
    moment().isAfter(moment(currentShift.end));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 8,
        marginBottom: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <div
          style={{
            width: 28,
            height: 28,
            borderRadius: 14,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#bdbdbd",
          }}
        >
          <img
            src="/bunnyB.png"
            alt="image"
            style={{ width: 20, height: 20 }}
          />
        </div>
      </div>
      <div style={{ textAlign: "left", marginLeft: 8 }}>
        <span style={{ marginLeft: 12 }}>Clipboard Health</span>
        <div
          style={{
            maxWidth: 320,
            borderRadius: 16,
            borderWidth: 1,
            borderStyle: "solid",
            padding: 12,
            borderColor: "#bdbdbd",
          }}
        >
          <div>
            <p style={{ marginBottom: 2 }}>{message.message}</p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <EyeOutlined
              style={{ fontSize: 18, color: "#9a9a9a", marginTop: 3 }}
            />
            <span style={{ marginLeft: 8, color: "#9a9a9a" }}>
              This message is only visible to you
            </span>
          </div>
          {currentShift?.reviewer?.name && (
            <StyledReviewMessage
              type="secondary"
              data-testid="chat-message-submitted-by"
            >
              This review was submitted by {currentShift.reviewer.name}
            </StyledReviewMessage>
          )}
          <div style={{ display: "flex", flexDirection: "row", marginTop: 8 }}>
            {canReviewShift && (
              <Button
                type="primary"
                data-testid="chat-review-button"
                onClick={() =>
                  dispatch({
                    type: SHOW_SHIFT_REVIEW_MODAL,
                    workerId: currentShift?.agentId,
                    workplaceId: currentShift?.facilityId,
                    workplaceUserId: user._id,
                    shiftId: currentShift?._id,
                    workerName: currentShift?.agent.name,
                    shiftStartTime: currentShift?.start,
                    successCallback: () => {
                      updateChannel(dispatch, {
                        ...currentChannel,
                        shiftWithReviews: currentChannel.shiftWithReviews.map(
                          (review) => {
                            if (
                              review._id === currentShift?._id &&
                              !currentShift.shiftReview
                            ) {
                              return {
                                ...review,
                                shiftReview: {
                                  shiftId: currentShift?._id,
                                },
                              };
                            } else {
                              return review;
                            }
                          }
                        ),
                      });
                    },
                  })
                }
              >
                {!!currentShift?.shiftReview ? "View review" : "Add review"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomChatMessage;
