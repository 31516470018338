import { keyBy } from "lodash";
import request from "superagent";
import { getAuthHeader } from "../utils/superagent";

export const RETRIEVE = "employees/RETRIEVE";

const initialState = {
  employees: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        employees: keyBy(action.data, "userId"),
      };
    default:
      return state;
  }
};

export const getEmployees = () => {
  return async (dispatch) => {
    return request
      .get(`${global.api}/employees/details`)
      .set(await getAuthHeader())
      .then((res) => {
        dispatch({
          type: RETRIEVE,
          data: res.body,
        });
      });
  };
};
