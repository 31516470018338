import * as WorkerJSDeprecated from "./workers.deprecated";
import * as WorkerJSNew from "./workers.new";

const useDeprecatedWorkers =
  process.env.REACT_APP_USE_DEPRECATED_WORKERS === "true";

const workers = useDeprecatedWorkers ? WorkerJSDeprecated : WorkerJSNew;

const {
  workerTypes,
  workerTypeDefaults,
  workerTypesRates,
  workerSpecialities,
  newWorkerTypes,
  allWorkerTypes,
  workerTypeObj,
  WorkerTypes,
  FacilityWorkerType,
  WORKER_STAGES_ENUM,
  WORKER_STAGES,
  WORKER_STAGES_REASONS_ENUM,
  WORKER_STAGES_REASONS,
  WORKER_STAGES_REASONS_DATA,
  hcpNamAssignmentCases,
  hcpNamAssignmentCaseList,
  workerTypesOptions,
  medicalLabWorkerOptions,
  surgicalWorkerTypesOptions,
  medicalLabWorkerTypes,
  HHCAndHospiceWorkerTypeOptions,
  HospitalWorkerOptions,
  getWorkerShiftTypeLabel,
  psychHospitalWorkerTypesOptions,
  longTermCareWorkerTypesOptions,
} = workers;

export {
  workerTypes,
  workerTypeDefaults,
  workerTypesRates,
  workerSpecialities,
  newWorkerTypes,
  allWorkerTypes,
  workerTypeObj,
  WorkerTypes,
  FacilityWorkerType,
  WORKER_STAGES_ENUM,
  WORKER_STAGES,
  WORKER_STAGES_REASONS_ENUM,
  WORKER_STAGES_REASONS,
  WORKER_STAGES_REASONS_DATA,
  hcpNamAssignmentCases,
  hcpNamAssignmentCaseList,
  workerTypesOptions,
  medicalLabWorkerOptions,
  surgicalWorkerTypesOptions,
  medicalLabWorkerTypes,
  HHCAndHospiceWorkerTypeOptions,
  HospitalWorkerOptions,
  getWorkerShiftTypeLabel,
  psychHospitalWorkerTypesOptions,
  longTermCareWorkerTypesOptions,
};
