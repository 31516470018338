import { combineReducers } from "redux";
import counter from "./counter";
import session from "./session";
import locations from "./locations";
import employees from "./employees";
import shifts from "./shifts";
import chat from "./chat";

export default combineReducers({
  counter,
  session,
  locations,
  employees,
  shifts,
  chat,
});
