import { Button, Popover, Table, Tag, Tooltip, Typography } from "antd";
import { invert, isEqual, map } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import {
  FACILITY_NOTIFICATIONS,
  FACILITY_ROLES,
} from "../../../constants/facility";
import { searchFilter } from "../../../utils/filtering";
import { formatPhone } from "../../../utils/string";

const { Paragraph } = Typography;

const invertedRoles = invert(FACILITY_ROLES);

const renderRoles = (roles) =>
  roles.map((role) => <Tag key={role}>{invertedRoles[role]}</Tag>);

const renderNotify = (notifications) =>
  map(
    notifications,
    (isNotify, key) =>
      isNotify && <Tag key={key}>{FACILITY_NOTIFICATIONS[key].title}</Tag>
  );

const roleFilters = map(FACILITY_ROLES, (value, key) => ({ value, text: key }));

const noLocalFilter = () => true;

const UserTable = ({
  loading,
  dataSource,
  page,
  setPage,
  total,
  filter,
  setFilter,
  onEdit,
}) => {
  const { admin, user } = useSelector((state) => state.session);
  const { _id: session, access } = user || {};
  const handleOnChange = (pagination, tableFilter) => {
    if (!isEqual(filter, tableFilter)) {
      setFilter({ ...tableFilter });
    }
  };

  const renderName = (name, user) => {
    if (user._id === session) {
      return (
        <Button type="link" onClick={onEdit(user)}>
          {name}
        </Button>
      );
    }

    if (admin) {
      return (
        <Button type="link" onClick={onEdit(user)}>
          {name}
        </Button>
      );
    }

    if (access && access.includes(FACILITY_ROLES.ADMIN)) {
      return (
        <Button type="link" onClick={onEdit(user)}>
          {name}
        </Button>
      );
    }

    return name;
  };

  const RenderNotesTitle = () => (
    <Tooltip title="This field is only visible to Clipboard Health">
      Notes
    </Tooltip>
  );

  const renderNotes = (notes) => (
    <Paragraph ellipsis={{ rows: 2, expandable: true }}>{notes}</Paragraph>
  );

  const renderNotesColumn = () => {
    if (!admin) return {};

    return {
      title: <RenderNotesTitle />,
      key: "notes",
      width: 150,
      dataIndex: "notes",
      render: renderNotes,
    };
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      ...searchFilter("name", "name", noLocalFilter),
      filteredValue: filter.name,
      render: renderName,
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      ...searchFilter("email", "email", noLocalFilter),
      filteredValue: filter.email,
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
      render: formatPhone,
      ...searchFilter("phone", "phone", noLocalFilter),
      filteredValue: filter.phone,
    },
    {
      title: "Designation",
      key: "designation",
      dataIndex: "designation",
    },
    {
      title: "Roles",
      key: "roles",
      dataIndex: "roles",
      align: "center",
      render: renderRoles,
      filters: roleFilters,
      filterMultiple: true,
      filteredValue: filter.roles,
    },
    renderNotesColumn(),
  ];

  return (
    <Table
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      rowKey="_id"
      pagination={{
        hideOnSinglePage: true,
        current: page,
        onChange: setPage,
        total,
        showSizeChanger: false,
      }}
      onChange={handleOnChange}
    />
  );
};

export { UserTable };
