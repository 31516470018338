import { Switch, Table, Popover, Modal, Button, Select } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { hours, batchDescription } from "../../../constants/facility";
import { fillDefaultAlertSettings } from "../helpers";
const { Option } = Select;

const NotificationInput = forwardRef(
  (
    {
      value,
      onChange,
      roles,
      disabled: propsDisabled,
      userGranularSettings,
      userLegacySettings,
    },
    ref
  ) => {
    const [showBatchingModal, setBatchModal] = useState({
      data: {},
      time: [],
      type: "",
      openModal: false,
    });

    useEffect(() => {
      onChange(fillDefaultAlertSettings(userGranularSettings, roles));
    }, [roles]);

    const onSwitchChange = (actionData, typeOfSwitch) => (checked) => {
      let newState = [];
      if (actionData.action === "All") {
        newState = value[actionData.type].map((item) => {
          if (typeOfSwitch === "batch") {
            if (
              item.default.userModifiableBatch &&
              item.default.tempBatchDisable &&
              checked
            ) {
              return item;
            }
            if (item.default.userModifiableBatch) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  time: actionData.default.time,
                },
              };
            }
          } else if (typeOfSwitch === "enabled") {
            if (
              item.default.userModifiableState &&
              item.default.userModifiableBatch &&
              !checked
            ) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  batch: checked,
                  tempBatchDisable: true,
                },
              };
            }
            if (
              item.default.userModifiableState &&
              item.default.userModifiableBatch
            ) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  tempBatchDisable: false,
                },
              };
            }
            if (
              item.default.userModifiableState &&
              !item.default.userModifiableBatch
            ) {
              return {
                ...item,
                default: { ...item.default, [typeOfSwitch]: checked },
              };
            }
          }
          return item;
        });
      } else {
        newState = value[actionData.type].map((item) => {
          if (item.action === actionData.action) {
            if (typeOfSwitch === "batch") {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  time: actionData.default.time,
                },
              };
            } else if (
              typeOfSwitch === "enabled" &&
              checked === false &&
              actionData.default.userModifiableBatch
            ) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  batch: false,
                  tempBatchDisable: true,
                },
              };
            } else if (
              typeOfSwitch === "enabled" &&
              checked === false &&
              !actionData.default.userModifiableBatch
            ) {
              return {
                ...item,
                default: { ...item.default, [typeOfSwitch]: checked },
              };
            } else if (typeOfSwitch === "enabled" && checked === true) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  tempBatchDisable: false,
                },
              };
            }
          }
          return item;
        });
      }
      onChange({
        ...value,
        [actionData.type]: [...newState],
      });

      if (typeOfSwitch === "batch" && checked) {
        const currentActionData = actionData;
        if (currentActionData.default.time === undefined) {
          currentActionData.default.time = 16;
        }
        let time = null;
        if (currentActionData.default.time > 12) {
          time = [
            Number((currentActionData.default.time - 2).toString()[1]),
            "PM",
          ];
        } else if (currentActionData.default.time === 12) {
          time = [Number(currentActionData.default.time), "PM"];
        } else if (currentActionData.default.time === 0) {
          time = [Number(currentActionData.default.time), "AM"];
        } else {
          time = [Number(currentActionData.default.time), "AM"];
        }

        setBatchModal({
          data: currentActionData,
          type: actionData.type,
          time,
          openModal: true,
        });
      }
    };

    const renderNotify = (actionData) => {
      // const { granularControl } = value
      // const thisActionData = granularControl[actionData.type][actionData.action]
      return (
        <Switch
          onChange={onSwitchChange(actionData, "enabled")}
          checked={actionData.default.enabled}
          disabled={!actionData.default.userModifiableState}
        />
      );
    };

    const renderTitle = (title, record) => {
      return <b>{title}</b>;
    };

    const renderBatch = (actionData) => {
      let checked, disabled;

      disabled =
        actionData.default.tempBatchDisable !== undefined &&
        actionData.default.tempBatchDisable
          ? actionData.default.tempBatchDisable
          : !actionData.default.userModifiableBatch;
      checked = actionData.default.batch;

      return (
        <Switch
          onChange={onSwitchChange(actionData, "batch")}
          checked={checked}
          disabled={disabled}
        />
      );
    };
    const columns = [
      {
        title: "Alert Type",
        dataIndex: "title",
        render: renderTitle,
      },
      {
        title: "Enable",
        render: renderNotify,
      },
      {
        title: "Batched",
        render: renderBatch,
      },
    ];
    const closeModal = () => {
      const { data } = showBatchingModal;

      onSwitchChange(data, "batch")(false);
      setBatchModal({ data: {}, time: [], type: ``, openModal: false });
    };

    return (
      <>
        <h3>Emails</h3>
        <Table
          pagination={false}
          dataSource={value["EMAIL"]}
          columns={columns}
        />
        <br />
        <h3>SMS / Text Messages</h3>
        <Table pagination={false} dataSource={value["SMS"]} columns={columns} />
        <Modal
          title={`Batching Options`}
          onCancel={closeModal}
          visible={showBatchingModal.openModal}
          destroyOnClose={true}
          width={"80%"}
          footer={[
            <Button key="cancelBatchBtn" onClick={closeModal}>
              Cancel
            </Button>,
            <Button
              key="saveBatchBtn"
              type="primary"
              onClick={() => {
                let { data, time } = showBatchingModal;

                if (time[0] === 12 && time[1] === "PM") {
                  time = 12;
                } else if (time[0] === 12 && time[1] === "AM") {
                  time = 0;
                } else if (time[1] === "PM") {
                  time = Number(time[0]) + 12;
                } else {
                  time = Number(time[0]);
                }
                data.default.time = time;

                onSwitchChange(data, "batch")(true);
                setBatchModal({
                  data: {},
                  time: [],
                  type: ``,
                  openModal: false,
                });
              }}
            >
              Save
            </Button>,
          ]}
        >
          {showBatchingModal.openModal && (
            <Table
              columns={[
                {
                  title: "Time",
                  key: "batchTime",
                  dataIndex: "time",
                },
                {
                  title: "Description",
                  key: "batchDescription",
                  dataIndex: "description",
                },
              ]}
              dataSource={[
                {
                  description: batchDescription[showBatchingModal.type],
                  time: (
                    <>
                      <Select
                        onChange={(value) => {
                          setBatchModal({
                            ...showBatchingModal,
                            time: [value, showBatchingModal.time[1]],
                          });
                        }}
                        value={showBatchingModal.time[0]}
                        style={{ width: 70, marginRight: 10 }}
                      >
                        {hours.map((hour) => (
                          <Option value={hour}>{hour}</Option>
                        ))}
                      </Select>
                      <Select
                        onChange={(value) => {
                          setBatchModal({
                            ...showBatchingModal,
                            time: [showBatchingModal.time[0], value],
                          });
                        }}
                        value={showBatchingModal.time[1]}
                        style={{ width: 70 }}
                      >
                        <Option value={"AM"}>{"AM"}</Option>
                        <Option value={"PM"}>{"PM"}</Option>
                      </Select>
                    </>
                  ),
                },
              ]}
              pagination={false}
            />
          )}
        </Modal>
      </>
    );
  }
);

export { NotificationInput };
