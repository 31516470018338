import React, { useState } from "react";
import {
  EyeOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Button, message as toast, Modal, Input } from "antd";
import request from "superagent";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { getAuthHeader } from "../../utils/superagent";
import { errorMessage } from "../../utils/errors";
import { logEvent } from "../../utils/segment/logEvents";
import { HCF_USER_EVENTS } from "../../constants/firebaseEvents";

const { TextArea } = Input;

const shareAlert = (
  <div style={{ marginTop: 8 }}>
    <ExclamationCircleOutlined
      style={{
        color: "#868686",
        opacity: 0.6,
      }}
    />
    <h5
      style={{
        display: "inline",
        marginLeft: 8,
        color: "#868686",
        opacity: 0.6,
      }}
    >
      Please note this reason will be shared with the worker.
    </h5>
  </div>
);

const ReviewSentHomeMessage = ({ message, facilityId, facilityUserId }) => {
  const [visibleRejectModal, setVisibleRejectModal] = useState(false);
  const [visibleApproveModal, setVisibleApproveModal] = useState(false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationFail, setValidationFail] = useState(false);

  let metaData;

  try {
    metaData = JSON.parse(message.data);
  } catch (ex) {
    metaData = {};
  }

  const eventPayload = {
    trigger:
      metaData.approvalStatus !== -1 ? "updateResponse" : "addConfirmation",
    shiftId: metaData.shiftId,
    workerId: metaData.agentId,
    workplaceId: facilityId,
    workplaceUserId: facilityUserId,
    eventName: "facility_cancelled_me_confirmation",
  };

  const rejectRequest = async () => {
    if (!reason) {
      setValidationFail(true);
      return;
    }
    setLoading(true);
    try {
      await request
        .patch(
          `${global.api}/shifts/${metaData.shiftId}/facility-cancelled-me/reject`
        )
        .set(await getAuthHeader())
        .send({
          messageId: String(message.messageId),
          isUpdateResponse: metaData.approvalStatus !== -1,
          reason,
        });
      setLoading(false);
      setVisibleRejectModal(false);
      toast.success("Thanks for your response!");

      logEvent(HCF_USER_EVENTS.FACILITY_CANCELLED_ME_CONFIRMATION_SUBMITTED, {
        ...eventPayload,
        action: "reject",
        reason: reason,
        success: true,
      });
    } catch (error) {
      toast.error(errorMessage(error));
      setLoading(false);
      logEvent(HCF_USER_EVENTS.FACILITY_CANCELLED_ME_CONFIRMATION_SUBMITTED, {
        ...eventPayload,
        action: "reject",
        reason: reason,
        success: false,
      });
    }
  };

  const approveRequest = async () => {
    if (metaData.approvalStatus === 0 && !reason) {
      setValidationFail(true);
      return;
    }
    setLoading(true);
    try {
      await request
        .patch(
          `${global.api}/shifts/${metaData.shiftId}/facility-cancelled-me/approve`
        )
        .set(await getAuthHeader())
        .send({
          messageId: String(message.messageId),
          isUpdateResponse: metaData.approvalStatus !== -1,
          reason,
        });
      setLoading(false);
      setVisibleApproveModal(false);
      toast.success("Thanks for your response!");

      logEvent(HCF_USER_EVENTS.FACILITY_CANCELLED_ME_CONFIRMATION_SUBMITTED, {
        ...eventPayload,
        action: "approve",
        reason: reason,
        success: true,
      });
    } catch (error) {
      toast.error(errorMessage(error));
      setLoading(false);
      logEvent(HCF_USER_EVENTS.FACILITY_CANCELLED_ME_CONFIRMATION_SUBMITTED, {
        ...eventPayload,
        action: "approve",
        reason: reason,
        success: false,
      });
    }
  };

  return (
    <div className="fcm-message-container">
      <div className="avatar-container">
        <div className="avatar-wrapper">
          <img src="/bunnyB.png" alt="image" />
        </div>
      </div>
      <div className="message-container">
        <span>Clipboard Health</span>
        <div className="message-wrapper">
          <div>
            <p>{message.message}</p>
          </div>
          <div className="visibility-container">
            <EyeOutlined style={{ fontSize: 20 }} />
            <span>This message is only visible to you</span>
          </div>
          {metaData.approvalStatus === -1 && (
            <div className="approval-container">
              <Button
                type="ghost"
                style={{ margin: 4 }}
                onClick={() => setVisibleRejectModal(true)}
                disabled={loading}
              >
                No, reject
              </Button>
              <Button
                type="primary"
                style={{ margin: 4 }}
                onClick={() => setVisibleApproveModal(true)}
                disabled={loading}
              >
                Yes, approve
              </Button>
            </div>
          )}
          {metaData.approvalStatus === 0 && (
            <div className="rejected-container">
              <div className="alert-container">
                <CloseCircleOutlined
                  style={{ fontSize: 20, color: "red" }}
                  color="red"
                />
                <span>Facility cancellation rejected</span>
              </div>
              <div className="action-container">
                <Button
                  type="link"
                  onClick={() => setVisibleApproveModal(true)}
                  disabled={loading}
                >
                  Update response
                </Button>
              </div>
            </div>
          )}
          {metaData.approvalStatus === 1 && (
            <div className="approved-container">
              <div>
                <CheckCircleOutlined
                  style={{ fontSize: 20, color: "green" }}
                  color="red"
                />
                <span className="alert">Facility cancellation approved</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        okText={`Yes, approve`}
        okType={"primary"}
        cancelText={"No, don't approve"}
        title="Approve this shift as a facility cancellation?"
        visible={visibleApproveModal}
        onOk={approveRequest}
        onCancel={() => {
          setVisibleApproveModal(false);
          setReason("");
          setValidationFail(false);
          setLoading(false);
        }}
        okButtonProps={{
          loading: loading,
        }}
        destroyOnClose={true}
      >
        <h5>Reason for approval</h5>
        <TextArea
          required={true}
          placeholder={
            metaData.approvalStatus === -1
              ? "You may provide context for your approval reason"
              : "Please provide context for your approval reason"
          }
          rows={4}
          onChange={(e) => {
            setReason(e.target.value);
            if (e.target.value) {
              setValidationFail(false);
            }
          }}
          status={validationFail ? "error" : ""}
        />
        {validationFail ? (
          <h5 style={{ color: "red", marginTop: 9 }}>
            Please fill out reason for approval.
          </h5>
        ) : (
          shareAlert
        )}
      </Modal>
      <Modal
        okText={`Yes, reject`}
        okType={"danger"}
        cancelText={"No, don't reject"}
        title="Reject this shift as a facility cancellation?"
        visible={visibleRejectModal}
        onOk={rejectRequest}
        onCancel={() => {
          setVisibleRejectModal(false);
          setReason("");
          setValidationFail(false);
          setLoading(false);
        }}
        okButtonProps={{
          loading: loading,
        }}
        destroyOnClose={true}
      >
        <h5>Reason for rejection</h5>
        <TextArea
          required={true}
          placeholder="Please provide context for your rejection reason"
          rows={4}
          onChange={(e) => {
            setReason(e.target.value);
            if (e.target.value) {
              setValidationFail(false);
            }
          }}
          status={validationFail ? "error" : ""}
        />
        {validationFail ? (
          <h5 style={{ color: "red", marginTop: 9 }}>
            Please fill out reason for rejection.
          </h5>
        ) : (
          shareAlert
        )}
      </Modal>
    </div>
  );
};

export { ReviewSentHomeMessage };
