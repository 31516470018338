const AGENTS_LIMIT_FOR_FIRST_LOAD = 5;
const AGENTS_LIMIT_FOR_SUBSEQUENT_LOADS = 10;
const FACILITIES_LIMIT_FOR_FIRST_LOAD = 5;
const FACILITIES_LIMIT_FOR_SUBSEQUENT_LOADS = 10;

export {
  AGENTS_LIMIT_FOR_FIRST_LOAD,
  AGENTS_LIMIT_FOR_SUBSEQUENT_LOADS,
  FACILITIES_LIMIT_FOR_FIRST_LOAD,
  FACILITIES_LIMIT_FOR_SUBSEQUENT_LOADS,
};
