import { message } from "antd";
import moment from "moment-timezone";
import { useEffect, useReducer, useState } from "react";

import { errorMessage } from "../../../utils/errors";
import { fetchUserCount, fetchUsers } from "../api";

const UPDATE_FILTER = "UPDATE_FILTER";
const REFRESH = "REFRESH";
const UPDATE_PAGE = "UPDATE_PAGE";

const initialState = {
  filter: {},
  page: 1,
  refreshedAt: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        page: 1,
        filter: {
          ...state.filter,
          ...action.data,
        },
      };
    case REFRESH:
      return {
        ...state,
        refreshedAt: moment().format(),
      };
    case UPDATE_PAGE:
      return {
        ...state,
        page: action.data,
      };
    default:
      return state;
  }
};

const handleError = (error) => {
  message.error(errorMessage(error));
};

const useUserApi = (isArchived) => {
  const [filterState, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [total, setTotal] = useState(0);
  const [facilityId, setFacilityId] = useState(null);

  const { filter, page, refreshedAt } = filterState;
  useEffect(() => {
    if (!facilityId) {
      return;
    }
    fetchUserCount({ filter, facilityId, isArchived })
      .then(setTotal)
      .catch(handleError);
  }, [filter, refreshedAt, facilityId, isArchived]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }
    const onSuccess = (list) => {
      setUserList(list);
      setLoading(false);
    };
    setLoading(true);
    fetchUsers({ filter, page, facilityId, isArchived })
      .then(onSuccess)
      .catch(handleError);
  }, [filter, page, refreshedAt, facilityId, isArchived]);

  return [
    { loading, total, userList, filterState },
    { dispatch, setFacilityId },
  ];
};

export { useUserApi, UPDATE_PAGE, UPDATE_FILTER, REFRESH };
