import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Button,
  PageHeader,
  Divider,
  Typography,
  message,
  Alert,
} from "antd";
import axios from "axios";

import "../css/enable_stripe.css";
import { PAYMENT_ANALYTICS_BY_DATE_YAML } from "../constants/paymentAnalytic";
import MonacoEditor from "react-monaco-editor";
import yaml from "yaml";
import ShouldRender from "./ShouldRender";

const { Title } = Typography;

const PaymentAnalytics = ({ selectedRegressionTestData = {} }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [paymentAnalyticsYAML, setPaymentAnalyticsYAML] = useState(
    PAYMENT_ANALYTICS_BY_DATE_YAML()
  );

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { paymentAnalytic: paymentAnalytic } = yaml.parse(
        selectedRegressionTestData.config
      );
      setPaymentAnalyticsYAML(
        yaml.stringify({ paymentAnalytic: paymentAnalytic })
      );
    } else if (selectedRegressionTestData.id) {
      setPaymentAnalyticsYAML("paymentAnalytic:\n");
    }
  }, [selectedRegressionTestData]);

  const editorDidMount = (editor, monaco) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const { paymentAnalytic } = yaml.parse(paymentAnalyticsYAML);
      const resp = await axios.post(
        `${
          process.env.REACT_APP_API_MAIN_URL || ""
        }/api/testHelpers/payment-analytics/generate-double-wd-fee`,
        {
          ...paymentAnalytic,
        },
        {
          headers: {
            Authorization: localStorage.getItem("authToken"),
          },
        }
      );
      if (resp.data) {
        message.success(
          `${paymentAnalytic.start} - ${paymentAnalytic.end} generate success`
        );
        makeCsv(resp.data.analysisResults, resp.data.start, resp.data.end);
      } else {
        message.error(
          `${paymentAnalytic.start} - ${paymentAnalytic.end} generate failure`
        );
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  };

  const makeCsv = (analysisResults, start, end) => {
    if (!analysisResults) return;
    const header = {
      hcpId: "hcpId",
      hcpName: "hcpName",
      hcpStage: "hcpStage",
      date: "date",
      shiftId: "shiftId",
      transferId: "transferId",
      payoutId: "payoutId",
      sourceEvent: "sourceEvent",
      transferAmount: "transferAmount",
      payoutAmount: "payoutAmount",
      difference: "difference",
      refundMessage: "refundMessage",
      isInstantPay: "isInstantPay",
      is100InstantPayEnabled: "is100InstantPayEnabled",
    };
    const dataWithHeader = [header, ...analysisResults];
    const stringify = JSON.stringify(dataWithHeader);
    const csv = convertToCSV(stringify);
    const exportedFileName = `double-fee-analysis ${start} to ${end} .csv`;
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <PageHeader
        className="enableStripePageHeader"
        title="Double Withdrawal Fee Analysis"
        subtitle="Double Withdrawal Fee Analysis"
        extra={[
          <Button
            key="1"
            type="secondary"
            onClick={() => {
              window
                .open(
                  `https://dev.to/paulasantamaria/introduction-to-yaml-125f`
                )
                .focus();
            }}
          >
            Intro to YAML
          </Button>,
        ]}
      />
      <Divider className="enableStripeHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <Title level={4}>YAML Config</Title>
            <div>
              <ShouldRender condition={selectedRegressionTestData.name}>
                <Alert
                  style={{ marginBottom: ".5rem" }}
                  message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                  type="info"
                  showIcon
                />
              </ShouldRender>
            </div>
          </Row>
          <Row>
            <MonacoEditor
              height="400"
              language="javascript"
              theme="vs-dark"
              value={paymentAnalyticsYAML}
              options={options}
              editorDidMount={editorDidMount}
              onChange={(value) => {
                setPaymentAnalyticsYAML(value);
              }}
            />
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Get Data
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default PaymentAnalytics;
