import Icon from "@ant-design/icons";
import React from "react";

const CurrencySvg = () => (
  <svg fill="currentColor" viewBox="64 64 896 896" width="1em" height="1em">
    <path
      d="M888.123077 214.646154H135.876923c-47.261538 0-86.646154 39.384615-86.646154 86.646154v419.446154c0 47.261538 39.384615 86.646154 86.646154 86.646153h752.246154c47.261538 0 86.646154-39.384615 86.646154-86.646153V303.261538c0-49.230769-39.384615-88.615385-86.646154-88.615384zM236.307692 720.738462c0-57.107692-45.292308-100.430769-100.430769-100.43077v-216.615384c57.107692 0 100.430769-45.292308 100.430769-100.43077h551.384616c0 57.107692 45.292308 100.430769 100.430769 100.43077v216.615384c-57.107692 0-100.430769 45.292308-100.430769 100.43077H236.307692z"
      p-id="963"
    />
    <path
      d="M512 504.123077m-143.753846 0a143.753846 143.753846 0 1 0 287.507692 0 143.753846 143.753846 0 1 0-287.507692 0Z"
      p-id="964"
    />
  </svg>
);

export const CurrencyIcon = (props) => (
  <Icon component={CurrencySvg} {...props} />
);
