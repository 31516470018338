import { workerAndWorkPlaceTypesProvider } from "../workers/workerAndWorkPlaceTypesProvider";

const useDeprecatedWorkerJS =
  process.env.REACT_APP_USE_DEPRECATED_WORKERS === "true";

const FacilityTypes = {
  LONG_TERM_CARE: "Long Term Care",
  SURGERY_CENTER: "Surgery Center",
  HOSPICE: "Hospice",
  HOME_HEALTHCARE: "Home Healthcare",
  PSYCH_HOSPITAL: "Psych Hospital",
  MEDICAL_LAB: "Medical Lab",
  HOSPITAL: "Hospital",
};

/**
 * @deprecated use workerAndWorkPlaceTypesProvider instead
 */
const facilityTypes = useDeprecatedWorkerJS
  ? Object.values(FacilityTypes)
  : Object.keys(workerAndWorkPlaceTypesProvider.workPlaceTypes);

const chartingTypes = ["Electronic", "Paper"];

const FacilityStatusObj = {
  ONBOARDING: "onboarding",
  ENROLLED: "enrolled",
  PROBATION: "probation",
  SUSPENDED: "suspended",
  TERMINATED: "terminated",
  ARCHIVED: "archived",
  PENDING: "pending",
};

export const HcfSelfOnboardingCaseCreationStage = {
  FORM_SUBMITTED: "FORM_SUBMITTED",
  TOS_UNSIGNED: "TOS_UNSIGNED",
  PENDING_SIGN_IN: "PENDING_SIGN_IN",
  PENDING_POSTING_SHIFTS: "PENDING_POSTING_SHIFTS",
  ONBOARDING_COMPLETE: "ONBOARDING_COMPLETE",
};

const FacilityStatus = Object.values(FacilityStatusObj);

export {
  facilityTypes,
  FacilityTypes,
  chartingTypes,
  FacilityStatus,
  FacilityStatusObj,
};
