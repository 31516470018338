import { showDnrCheckboxBasedOnRating } from "./helpers";
import {
  ShiftReviewAction,
  ShiftReviewFormInput,
  ShiftReviewFormSubmissionStage,
  ShiftReviewMode,
  ShiftReviewState,
} from "./shiftReview.types";

export const initialFormState = {
  [ShiftReviewFormInput.SHIFT_REVIEW_RATING]: undefined,
  [ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK]: "",
  [ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER]: false,
  [ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES]: [],
  exclusionId: undefined,
  isLoading: true,
  formSubmissionStage: ShiftReviewFormSubmissionStage.NOT_STARTED,
  showDnr: false,
  dnrFieldTouched: false,
  mode: ShiftReviewMode.EDIT,
};

export const reducer = (state: ShiftReviewState = initialFormState, action) => {
  switch (action.type) {
    case ShiftReviewAction.UPDATE_RATING: {
      return {
        ...state,
        [ShiftReviewFormInput.SHIFT_REVIEW_RATING]: action.value,
        [ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES]:
          initialFormState[ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES],
        showDnr: showDnrCheckboxBasedOnRating(action.value),
        ...(state.dnrFieldTouched
          ? {}
          : {
              [ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER]:
                action.value === 1,
            }),
      };
    }
    case ShiftReviewAction.UPDATE_ADDITIONAL_FEEDBACK: {
      return {
        ...state,
        [ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK]: action.value,
      };
    }
    case ShiftReviewAction.UPDATE_DNR_WORKER: {
      return {
        ...state,
        [ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER]: action.value,
      };
    }
    case ShiftReviewAction.UPDATE_DNR_FIELD_TOUCHED: {
      return {
        ...state,
        dnrFieldTouched: action.value,
      };
    }
    case ShiftReviewAction.UPDATE_QUALITIES: {
      const selectedQuality: string = action.value;
      if (!action.value) return state;
      if (
        state[ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES].includes(
          action.value
        )
      ) {
        return {
          ...state,
          qualities: state[ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES].filter(
            (quality) => quality !== selectedQuality
          ),
        };
      }
      return {
        ...state,
        qualities: [
          ...state[ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES],
          selectedQuality,
        ],
      };
    }
    case ShiftReviewAction.FILL_FORM_DATA: {
      const { shiftReview, exclusion, reviewer } = action.data;
      const rating = shiftReview?.rating;
      const showDnrCheckbox = showDnrCheckboxBasedOnRating(rating);
      return {
        ...state,
        isLoading: false,
        ...(shiftReview
          ? {
              mode: ShiftReviewMode.VIEW,
              shiftReviewId: shiftReview.id,
              reviewerName: reviewer?.name,
              rating,
              [ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES]:
                shiftReview[ShiftReviewFormInput.SHIFT_REVIEW_QUALITIES],
              [ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK]:
                shiftReview[
                  ShiftReviewFormInput.SHIFT_REVIEW_ADDITIONAL_FEEDBACK
                ],
            }
          : {}),
        ...(exclusion.length
          ? {
              [ShiftReviewFormInput.SHIFT_REVIEW_DNR_WORKER]: showDnrCheckbox
                ? true
                : rating === 1,
              exclusionId: exclusion[0]._id,
            }
          : {}),
      };
    }
    case ShiftReviewAction.SUBMITTING_FORM: {
      return {
        ...state,
        formSubmissionStage: ShiftReviewFormSubmissionStage.STARTED,
      };
    }
    case ShiftReviewAction.FORM_SUBMISSION_FAILED: {
      return {
        ...state,
        formSubmissionStage: ShiftReviewFormSubmissionStage.FAILED,
      };
    }
    case ShiftReviewAction.FORM_SUBMITTED: {
      return {
        ...state,
        formSubmissionStage: ShiftReviewFormSubmissionStage.SUCCESS,
      };
    }
    case ShiftReviewAction.UPDATE_FORM_TO_EDIT_MODE: {
      const showDnrCheckbox = showDnrCheckboxBasedOnRating(state.rating);
      return {
        ...state,
        mode: ShiftReviewMode.EDIT,
        showDnr: showDnrCheckbox,
      };
    }
    case ShiftReviewAction.RESET_FORM: {
      return {
        ...initialFormState,
      };
    }
    default: {
      return state;
    }
  }
};
