import React from "react";

import { Alert } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

const FacilityPendingAlert = () => (
  <Alert
    icon={<InfoCircleFilled style={{ color: "#808080" }} />}
    style={{
      backgroundColor: "#F5F5F5",
      color: "#282828",
      borderColor: "#A9A9A9",
      marginBottom: "10px",
    }}
    message={
      <span>
        Your account is pending because you have past due invoices or you've
        reached your credit limit. You can still post new shifts, but no open
        shifts will be booked until this is solved. You can click here to check
        your <a href="/facility/invoice/history">invoices</a> and submit a
        payment to leave this situation Please contact us at{" "}
        <a
          href="mailto:billing@clipboardhealth.com"
          target="_blank"
          rel="noreferrer"
        >
          billing@clipboardhealth.com
        </a>{" "}
        for assistance.
      </span>
    }
    showIcon
  />
);

export { FacilityPendingAlert };
