import React, { useState, useEffect } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import { PageHeader, Button } from "antd";
import moment from "moment-timezone";

import { ModifyFacilityUserModal } from "./modifyUser";
import { UserTab } from "./userTab";
import { useSelector } from "react-redux";
import { HCF_USER_EVENTS } from "../../constants/firebaseEvents";
import { logEvent } from "../../utils/segment/logEvents";

const FacilityUser = ({ facilityId }) => {
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const { userId, admin } = useSelector((state) => state.session);

  useEffect(() => {
    if (!admin) {
      logEvent(HCF_USER_EVENTS.USERS_VIEWED);
    }
  }, []);

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    setUserToEdit(null);
  };

  const onUpdate = () => {
    setRefresh(moment().format());
    closeModal();
  };

  const onEdit = (user) => () => {
    setUserToEdit(user);
    openModal();
  };

  return (
    <>
      <PageHeader
        title="Users"
        extra={
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            data-testid="add-new-user-button"
            onClick={openModal}
          >
            New User
          </Button>
        }
      />
      <UserTab
        refresh={refresh}
        onEdit={onEdit}
        facilityId={facilityId || userId}
      />
      <ModifyFacilityUserModal
        visible={showModal}
        onUpdate={onUpdate}
        onCancel={closeModal}
        userToEdit={userToEdit}
        facilityId={facilityId ?? userId}
      />
    </>
  );
};

export { FacilityUser };
