import { WorkerTypes, NurseWorkerType } from "./WorkerTypes";

const WorkPlaceTypes = {
  SURGERY_CENTER: "Surgery Center",
  LONG_TERM_CARE: "Long Term Care",
  HOSPICE: "Hospice",
  HOME_HEALTHCARE: "Home Healthcare",
  PSYCH_HOSPITAL: "Psych Hospital",
  MEDICAL_LAB: "Medical Lab",
  HOSPITAL: "Hospital",
};

const WorkPlaceTypesWithWorkerTypes = {
  [WorkPlaceTypes.LONG_TERM_CARE]: [
    WorkerTypes.CNA,
    WorkerTypes.LVN,
    WorkerTypes.RN,
    WorkerTypes.NP,
    WorkerTypes.CAREGIVER,
    WorkerTypes.MEDICAL_TECHNICIAN,
    WorkerTypes.QMAP,
    WorkerTypes.MEDICAL_AIDE,
    WorkerTypes.SITE_LEAD,
    NurseWorkerType.NURSE,
  ],
  [WorkPlaceTypes.SURGERY_CENTER]: [
    WorkerTypes.PACU_RN,
    WorkerTypes.OR_RN,
    WorkerTypes.SPD_TECH,
    WorkerTypes.SURG_TECH,
    WorkerTypes.CRNA,
  ],
  [WorkPlaceTypes.HOSPICE]: [
    WorkerTypes.LVN,
    WorkerTypes.RN,
    WorkerTypes.PT,
    WorkerTypes.PTA,
    WorkerTypes.HHA,
  ],
  [WorkPlaceTypes.HOME_HEALTHCARE]: [
    WorkerTypes.LVN,
    WorkerTypes.RN,
    WorkerTypes.PT,
    WorkerTypes.PTA,
    WorkerTypes.HHA,
  ],
  [WorkPlaceTypes.PSYCH_HOSPITAL]: [
    WorkerTypes.CNA,
    WorkerTypes.LVN,
    WorkerTypes.RN,
    WorkerTypes.NP,
    WorkerTypes.CAREGIVER,
    WorkerTypes.MEDICAL_TECHNICIAN,
    NurseWorkerType.NURSE,
  ],
  [WorkPlaceTypes.MEDICAL_LAB]: [
    WorkerTypes.PHLEBOTOMIST,
    WorkerTypes.MLT,
    WorkerTypes.MEDICAL_ASSISTANT,
    WorkerTypes.EMT,
    WorkerTypes.RESPIRATORY_THERAPIST,
    WorkerTypes.PARAMEDIC,
    WorkerTypes.NON_CLINICAL,
    WorkerTypes.PHARM_TECH,
    WorkerTypes.MEDICAL_TECHNICIAN,
    WorkerTypes.MEDICAL_AIDE,
    WorkerTypes.CNA,
    WorkerTypes.LVN,
    WorkerTypes.RN,
  ],
  [WorkPlaceTypes.HOSPITAL]: [
    WorkerTypes.CNA,
    WorkerTypes.LVN,
    WorkerTypes.RN,
    WorkerTypes.NP,
    WorkerTypes.PHLEBOTOMIST,
    WorkerTypes.MEDICAL_ASSISTANT,
  ],
};

export { WorkPlaceTypes, WorkPlaceTypesWithWorkerTypes };
