import React, { useEffect } from "react";
import {
  Form,
  Row,
  message,
  Button,
  PageHeader,
  Divider,
  Spin,
  Typography,
  Alert,
} from "antd";
import axios from "axios";
import "../css/create_hcp.css";
import yaml from "yaml";
import MonacoEditor from "react-monaco-editor";
import { HCPDefaultYAMLValue } from "../constants/hcp";
import ShouldRender from "./ShouldRender";

const { Title } = Typography;

const CreateHcp = ({ selectedRegressionTestData = {} }) => {
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [HCPYAMLData, setHCPYAMLData] = React.useState(HCPDefaultYAMLValue);

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { hcp: hcpData } = yaml.parse(selectedRegressionTestData.config);
      setHCPYAMLData(yaml.stringify({ hcp: hcpData }));
    } else if (selectedRegressionTestData.id) {
      setHCPYAMLData("hcp:\n");
    }
  }, [selectedRegressionTestData]);

  const editorDidMount = (editor, monaco) => {
    if (!window.monacoEditor) {
      Object.defineProperty(window, "monacoEditor", {
        value: editor,
        writable: false,
      });
    }
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const onSubmit = (yamlData) => {
    try {
      setLoading(true);
      const hcpData = yaml.parse(HCPYAMLData);

      axios
        .post(
          `${
            process.env.REACT_APP_API_MAIN_URL || ""
          }/api/testHelpers/createUser`,
          {
            ...hcpData.hcp,
          }
        )
        .then((res) => {
          setResponse(res.data);
          message.success("Success");
        })
        .catch(function (e) {
          message.error(`Error: ${e.response.data}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      message.error(`Error adding HCP -  ${error.message}`);
      setLoading(false);
    }
  };

  if (response && response.link) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Button onClick={() => navigator.clipboard.writeText(response.link)}>
          Copy login link
        </Button>
      </Row>
    );
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 16, span: 8 },
  };

  return (
    <Spin spinning={loading}>
      <div>
        <PageHeader
          className="createHCPHeader"
          title="Create HCP"
          subtitle="Generate a new HCP"
          extra={[
            <Button
              type="secondary"
              key={"key"}
              onClick={() => {
                window
                  .open(
                    `https://dev.to/paulasantamaria/introduction-to-yaml-125f`
                  )
                  .focus();
              }}
            >
              Intro to YAML
            </Button>,
          ]}
        />
        <Divider className="createHCPHeaderDivider" />
        <Row
          justify="start"
          align="middle"
          style={{ width: "100%", height: "100%" }}
        >
          <Form
            {...layout}
            name="basic"
            form={form}
            onFinish={onSubmit}
            style={{ width: "600px" }}
          >
            <Row>
              <Title level={4}>YAML Config</Title>
              <div>
                <ShouldRender condition={selectedRegressionTestData.name}>
                  <Alert
                    style={{ marginBottom: ".5rem" }}
                    message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                    type="info"
                    showIcon
                  />
                </ShouldRender>
              </div>
            </Row>
            <Row>
              <MonacoEditor
                height="400"
                language="javascript"
                theme="vs-dark"
                value={HCPYAMLData}
                options={options}
                editorDidMount={editorDidMount}
                onChange={(value) => {
                  setHCPYAMLData(value);
                }}
              />
            </Row>

            <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Row>
          </Form>
        </Row>
      </div>
    </Spin>
  );
};

export default CreateHcp;
