export const getBuildNameAwareEnv = () => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "production";
    case "staging":
      if (process.env.REACT_APP_BUILD_NAME === "staging") {
        return "staging";
      }
      return "dev";
    default:
      return "local";
  }
};
