import {
  getUpcomingShiftsGrouped,
  fetchShiftWithReviews,
} from "../containers/chat/chatApi";
import moment from "moment-timezone";

export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
export const SET_CHANNELS = "SET_CHANNELS";
export const SET_UPCOMING_SHIFTS = "SET_UPCOMING_SHIFTS";
export const SET_REVIEWS = "SET_REVIEWS";

const initialState = {
  channels: [],
};

const sortChannels = (list) => {
  return list.sort((a, b) => {
    if (a.unreadMessageCount && b.unreadMessageCount)
      return b.lastMessage.createdAt - a.lastMessage.createdAt;

    if (a.unreadMessageCount) return -1;
    if (b.unreadMessageCount) return 1;

    if (a.shift && b.shift) return moment(a.shift.start).diff(b.shift.start);
    if (a.shift) return -1;
    if (b.shift) return 1;

    const a1 = a.lastMessage ? a.lastMessage.createdAt : a.createdAt;
    const b1 = b.lastMessage ? b.lastMessage.createdAt : b.createdAt;

    return b1 - a1;
  });
};

const setMetadata = (list) => {
  for (const item of list) {
    const { facilityName, hcpName, lastBooked, agentReq } =
      item.getCachedMetaData();
    item.metadata = {
      facilityName,
      hcpName,
      lastBooked,
      agentReq,
    };
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANNELS:
      const newChannels = action.data;
      setMetadata(newChannels);

      const oldChannels = state.channels;
      return {
        channels: sortChannels(
          newChannels.map((channel) => {
            const oldChannel = oldChannels.find(
              (oldItem) => oldItem.url === channel.url
            );
            if (oldChannel) return { ...channel, shift: oldChannel.shift };

            return channel;
          })
        ),
      };
    case UPDATE_CHANNEL:
      const updatedChannel = action.data;
      const foundChannel = state.channels.find(
        (item) => item.url === updatedChannel.url
      );
      if (foundChannel) {
        return {
          channels: sortChannels(
            state.channels.map((item) => {
              return item.url === updatedChannel.url
                ? { ...item, ...updatedChannel }
                : item;
            })
          ),
        };
      } else {
        return {
          channels: sortChannels([...state.channels, updatedChannel]),
        };
      }
    case SET_UPCOMING_SHIFTS:
      const shiftsGroupedByAgentId = action.data;

      return {
        channels: sortChannels(
          state.channels.map((channel) => {
            const shift = shiftsGroupedByAgentId[channel.url.split("_")[1]];
            if (shift) return { ...channel, shift };

            return channel;
          })
        ),
      };
    case SET_REVIEWS:
      const reviewsGroupedByAgentId = action.data;
      return {
        channels: sortChannels(
          state.channels.map((channel) => {
            const shiftWithReviews =
              reviewsGroupedByAgentId[channel.url.split("_")[1]];
            if (shiftWithReviews) return { ...channel, shiftWithReviews };

            return channel;
          })
        ),
      };
    default:
      return state;
  }
};

export const setChannels = async (dispatch, channels) => {
  dispatch({
    data: channels,
    type: SET_CHANNELS,
  });

  if (!channels.length) return;

  fetchAndSetUpcomingShifts(dispatch);
  fetchAndSetShiftReviews(dispatch);
};

export const fetchAndSetUpcomingShifts = async (dispatch) => {
  const shiftsGroupedByFacilityId = await getUpcomingShiftsGrouped();

  dispatch({
    type: SET_UPCOMING_SHIFTS,
    data: shiftsGroupedByFacilityId,
  });
};

export const fetchAndSetShiftReviews = async (dispatch) => {
  const faciliTyReviews = await fetchShiftWithReviews();
  const reviewsGroupedByAgentId = faciliTyReviews.reduce((prev, current) => {
    if (!prev[current.agentId]) {
      prev[current.agentId] = [current];
    } else {
      prev[current.agentId] = [...prev[current.agentId], current];
    }
    return prev;
  }, {});

  dispatch({
    data: reviewsGroupedByAgentId,
    type: SET_REVIEWS,
  });
};

export const updateChannel = (dispatch, channel) => {
  dispatch({
    data: channel,
    type: UPDATE_CHANNEL,
  });
};
