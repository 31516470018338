export const SHIFT_REVIEW_QUALITY_OPTIONS_RATING_GREATER_THAN_THREE = [
  "Attentive",
  "Arrived on time",
  "Communicative",
  "Completed all tasks",
  "Friendly",
  "Professional",
];

export const SHIFT_REVIEW_QUALITY_OPTIONS_FOR_RATING_LESS_THAN_THREE = [
  "Arrived late / left early",
  "Did not complete all tasks",
  "Inappropriately dressed",
  "Lacks training",
  "Mistreated staff / patients",
  "Rude",
  "Unprofessional",
];

export const SHIFT_REVIEW_QUALITY_OPTIONS_FOR_RATING_EQUAL_TO_THREE = [
  "Arrived on time",
  "Friendly",
  "Completed all tasks",
  "Professional",
  "Arrived late / left early",
  "Did not complete all tasks",
  "Inappropriately dressed",
  "Lacks training",
];

export const SHIFT_REVIEW_DNR_REASON =
  "DNR Added by Facility via the Shift Review Form";
