import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { updateDrawer } from "../../modules/session";
import { AgentExperience } from "../../containers/app/agentExperience";

const PrivateRoute = ({
  isAuthorized,
  isAgent,
  pseudo,
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const { userId } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    const qs = queryString.parse(location.search);
    const { drawer, userId } = qs;

    if (!pseudo || !userId || !drawer) return;

    pseudo({ userId }).then(() =>
      drawer ? dispatch(updateDrawer({ option: true, type: drawer })) : null
    );
  }, []);

  useEffect(() => {
    const qs = queryString.parse(location.search);
    const { requestedUrl, drawer } = qs;
    const redirectURL = localStorage.getItem("redirectURL");
    if (!redirectURL && requestedUrl) {
      let url = requestedUrl;
      if (drawer) {
        url = requestedUrl + `&drawer=${drawer}`;
      }
      localStorage.setItem("redirectURL", url);
    }
  }, [location]);

  const renderRoute = (props) => {
    if (!isAuthorized) {
      return <Redirect to="/enter" />;
    }
    const redirectURL = localStorage.getItem("redirectURL");

    if (redirectURL && userId) {
      const url = redirectURL;
      localStorage.removeItem("redirectURL");
      return <Redirect to={url} />;
    }

    if (isAgent) {
      return <AgentExperience />;
    }

    return <Component {...props} />;
  };
  return <Route {...rest} render={renderRoute} />;
};

export { PrivateRoute };
