import {
  ApiOutlined,
  AreaChartOutlined,
  CalendarOutlined,
  DatabaseOutlined,
  FileDoneOutlined,
  LoginOutlined,
  LogoutOutlined,
  MessageOutlined,
  ScheduleOutlined,
  TeamOutlined,
  UserOutlined,
  ControlOutlined,
  CommentOutlined,
} from "@ant-design/icons";

import { Badge, Menu, Modal } from "antd";
import { includes } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { EMPLOYEE_ROLES, EMPLOYEE_API_ROLES } from "../../constants/employee";
import { FACILITY_ROLES } from "../../constants/facility";
import { CurrencyIcon } from "../../icons/currency";
import { logEvent } from "../../utils/segment/logEvents";
import { ADMIN_EVENTS } from "../../constants/firebaseEvents";
import { useFlags } from "launchdarkly-react-client-sdk";
import { USER_TYPES, EMPLOYEE_EMAIL_SUFFIX } from "../../constants/userTypes";

const { SubMenu } = Menu;

const ResponsiveMenu = styled(Menu)`
  line-height: 64px !important;
  @media (max-width: 992px) {
    float: none;
    line-height: 0;
  }
`;

const adminMenus = (roles) => [
  <SubMenu
    key="accounts"
    data-cbh-id="menu-item-accounts"
    title={
      <span>
        <TeamOutlined />
        <span>Accounts</span>
      </span>
    }
  >
    <Menu.Item key="agents" data-cbh-id="menu-item-agents">
      <Link to="/admin/accounts/agents">HCPs</Link>
    </Menu.Item>
    <Menu.Item key="facilities" data-cbh-id="menu-item-facilities">
      <Link to="/admin/accounts/facilities">Facilities</Link>
    </Menu.Item>
    {roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE) && (
      <Menu.Item key="employees" data-cbh-id="menu-item-employees">
        <Link to="/admin/accounts/employees">Employees</Link>
      </Menu.Item>
    )}
    {(roles.includes(EMPLOYEE_ROLES.DOCUMENTS) ||
      roles.includes(EMPLOYEE_ROLES.DOCUMENT_CREATION)) && (
      <Menu.Item key="requirements" data-cbh-id="menu-item-requirements">
        <Link to="/admin/accounts/requirements">Requirements</Link>
      </Menu.Item>
    )}
    <Menu.Item key="locations" data-cbh-id="menu-item-locations">
      <Link to="/admin/accounts/locations">Locations</Link>
    </Menu.Item>
    <Menu.Item key="changelog" data-cbh-id="menu-item-changelog">
      <Link to="/admin/accounts/changelog">Change Log</Link>
    </Menu.Item>
    <Menu.Item key="exclusions" data-cbh-id="menu-item-exclusions">
      <Link to="/admin/accounts/exclusions">DNRs</Link>
    </Menu.Item>
  </SubMenu>,
  <SubMenu
    key="shifts"
    data-cbh-id="menu-item-shifts"
    title={
      <span>
        <ScheduleOutlined />
        <span>Shifts</span>
      </span>
    }
    onTitleClick={() => logEvent(ADMIN_EVENTS.VIEW_SHIFT_MENU_OPTIONS)}
  >
    <Menu.Item key="staffed" data-cbh-id="menu-item-shifts-staffed">
      <Link to="/admin/dashboard/staffed">Staffed Shifts</Link>
    </Menu.Item>
    <Menu.Item key="verification" data-cbh-id="menu-item-shifts-verification">
      <Link to="/admin/dashboard/verification">Shift Verification</Link>
    </Menu.Item>
    <Menu.Item key="upcoming" data-cbh-id="menu-item-shifts-upcoming">
      <Link to="/admin/dashboard/upcoming">Upcoming 2 weeks</Link>
    </Menu.Item>
    <Menu.Item key="calendar" data-cbh-id="menu-item-shifts-calendar">
      <Link to="/admin/dashboard/calendar">Calendar</Link>
    </Menu.Item>
  </SubMenu>,
  ...(roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE) ||
  roles.includes(EMPLOYEE_API_ROLES.INVOICE_EDIT)
    ? [
        <SubMenu
          key="Invoice"
          data-cbh-id="menu-item-invoice"
          title={
            <span>
              <FileDoneOutlined />
              <span>Invoice</span>
            </span>
          }
        >
          <Menu.Item key="byperiod" data-cbh-id="menu-item-invoice-byperiod">
            <Link to="/admin/accounts/invoicing/byperiod">By Period</Link>
          </Menu.Item>
          <Menu.Item key="bynumber" data-cbh-id="menu-item-invoice-bynumber">
            <Link to="/admin/accounts/invoicing/bynumber">By Number</Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : roles.includes(EMPLOYEE_ROLES.BILLING)
    ? [
        <SubMenu
          key="Invoice"
          data-cbh-id="menu-item-invoice"
          title={
            <span>
              <FileDoneOutlined />
              <span>Invoice</span>
            </span>
          }
        >
          <Menu.Item key="bynumber" data-cbh-id="menu-item-invoice-bynumber">
            <Link to="/admin/accounts/invoicing/bynumber">By Number</Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : []),
  <SubMenu
    key="payroll"
    data-cbh-id="menu-item-payroll"
    title={
      <span>
        <CurrencyIcon />
        <span>Payroll</span>
      </span>
    }
  >
    <Menu.Item key="payroll" data-cbh-id="menu-item-payroll-agent">
      <Link to="/agent/payroll">Payroll</Link>
    </Menu.Item>
  </SubMenu>,
  <SubMenu
    key="logs"
    data-cbh-id="menu-item-logs"
    title={
      <span>
        <ApiOutlined />
        <span>Logs</span>
      </span>
    }
  >
    <Menu.Item key="shift" data-cbh-id="menu-item-shift-logs">
      <Link to="/admin/logs/shift">Shifts</Link>
    </Menu.Item>
    <Menu.Item key="hcp-app-logs" data-cbh-id="menu-item-hcp-app-logs">
      <Link to="/admin/logs/hcp-app-logs">HCP App Logs</Link>
    </Menu.Item>
  </SubMenu>,
  ...(roles.includes(EMPLOYEE_ROLES.SHIFT_PRICING)
    ? [
        <SubMenu
          key="shiftPayments"
          data-cbh-id="menu-item-shiftpayments"
          title={
            <span>
              <CurrencyIcon />
              <span>Shift Payments</span>
            </span>
          }
        >
          <Menu.Item
            key="pay-rates"
            data-cbh-id="menu-item-shiftpayments-pay-rates"
          >
            <Link to="/admin/shift-payments/pay-rates">Pay Rates</Link>
          </Menu.Item>
          <Menu.Item
            key="charge-differentials"
            data-cbh-id="menu-item-shiftpayments-charge-differentials"
          >
            <Link to="/admin/shift-payments/charge-differentials">
              Charge Differentials
            </Link>
          </Menu.Item>
          <Menu.Item
            key="pay-differentials"
            data-cbh-id="menu-item-shiftpayments-pay-differentials"
          >
            <Link to="/admin/shift-payments/pay-differentials">
              Pay Differentials
            </Link>
          </Menu.Item>
          <Menu.Item
            key="change-logs"
            data-cbh-id="menu-item-shiftpayments-change-logs"
          >
            <Link to="/admin/shift-payments/change-logs">Change Logs</Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : []),
];

const agentMenus = [
  <Menu.Item key="dashboard" data-cbh-id="menu-item-dashboard">
    <Link to="/agent/dashboard">
      <CalendarOutlined />
      <span>Calendar</span>
    </Link>
  </Menu.Item>,
  <SubMenu
    key="list-payroll"
    data-cbh-id="menu-item-agent-list-payroll"
    title={
      <span>
        <CurrencyIcon />
        <span>Payroll</span>
      </span>
    }
  >
    <Menu.Item key="payroll" data-cbh-id="menu-item-agent-payroll">
      <Link to="/agent/payroll">Payroll</Link>
    </Menu.Item>
    <Menu.Item
      key="stripe-payouts"
      data-cbh-id="menu-item-agent-stripe-payouts"
    >
      <Link to="/agent/stripe-payouts">Stripe Payouts</Link>
    </Menu.Item>
  </SubMenu>,
  <Menu.Item key="attendance" data-cbh-id="menu-item-agent-attendance">
    <Link to="/agent/attendance">
      <CalendarOutlined />
      <span>Shift Cancellations</span>
    </Link>
  </Menu.Item>,
];

const facilityMenus = (
  roles,
  admin,
  userProfileRenderer,
  ldFlags,
  unreadChannelCount,
  emailOfLoggedInUser,
  profile
) => {
  let menus = [
    <Menu.Item key="dashboard" data-cbh-id="menu-item-dashboard">
      <Link to="/facility/dashboard">
        <CalendarOutlined />
        <span>Calendar</span>
      </Link>
    </Menu.Item>,
  ];

  menus = [
    ...menus,
    <SubMenu
      key="management"
      data-cbh-id="menu-item-management"
      title={
        <span>
          <ControlOutlined />
          <span>Management</span>
        </span>
      }
    >
      <Menu.Item key="users" data-cbh-id="menu-item-users">
        <Link to="/facility/users">Users</Link>
      </Menu.Item>
      <Menu.Item
        key="worker-instructions"
        data-cbh-id="menu-item-worker-instructions"
      >
        <Link to="/facility/worker-instructions">Worker Instructions</Link>
      </Menu.Item>
      <Menu.Item key="covid-data" data-cbh-id="menu-item-covid-data">
        <Link to="/facility/covid-data">COVID</Link>
      </Menu.Item>
    </SubMenu>,
  ];

  if (
    ldFlags["chat"] &&
    (includes(roles, FACILITY_ROLES.ADMIN) ||
      includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT))
  ) {
    menus.push(
      <Menu.Item key="chat" data-cbh-id="menu-item-chat">
        <Link to="/facility/chat">
          <MessageOutlined />
          <span>
            Chat{" "}
            <Badge
              style={{
                marginLeft: 6,
                boxShadow: "none",
                backgroundColor: "#e94a4d",
              }}
              count={unreadChannelCount}
            />
          </span>
        </Link>
      </Menu.Item>
    );
  }
  const hasViewHistoryRights =
    admin ||
    includes(roles, FACILITY_ROLES.ADMIN) ||
    includes(roles, FACILITY_ROLES.INVOICES);

  const hasViewUpcomingChargesRights =
    hasViewHistoryRights || includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT);

  menus = [
    ...menus,
    <SubMenu
      key="Invoice"
      data-cbh-id="menu-item-invoice"
      title={
        <span>
          <FileDoneOutlined />
          <span>Invoice</span>
        </span>
      }
    >
      {hasViewUpcomingChargesRights && (
        <Menu.Item
          key="upcoming-charges"
          data-cbh-id="menu-item-invoice-upcoming-charges"
        >
          <Link to="/facility/invoice/upcoming-charges">Upcoming Charges</Link>
        </Menu.Item>
      )}
      {hasViewHistoryRights && (
        <Menu.Item key="history" data-cbh-id="menu-item-invoice-history">
          <Link to="/facility/invoice/history">History</Link>
        </Menu.Item>
      )}
    </SubMenu>,
  ];

  return [...menus, userProfileRenderer];
};

const AppMenu = ({
  admin,
  isAgent,
  isAdmin,
  isFacility,
  hasSession,
  className,
  mode,
  selectable,
  profile,
  logout,
  closeMenu,
  enableDefaultSelectedKeys,
  unreadChannelCount,
  emailOfLoggedInUser,
  ...rest
}) => {
  const [selectedKey, setSelectedKey] = useState();
  const { channels } = useSelector((state) => state.chat);

  const { name, access } = useSelector((state) => state.session.user || {});

  const userName = profile ? profile.name : "Home";

  const { pathname } = useLocation();
  const routeKey = pathname.split("/").pop();
  const ldFlags = useFlags();

  useEffect(() => {
    setSelectedKey(routeKey);
  }, [routeKey]);

  const logoutConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      onOk: logout,
    });
  };

  const handleOnSelect = ({ key }) => {
    setSelectedKey([key]);
  };

  const path =
    (isAdmin && "/admin/profile") ||
    (isAgent && "/agent/profile") ||
    (isFacility && name && "/facility/profile") ||
    "/admin/profile";

  const userProfileRenderer = (
    <Menu.Item key="profile" data-cbh-id="menu-item-profile">
      <Link className="ellipsis" to={path}>
        {hasSession ? <UserOutlined /> : <LoginOutlined />}
        <span>{name ? name : userName}</span>
      </Link>
    </Menu.Item>
  );

  return (
    <ResponsiveMenu
      theme="dark"
      mode={mode}
      selectable={selectable || false}
      className={className}
      onClick={closeMenu}
      selectedKeys={enableDefaultSelectedKeys ? selectedKey : false}
      onSelect={handleOnSelect}
      defaultOpenKeys={isFacility ? ["management", "Invoice"] : []}
      {...rest}
    >
      {isAdmin && adminMenus(profile.roles).map((menu) => menu)}
      {isAgent && agentMenus.map((menu) => menu)}
      {isFacility
        ? facilityMenus(
            access,
            admin,
            userProfileRenderer,
            ldFlags,
            unreadChannelCount,
            emailOfLoggedInUser,
            profile
          ).map((menu) => menu)
        : // user profile should appear after the help section. so it is rendered different for facility login.
          userProfileRenderer}
      {hasSession && (
        <Menu.Item key="logout" data-cbh-id="menu-item-logout">
          {
            // eslint-disable-next-line
            <a onClick={logoutConfirm}>
              <LogoutOutlined />
              <span>Log out</span>
            </a>
          }
        </Menu.Item>
      )}
    </ResponsiveMenu>
  );
};

export { AppMenu };
