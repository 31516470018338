import { getBuildNameAwareEnv } from "./getBuildName";

const meta = () => {
  const env = {
    ENABLE_SHIFT_SIGN: true,
    REACT_APP_GOOGLE_MAP_API: process.env.REACT_APP_GOOGLE_MAP_API,
    REACT_APP_DOCUMENTS_SERVICE_URL:
      process.env.REACT_APP_DOCUMENTS_SERVICE_URL,
    REACT_PAYMENT_SERVICE_URL: process.env.REACT_PAYMENT_SERVICE_URL,
    REACT_APP_TEST_ENV: process.env.REACT_APP_TEST_ENV,
    REACT_APP_SHIFT_VERIFICATION_MAX_HOURS:
      process.env.REACT_APP_SHIFT_VERIFICATION_MAX_HOURS || 18,
    REACT_APP_SHIFT_LUNCH_MAX_HOURS:
      process.env.REACT_APP_SHIFT_LUNCH_MAX_HOURS || 1,
    MAX_SHIFT_DURATION_HOURS: 16,
    REACT_APP_SHIFT_VERIFICATION_BASE_URL:
      process.env.REACT_APP_SHIFT_VERIFICATION_BASE_URL,
    REACT_APP_IS_REAL_PRODUCTION: process.env.REACT_APP_IS_REAL_PRODUCTION,
  };

  const app = {
    dev: "localhost:3000",
    demo: "demo.clipboardhealth.com",
    ops: "ops.clipboardhealth.com",
    sandbox: "app-sandbox.clipboardhealth.com",
    staging: "app-staging.getbluebirdhealth.com",
    production: "clipboard.health",
  };

  const supportPhone = "408-837-0116";
  const supportEmail = "support@clipboardhealth.com";
  const zenDeskContact = "https://app.futuresimple.com/crm/contacts";
  const googleMapApi =
    env.REACT_APP_GOOGLE_MAP_API || "AIzaSyB_SP5vECJvO40dN1WbFSvPri5xpmL7_ow";
  const googleStreetMapViewApiUrl =
    "https://maps.googleapis.com/maps/api/streetview";
  const fileUploadUrl =
    "https://api.cloudinary.com/v1_1/clipboardhealth/auto/upload";

  const commonOptions = {
    shiftSign: env.ENABLE_SHIFT_SIGN,
    supportPhone,
    supportEmail,
    zenDeskContact,
    googleMapApi,
    googleStreetMapViewApiUrl,
    fileUploadUrl,
    cloudinaryCloudName: "bharanicbh",
    workerDocsLink: "https://cbh.care/docs",
    workerOpenShiftsLink: "https://cbh.care/openShifts",
    workerWebApp: "https://hcp.clipboard.health",
    firebaseConfig: {
      apiKey: "AIzaSyDTI5wYMxrvW_PqyPr4X5F1qSrWhNdJtgU",
      authDomain: "clipboard-app.firebaseapp.com",
      databaseURL: "https://clipboard-app.firebaseio.com",
      projectId: "clipboard-app",
      storageBucket: "clipboard-app.appspot.com",
      messagingSenderId: "651599910014",
      appId: "1:651599910014:web:1690f2328ca70ced14342f",
      measurementId: "G-EFPXTPH5QT",
    },
    mixpanelToken: "526833eb22638ba90aa0b358e4e4157c",
    salesForceBaseURL: "https://clipboardhealth--sandbox.lightning.force.com",
    documentServiceUrl: env.REACT_APP_DOCUMENTS_SERVICE_URL,
    testEnvironment: env.REACT_APP_TEST_ENV,
    shiftVerificationMaxHours: env.REACT_APP_SHIFT_VERIFICATION_MAX_HOURS,
    shiftLunchMaxHours: env.REACT_APP_SHIFT_LUNCH_MAX_HOURS,
    maxShiftDuration: env.MAX_SHIFT_DURATION_HOURS,
    baseChargeLimit: {
      CNA: 50,
      LVN: 70,
      RN: 90,
      CAREGIVER: 50,
      NP: 90,
      "Medical Technician": 50,
    },
    shiftVerificationBaseUrl: env.REACT_APP_SHIFT_VERIFICATION_BASE_URL,
    sendBirdAppId: process.env.REACT_APP_SENDBIRD_APP_ID,
  };

  const segment = {
    dev: {
      key: "e369WCLzzXytmMeSPHGMo0PARzFyBcX0",
    },
    sandbox: {
      key: "e369WCLzzXytmMeSPHGMo0PARzFyBcX0",
    },
    production: {
      key: "WBGrMGAgWwplwULUnWxSFLoorUM4JkH7",
    },
  };

  const PRODUCTION_HOSTS = [
    "beta.clipboard.health",
    "backup1.clipboard.health",
    "backup2.clipboard.health",
    "clipboard.health",
  ];

  const datadogRUM = {
    applicationId: process.env.REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID || "",
    clientToken: process.env.REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN || "",
    service: "admin-web-app",
    // this is an independent version for Datadog sourcemap
    // but if devops can use real production version, you can set it in your deployment script
    version:
      process.env.REACT_APP_ADMIN_DATADOG_RUM_SOURCEMAP_VERSION || "1.0.0",
    sampleRate: 100,
    trackInteractions: true,
    env: getBuildNameAwareEnv(),
    allowedTracingOrigins: [process.env.REACT_APP_API_MAIN_URL || ""],
  };

  const locationHost = window.location.host;

  const host =
    PRODUCTION_HOSTS.includes(locationHost) ||
    process.env.REACT_APP_IS_REAL_PRODUCTION
      ? app.production
      : locationHost;
  const stage = app.dev ? "dev" : app.sandbox ? "sandbox" : undefined;

  switch (host) {
    case app.dev:
      return {
        api: "http://localhost:5000/api",
        app: app.dev,
        ...commonOptions,
        mixpanelToken: "526833eb22638ba90aa0b358e4e4157c",
        documentServiceUrl: "http://localhost:3337/graphql",
        stage,
        segment: segment.dev,
        datadogRUM,
      };
    case app.sandbox:
      return {
        api: "/api",
        app: app.sandbox,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
        datadogRUM,
      };
    case app.demo:
      return {
        api: "/api",
        app: app.demo,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
        datadogRUM,
      };
    case app.ops:
      return {
        api: "/api",
        app: app.ops,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
        datadogRUM,
      };
    case app.staging:
      return {
        api: "/api",
        app: app.staging,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
        datadogRUM,
      };
    case app.production:
      return {
        api:
          locationHost === app.production ||
          process.env.REACT_APP_IS_REAL_PRODUCTION
            ? "https://api.clipboard.health/api"
            : "/api",
        app: app.production,
        ...commonOptions,
        cloudinaryCloudName: "clipboardhealth",
        firebaseConfig: {
          apiKey: "AIzaSyB_SP5vECJvO40dN1WbFSvPri5xpmL7_ow",
          authDomain: "clipboard-health.firebaseapp.com",
          databaseURL: "https://clipboard-health.firebaseio.com",
          projectId: "clipboard-health",
          storageBucket: "clipboard-health.appspot.com",
          messagingSenderId: "1098403931480",
          appId: "1:1098403931480:web:cf36f44ee505ab81",
          measurementId: "G-K65B9132JS",
        },
        mixpanelToken: "547bb68cc1aeb6e1119edd4d163d7c23",
        paymentServiceUrl: "https://cbh-payment.clipboard.health/api",
        salesForceBaseURL: "https://clipboardhealth.lightning.force.com",
        stage,
        documentServiceUrl:
          "https://documents-service-backend.prod.clipboardhealth.org/graphql",
        segment: segment.production,
        datadogRUM,
      };
    default:
      return {
        api: (process.env.REACT_APP_API_MAIN_URL || "") + "/api",
        app: "",
        ...commonOptions,
        stage,
        segment: segment.sandbox,
        datadogRUM,
      };
  }
};

export default meta;
