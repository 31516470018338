import { BunnyLogo, ClipboardLogo } from "../../components/logos";
import { Button, Drawer, Layout, Modal, Spin, Badge } from "antd";
import { FallbackLoader, LazyLoad } from "../../components/lazyLoad";
import { Link, Redirect, Route, useLocation } from "react-router-dom";
import React, {
  Fragment,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { get as _get, includes } from "lodash";
import { AccountSwitcher } from "./accountSwitcher/accountSwitcher";
import { AgentExperience } from "./agentExperience";
import { AnimatedLoading } from "../../components/animatedLoading";
import { AppFooter } from "./footer";
import { AppMenu } from "./menu";
import Clock from "react-live-clock";
import { EMPLOYEE_ROLES } from "../../constants/employee";
import { FACILITY_ROLES } from "../../constants/facility";
import { FacilityAccountSwitcher } from "./FacilityAccountSwitcher";
import { FacilitySuspensionAlert } from "./facilitySuspensionAlert";
import { FacilityPendingAlert } from "./facilityPendingAlert";
import { FacilityUser } from "../facilityUser";
import { HamburgerIcon } from "../../icons/hamburger";
import { PrivateRoute } from "../../components/routing";
import { invokeSegment } from "../../components/segmentSnippet";
import { isTruthy } from "../../utils/errors";
import { FacilityStatusObj } from "../../utils/facility";
import meta from "../../utils/meta";
import { singInWithCustomToken } from "../enter/firebase";
import qs from "query-string";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ChatPage } from "../chat/chat";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  initDatadogRUM,
  startDatadogRUMReplayRecording,
} from "../../utils/datadogRUM";
import { initBraze } from "frontend-admin/src/utils/braze";
import useZendeskMessaging from "../../hooks/useZendeskMessaging";
import { ShiftReview } from "frontend-admin/src/components/shiftReview";
import {
  HIDE_SHIFT_REVIEW_MODAL,
  HIDE_SHIFT_TIMECARD_MODAL,
} from "../../modules/shifts";
import { blankPage } from "../../components/blankSignin";

const SegmentDevKey = meta().segment.key;

const { Header, Content, Sider } = Layout;

const LOGIN_PATHS = ["/login/verify", "/enter"];
const SHOW_CONTENTS_PATHS = ["/facility/chat"];

const AuthorizedSignPage = lazy(() =>
  import("../accountManagement/authorizedSignPage").then((module) => ({
    default: module.AuthorizedSignPage,
  }))
);

const AdminAppLogin = lazy(() =>
  import("../enter/commonLogin").then((module) => ({
    default: module.AdminAppLogin,
  }))
);

const FirebaseLoginVerify = lazy(() =>
  import("../enter/commonLogin").then((module) => ({
    default: module.FirebaseLoginVerify,
  }))
);

const AgentDashboard = lazy(() =>
  import("../agentDashboard").then((module) => ({
    default: module.AgentDashboard,
  }))
);

const AdminDashboard = lazy(() =>
  import("../adminDashboard").then((module) => ({
    default: module.AdminDashboard,
  }))
);

const Profile = LazyLoad(lazy(() => import("../completeProfile")));
const EditProfile = lazy(() => import("../editProfile"));
const FacilityUserProfile = lazy(() =>
  import("../userProfile/facility").then((module) => ({
    default: module.FacilityUserProfile,
  }))
);

const Payroll = lazy(() => import("../payroll/payrollView"));

const StripePayouts = lazy(() =>
  import("../payroll/stripePayouts/stripePayouts")
);

const StrikeHistory = lazy(() => import("../payroll/strikeHistory"));

const FacilityDashboard = lazy(() =>
  import("../facilityDashboard").then((module) => ({
    default: module.FacilityDashboard,
  }))
);

const FacilityInvoiceHistory = lazy(() =>
  import("../facilityInvoiceHistory").then((module) => ({
    default: module.FacilityInvoiceHistory,
  }))
);

const FacilityWorkerInstructions = lazy(() =>
  import("../facilityWorkerInstructions").then((module) => ({
    default: module.FacilityWorkerInstructions,
  }))
);

const FacilityCovidData = lazy(() =>
  import("../facilityCovid").then((module) => ({
    default: module.FacilityCovidData,
  }))
);

const FacilityUpcomingCharges = lazy(() =>
  import("../facilityUpcomingCharges").then((module) => ({
    default: module.FacilityUpcomingCharges,
  }))
);

const Logs = lazy(() =>
  import("../logs").then((module) => ({ default: module.Logs }))
);

const ShiftPayments = lazy(() =>
  import("../shiftPayments").then((module) => ({
    default: module.ShiftPayments,
  }))
);

const AgentOnboarding = lazy(() => import("../onboarding/agent"));

const Accounts = lazy(() => import("../accountManagement"));

const PDFGenerator = lazy(() =>
  import("../accountManagement/invoice/pdfGenerator").then((module) => ({
    default: module.PDFGenerator,
  }))
);

const InvoiceView = lazy(() =>
  import("../../components/invoiceView").then((module) => ({
    default: module.InvoiceView,
  }))
);

const WorkerLanding = lazy(() => import("../workerLanding"));

const DocumentView = lazy(() =>
  import("../documents").then((module) => ({ default: module.DocumentView }))
);

const DocumentEdit = lazy(() =>
  import("../documents").then((module) => ({ default: module.DocumentEdit }))
);

const TimesheetView = lazy(() =>
  import("../accountManagement/invoice/timesheetView").then((module) => ({
    default: module.TimesheetView,
  }))
);

const TimesheetViewRedirect = lazy(() =>
  import("../accountManagement/invoice/timesheetViewRedirect").then(
    (module) => ({
      default: module.TimesheetViewRedirect,
    })
  )
);

const SignatoryShiftSign = lazy(() =>
  import("../agentDashboard/shiftSign/signatoryShiftSign").then((module) => ({
    default: module.SignatoryShiftSign,
  }))
);

const AdminCalendar = lazy(() =>
  import("../calendar").then((module) => ({
    default: module.AdminCalendar,
  }))
);

const SideMenuDrawer = styled(Drawer)`
  .ant-drawer-content,
  .ant-drawer-header {
    background: #001529;
  }

  .logo {
    display: inline-block;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-select {
    margin: 20px;
  }
`;

const Trigger = styled(HamburgerIcon)`
  color: white !important;
  line-height: 64px !important;
  margin: 0 10px;
  font-size: 20px;
`;
const ResponsiveDiv = styled.div`
  color: white !important;
  line-height: 64px !important;
  margin: 0 10px;
  font-size: 20px;
  display: none !important;
  @media (max-width: 992px) {
    display: inline-block !important;
  }
`;

const LogoContainer = styled(Link)`
  margin: 0 20px;
  line-height: 0;
  float: left;
  .logo {
    display: inline-block;
  }

  @media (max-width: 992px) {
    display: block;
    text-align: center;
    margin: 0;
    float: none;
  }
`;

const LocalTime = ({ tz }) => (
  <span
    className="white-text"
    style={{ display: "inline-block", padding: "0 20px", float: "right" }}
  >
    Local time: &nbsp;
    <Clock format={"h:mma"} ticking={true} timezone={tz} />
    {tz ? ` (${tz}) ` : ""}
  </span>
);

const AppLayout = ({
  admin,
  isAdmin,
  isAgent,
  isFacility,
  isSuspended,
  hasSession,
  location,
  session,
  get,
  getAdminProfile,
  loadingAdminProfile,
  initFirebaseSession,
  isFirebaseSession,
  getLocations,
  getEmployees,
  logout,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState({
    drawer: true,
    sideMenu: isTruthy(localStorage.isSideMenuCollapsed) || false,
  });
  const [scrollState, setScrollState] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    /*
      0 - Mobile (Phone Portrait)
      1 - Small (Phone Landscape)
      2 - Medium (Tablet)
      3 - Large (Standard Desktop)
      4 - XLarge
    */
    size: 3,
  });
  const [requestedURL, setRequestedURL] = useState(null);

  const { access } = useSelector((state) => state.session.user || {});
  const { shiftReview, timecardsListPreview } = useSelector(
    (state) => state.shifts
  );
  const dispatch = useDispatch();

  const Location = useLocation();
  const pathName = Location.pathname;
  const qString = qs.parse(location.search);
  const [isToken, setToken] = useState(qString.token);
  const [loading, setLoading] = useState(true);
  const { ready } = useSelector((state) => state.session || {});
  const isAuthorized = isTruthy(isAdmin || isAgent || isFacility);
  const ldFlags = useFlags();

  const checkForAccessToken = useCallback(async (token) => {
    const result = await singInWithCustomToken(token);
    if (result.error) {
      setToken(false);
      return;
    } else {
      setToken(true);
      return;
    }
  }, []);

  const closeShiftTimecardModal = useCallback(() => {
    dispatch({ type: HIDE_SHIFT_TIMECARD_MODAL });
  }, [dispatch]);

  useEffect(() => {
    initDatadogRUM();
    startDatadogRUMReplayRecording();
  }, []);

  useZendeskMessaging(isFacility, admin);

  useEffect(() => {
    if (!window.analytics) {
      invokeSegment(SegmentDevKey);
    }
    window.analytics.page();
  }, [Location]);

  const closeShiftReviewModal = useCallback(() => {
    dispatch({ type: HIDE_SHIFT_REVIEW_MODAL });
  }, [dispatch]);

  useEffect(() => {
    setRequestedURL(pathName);
  }, [isAuthorized]);

  useEffect(() => {
    const width = window.innerWidth;
    let size = 4;

    if (width < 576) {
      size = 0;
    } else if (width < 768) {
      size = 1;
    } else if (width < 992) {
      size = 2;
    } else if (width < 1200) {
      size = 3;
    }

    setScrollState((state) => ({ ...state, size }));
  }, []);

  useEffect(() => {
    // Workaround to avoid issues with new roles DB field. If user was
    // loggedIn and is an admin, we fetch the new admin profile to have
    // the roles field and prevent errors
    if (loadingAdminProfile) {
      getAdminProfile();
    }
  }, [loadingAdminProfile, getAdminProfile]);

  useEffect(() => {
    async function load() {
      setLoading(true);
      await initFirebaseSession();
      setLoading(false);
    }
    load();
  }, [initFirebaseSession]);

  useEffect(() => {
    if (session && session.userId && !session.admin) {
      async function load() {
        setLoading(true);
        await initBraze(session?.userId);
        setLoading(false);
      }
      load();
    }
  }, [initBraze]);

  useEffect(() => {
    if (loading || !ready) return;
    if (!isAuthorized) return;
    get();
    getLocations();
    getEmployees();
  }, [isAuthorized, get, getLocations, getEmployees, loading, ready]);

  useEffect(() => {
    if (loading || !ready) return;
    if (isToken) {
      checkForAccessToken(isToken);
    }
  }, [checkForAccessToken, loading, ready]);

  const { channels } = useSelector((state) => state.chat);

  let unreadChannelCount = 0;
  for (const channel of channels) {
    if (channel.unreadMessageCount)
      unreadChannelCount += channel.unreadMessageCount;
  }

  const toggleCollapsible = (field) => () => {
    setCollapsed((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));

    if (field === "sideMenu") {
      localStorage.setItem("isSideMenuCollapsed", !collapsed.sideMenu);
    }
  };

  const getUrlParams = () => {
    if (!location || !location.search) {
      return {};
    }
    return qs.parse(location.search);
  };

  const renderAgentDashboardRoute = () => {
    if (isAgent && !admin) {
      return <AgentExperience />;
    }

    if (isAgent) {
      return <AgentDashboard {...scrollState} />;
    }
    // pass url params through to /enter
    return <Redirect to={`/enter${location.search}`} />;
  };

  // TODO: this should be folded into the router below
  if (location.pathname.indexOf("/apply") >= 0) {
    if (hasSession) {
      window.location.href = "/enter";
      return;
    }
    return <AgentOnboarding location={location} />;
  }

  const urlParams = getUrlParams();
  const noScroll = "noScroll" in urlParams;

  const renderEnter = () => {
    const { date } = qs.parse(location.search);
    if (isAgent) {
      if (urlParams.url) {
        window.location.href = urlParams.url;
      } else {
        const to = date ? `/agent/dashboard?date=${date}` : "agent/dashboard";
        return <Redirect to={to} />;
      }
    } else if (isFacility) {
      const to = date
        ? `/facility/dashboard?date=${date}`
        : "facility/dashboard";
      return <Redirect to={to} />;
    } else if (isAdmin) {
      return <Redirect to="/admin/dashboard" />;
    }
    if (urlParams["adminId"]) {
      // no session...we were re-directed here from /admin/dashboard
      get({ userId: urlParams["adminId"], admin: true });
    }
    return <AdminAppLogin requestedURL={requestedURL} />;
  };

  if (loadingAdminProfile) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  const isDocumentAuthorized = () => {
    if (
      _get(session, "adminProfile.roles", []).includes(EMPLOYEE_ROLES.DOCUMENTS)
    ) {
      return true;
    } else if (isFacility) {
      if (!isFirebaseSession) {
        return true;
      }
      if (includes(access, FACILITY_ROLES.DOCUMENTS)) {
        return true;
      }
    }
    return false;
  };

  const isLoginPath = LOGIN_PATHS.includes(location.pathname);

  if (isFacility && isFirebaseSession && access === undefined) {
    return null;
  }

  const isInvoiceViewAuthorized = () => {
    if (includes(access, FACILITY_ROLES.INVOICES)) {
      return true;
    }

    if (isFacility && !isFirebaseSession) {
      return true;
    }

    return false;
  };
  const showAccountSwitcher = !noScroll && session.admin;
  const showFacilitySwitcher = !noScroll && isFacility && isFirebaseSession;
  if ((loading || !ready) && session.userId) return null;

  const showsBadge =
    ldFlags["chat"] &&
    (includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT));
  const cssMainDisplay = SHOW_CONTENTS_PATHS.find((path) =>
    location.pathname.startsWith(path)
  )
    ? "contents"
    : "block";
  return (
    <Fragment>
      <SideMenuDrawer
        title={
          <LogoContainer to="/enter">
            <ClipboardLogo height={63} width={100} />
            <BunnyLogo height={63} width={70} />
          </LogoContainer>
        }
        placement="left"
        onClose={toggleCollapsible("drawer")}
        visible={!collapsed.drawer}
      >
        {showAccountSwitcher && (
          <AccountSwitcher
            admin={admin}
            pseudo={props.pseudo}
            closeMenu={toggleCollapsible("drawer")}
          />
        )}
        {showFacilitySwitcher && (
          <FacilityAccountSwitcher
            userId={session.userId}
            pseudo={props.pseudo}
            closeMenu={toggleCollapsible("drawer")}
          />
        )}
        {!noScroll && <LocalTime tz={props.tz} />}
        {!noScroll && (
          <AppMenu
            style={{ width: 256 }}
            isAdmin={isAdmin}
            isAgent={isAgent}
            isFacility={isFacility}
            logout={logout}
            hasSession={hasSession}
            profile={props.profile}
            closeMenu={toggleCollapsible("drawer")}
            unreadChannelCount={unreadChannelCount}
            emailOfLoggedInUser={session?.user?.email}
          />
        )}
      </SideMenuDrawer>
      <div>
        <Layout style={noScroll ? null : { height: "100vh" }}>
          <Header style={{ padding: "0 10px" }}>
            <ResponsiveDiv style={{ position: "absolute", right: 0 }}>
              <Trigger
                className="trigger"
                onClick={toggleCollapsible("drawer")}
              />
              {showsBadge && (
                <Badge
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#e94a4d",
                    position: "relative",
                    top: -25,
                    right: 20,
                  }}
                  count={unreadChannelCount}
                />
              )}
            </ResponsiveDiv>
            <LogoContainer to="/enter">
              <ClipboardLogo height={60} width={140} />
              <BunnyLogo height={63} width={60} />
            </LogoContainer>
            {showAccountSwitcher && (
              <AccountSwitcher
                admin={admin}
                pseudo={props.pseudo}
                className="hide-on-med-and-down"
              />
            )}
            {showFacilitySwitcher && (
              <FacilityAccountSwitcher
                userId={session.userId}
                pseudo={props.pseudo}
              />
            )}
            {!noScroll && <LocalTime tz={props.tz} />}
          </Header>
          <Layout>
            {isAuthorized && !noScroll && (
              <Sider
                style={{ overflowY: "auto" }}
                width="15vw"
                className="hide-on-med-and-down"
                collapsible
                collapsed={collapsed.sideMenu}
                onCollapse={toggleCollapsible("sideMenu")}
              >
                <AppMenu
                  className="hide-on-med-and-down"
                  admin={admin}
                  isAdmin={isAdmin}
                  isAgent={isAgent}
                  isFacility={isFacility}
                  logout={logout}
                  hasSession={hasSession}
                  profile={props.profile}
                  mode="inline"
                  selectable={true}
                  enableDefaultSelectedKeys={true}
                  unreadChannelCount={unreadChannelCount}
                  emailOfLoggedInUser={session?.user?.email}
                />
              </Sider>
            )}
            <Content
              id="content-box"
              style={{ ...(noScroll ? null : { overflowY: "scroll" }) }}
              className={isLoginPath ? "full-screen" : ""}
            >
              <main style={{ display: cssMainDisplay }}>
                <Suspense fallback={<FallbackLoader />}>
                  {props.profile?.status === FacilityStatusObj.SUSPENDED && (
                    <FacilitySuspensionAlert userId={session.userId} />
                  )}
                  {props.profile?.status === FacilityStatusObj.PENDING && (
                    <FacilityPendingAlert />
                  )}
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/enter" />}
                  />
                  <Route exact path="/admin">
                    <Redirect to="/enter" />
                  </Route>
                  {(process.env.REACT_APP_NODE_ENV ?? import.meta.env.MODE) !==
                    "production" && (
                    <Route exact path="/blank" component={blankPage} />
                  )}
                  {/* Animated component route */}
                  <Route
                    exact
                    path="/signup/:externalId/:experimentId"
                    component={AnimatedLoading}
                  />
                  <PrivateRoute
                    exact
                    path="/login/verify"
                    component={FirebaseLoginVerify}
                    isAuthorized={!isAuthorized}
                  />
                  <Route exact path="/workers" component={WorkerLanding} />
                  <PrivateRoute
                    exact
                    path="/documents/:userId"
                    component={DocumentView}
                    isAuthorized={isDocumentAuthorized()}
                  />
                  <PrivateRoute
                    exact
                    path="/calendar"
                    component={() => <AdminCalendar pseudo={props.pseudo} />}
                    isAuthorized={isAdmin}
                    pseudo={props.pseudo}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/invoice/:id"
                    component={InvoiceView}
                    isAuthorized={
                      isAdmin || isInvoiceViewAuthorized() || isToken
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/admin/invoice/pdf/:id"
                    component={PDFGenerator}
                    isAuthorized={
                      isAdmin || isInvoiceViewAuthorized() || isToken
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/timesheet/:invoiceNumber/redirect"
                    component={TimesheetViewRedirect}
                    isAuthorized={isAdmin || isFacility}
                  />
                  <Route
                    exact
                    path="/timesheet/:id"
                    component={TimesheetView}
                  />
                  <PrivateRoute
                    exact
                    path="/timesheet/pdf/:id"
                    component={PDFGenerator}
                    isAuthorized={
                      isAdmin || isInvoiceViewAuthorized() || isToken
                    }
                  />
                  <Route
                    exact
                    path="/shiftSign/:id/:signatoryId"
                    component={SignatoryShiftSign}
                  />
                  <Route
                    exact
                    path="/signatorySign/:facilityId/:signatoryId"
                    component={AuthorizedSignPage}
                  />
                  <Route
                    path="/enter"
                    location={location}
                    render={renderEnter}
                  />

                  <PrivateRoute
                    exact
                    path="/facility/dashboard"
                    component={FacilityDashboard}
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    exact
                    path="/facility/users"
                    component={FacilityUser}
                    isAuthorized={isFacility || admin}
                  />

                  <PrivateRoute
                    exact
                    path="/facility/worker-instructions"
                    component={FacilityWorkerInstructions}
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    exact
                    path="/facility/covid-data"
                    component={FacilityCovidData}
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    path="/facility/chat/:facilityId?/:agentId?"
                    component={ChatPage}
                    isAuthorized={
                      ldFlags["chat"] &&
                      isFacility &&
                      (includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT))
                    }
                  />

                  <PrivateRoute
                    exact
                    path="/facility/invoice/history"
                    component={FacilityInvoiceHistory}
                    isAuthorized={
                      isFacility &&
                      (admin ||
                        includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES))
                    }
                  />

                  <PrivateRoute
                    exact
                    path="/facility/invoice/upcoming-charges"
                    component={FacilityUpcomingCharges}
                    isAuthorized={
                      isFacility &&
                      (admin ||
                        includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES) ||
                        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT))
                    }
                  />

                  <PrivateRoute
                    exact
                    path="/facility/profile"
                    component={FacilityUserProfile}
                    isAuthorized={isFacility}
                  />

                  <Route
                    exact
                    path="/agent/dashboard"
                    location={location}
                    render={renderAgentDashboardRoute}
                  />
                  <PrivateRoute
                    path="/agent/payroll"
                    component={Payroll}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent || isAdmin}
                  />
                  <PrivateRoute
                    path="/agent/stripe-payouts"
                    component={StripePayouts}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />
                  <PrivateRoute
                    path="/agent/attendance"
                    component={StrikeHistory}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />
                  <PrivateRoute
                    exact
                    path="/agent/profile"
                    component={EditProfile}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />

                  <PrivateRoute
                    path="/admin/dashboard"
                    component={AdminDashboard}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/profile"
                    component={EditProfile}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path="/admin/accounts"
                    component={Accounts}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path="/admin/logs"
                    component={Logs}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path="/admin/shift-payments"
                    component={ShiftPayments}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/documents/:userId"
                    component={DocumentEdit}
                    isAuthorized={session.adminProfile?.roles.includes(
                      EMPLOYEE_ROLES.DOCUMENTS
                    )}
                  />
                </Suspense>
              </main>
              <AppFooter className="hide-on-large-only" />
            </Content>
          </Layout>
          <AppFooter
            style={!noScroll ? { height: "48px", paddingTop: "16px" } : null}
            className="hide-on-med-and-down"
          />
        </Layout>
      </div>

      <Modal
        title="Complete your profile"
        visible={props.profile && props.profile.name === "" && !session.admin}
        closable={false}
        footer={null}
      >
        <Profile />
      </Modal>

      <Modal
        title="Welcome to Clipboard Health!"
        visible={"welcomeAgent" in urlParams}
        closable={false}
        onOk={() => props.history.push(location.path)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => props.history.push(location.path)}
          >
            Get Started
          </Button>,
        ]}
      >
        <p>
          Let’s make some money! Pick a day, pick a shift, and mark your
          interest.
          <br />
          <br />
          Love,
          <br />- The Clipboard Team
        </p>
      </Modal>
      {!!shiftReview.showModal && (
        // The modal is removed and re-rendered to destroy the total component and recreate on modal close to avoid persisting state.
        <Modal
          visible={!!shiftReview.showModal}
          footer={false}
          size="small"
          onCancel={closeShiftReviewModal}
        >
          <ShiftReview {...shiftReview} closeModal={closeShiftReviewModal} />
        </Modal>
      )}
      {timecardsListPreview?.length > 0 && (
        <Modal
          visible={timecardsListPreview?.length > 0}
          footer={false}
          onCancel={closeShiftTimecardModal}
        >
          <FilePreviewList fileList={timecardsListPreview} />
        </Modal>
      )}
    </Fragment>
  );
};

export { AppLayout };
