import React from "react";
import { ApolloProvider } from "@apollo/client";
import { GraphQlClient } from "./graphql/client";
import {
  Modal,
  Row,
  Select,
  Spin,
  Space,
  Button,
  Input,
  PageHeader,
  Divider,
  message,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { LoadingOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import CreateHcp from "./components/CreateHcp";
import CreateHcf from "./components/CreateHcf";
import ShiftAction from "./ShiftAction";
import { getSignInLink, isLoggedIn } from "./common/authService";
import "./css/index.css";
import ShouldRender from "./components/ShouldRender";
import AssignShift from "./components/AssignShift";
import RunService from "./components/RunService";
import EnableStripe from "./components/EnableStripe";
import PaymentAnalytics from "./components/PaymentAnalytics";
import HCPClockOut from "./components/HCPClockOut";
import UploadDocs from "./components/UploadDocs";
import ApproveDocs from "./components/ApproveDocs";
import DownloadCSV from "./components/DownloadInvoiceCSV";
import TriggerQfa from "./components/TriggerQfa";
import FetchOTP from "./components/FetchOTP";
import firebase from "firebase/app";
import { FirebaseAuth } from "../../projects/frontend-admin/src/utils/firebase";

const TestPanel = () => {
  const [panelVisible, setPanelVisible] = React.useState(false);
  const [loginEmail, setLoginEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [selectedRegressionTest, setSelectedRegressionTest] = React.useState();
  const [selectedState, setSelectedState] = React.useState("");
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [selectedTestTitle, setSelectedTestTitle] = React.useState(false);
  const [HCPCreated, setHCPCreated] = React.useState(false);
  const [HCFCreated, setHCFCreated] = React.useState(false);
  const [ShiftCreated, setShiftCreated] = React.useState(false);
  const [rHcpData, setRHcpData] = React.useState({});
  const [rHcfData, setRHcfData] = React.useState({});
  const [rShiftData, setRShiftData] = React.useState({});

  const signMeIn = async () => {
    await handleClearSiteData(false);
    const email = loginEmail;
    const result = await getSignInLink(email);
    if (!result.link) {
      message.error("An error has been encountered while fetching signIn link");
      return;
    }
    if (!result.isMobileApp) {
      await FirebaseAuth.signInWithEmailLink(email, result.link);
      FirebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      return;
    }
    location.replace(result.link);
  };

  const onEmailChange = (e) => {
    setLoginEmail(e.target.value);
  };

  /**
   * clears indexDB, local storage, cache and cache
   */
  const handleClearSiteData = async (reload = true) => {
    try {
      // clear local storage
      localStorage.clear();
      // clear session storage
      sessionStorage.clear();
      // clear cookies
      const allCookies = document.cookie.split(";");
      for (let i = 0; i < allCookies.length; i++) {
        const key = allCookies[i].split("=");
        document.cookie =
          key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }
      // clear indexed db
      const databases = await indexedDB.databases();
      databases.forEach((db) => {
        indexedDB.deleteDatabase(db.name);
      });

      if (reload) {
        location.reload();
      }
    } catch (error) {
      message.error("An error occurred while clearing storage");
      console.error(error);
    }
  };

  return (
    <>
      <ApolloProvider client={GraphQlClient}>
        <ShouldRender condition={panelVisible}>
          <div
            className="expandedPanel"
            style={{
              height: `${
                isLoggedIn() && selectedRegressionTest ? "100vh" : "auto"
              }`,
              maxHeight: `100vh`,
              overflowY: `${
                isLoggedIn() && selectedRegressionTest ? "scroll" : "auto"
              }`,
            }}
          >
            <PageHeader
              className="testPanelHeader"
              title="Test Panel"
              subtitle="select scenarios and run tests"
              extra={
                isLoggedIn() ? (
                  <>
                    <Button type="danger" onClick={handleClearSiteData}>
                      Clear site data
                    </Button>
                    ,
                    <DownOutlined onClick={() => setPanelVisible(false)} />
                  </>
                ) : (
                  <>
                    <Button type="danger" onClick={handleClearSiteData}>
                      Clear site data
                    </Button>
                    ,
                    <DownOutlined onClick={() => setPanelVisible(false)} />
                  </>
                )
              }
            />

            <Divider className="headerDivider" />

            <div>
              quick login:
              <br />
              <Input
                value={loginEmail}
                onChange={onEmailChange}
                placeholder={"E-mail"}
                e2e={"test-panel-login-input"}
                style={{ width: "60%", display: "inline-block" }}
              />{" "}
              &nbsp;
              <Button
                onClick={signMeIn}
                disabled={loginLoading}
                className="signMeInButton"
                style={{ width: "30%", display: "inline-block" }}
              >
                Sign Me In
              </Button>
            </div>

            <Divider className="headerDivider" />

            <Spin spinning={loading} style={{ width: "100%" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {
                  <div>
                    <Select
                      placeholder="Set state..."
                      style={{ width: "200px" }}
                      onChange={(val) => setSelectedState(val)}
                      allowClear
                      e2e="test-panel-action-switcher"
                    >
                      <Select.Option value="hcp">Create HCP</Select.Option>
                      <Select.Option value="hcf">Create HCF</Select.Option>
                      <Select.Option value="enableStripe">
                        Enable Stripe
                      </Select.Option>
                      <Select.Option value="paymentAnalytics">
                        Payment Analytics
                      </Select.Option>
                      <Select.Option value="uploadDocs">
                        Upload Required Documents
                      </Select.Option>
                      <Select.Option value="approveDocs">
                        Approve Pending Documents
                      </Select.Option>
                      <Select.Option value="shift">Shift Action</Select.Option>
                      <Select.Option value="shiftAssign">
                        Assign Shift
                      </Select.Option>
                      <Select.Option value="otp">Fetch OTP</Select.Option>
                      <Select.Option value="runService">
                        Run Event Service
                      </Select.Option>
                      <Select.Option value="csv">
                        Download Invoice CSV
                      </Select.Option>
                      <Select.Option value="qfa">Trigger Qfa</Select.Option>
                      <Select.Option value="hcpClockOut">
                        HCP Clock Out
                      </Select.Option>
                      <Select.Option value="hideTP">
                        Hide Test Panel for 1h
                      </Select.Option>
                    </Select>
                  </div>
                }
              </Space>
            </Spin>
          </div>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "hcp"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="hcpModal"
          >
            <CreateHcp />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "hcf"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="hcfModal"
          >
            <CreateHcf />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "shift"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="shiftActionModal"
          >
            <ShiftAction />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "hcpClockOut"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="shiftActionModal"
          >
            <HCPClockOut />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "shiftAssign"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="shiftAssign"
          >
            <AssignShift />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "enableStripe"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="enableStripeModal"
          >
            <EnableStripe />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "paymentAnalytics"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="enableStripeModal"
          >
            <PaymentAnalytics />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "runService"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="runService"
          >
            <RunService />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "uploadDocs"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="uploadDocs"
          >
            <UploadDocs />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "approveDocs"}
            maskClosable
            onCancel={() => setSelectedState(null)}
            className="uploadDocs"
          >
            <ApproveDocs />
          </Modal>
          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "csv"}
            maskClosable
            onCancel={() => setSelectedState(null)}
          >
            <DownloadCSV />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "otp"}
            maskClosable
            onCancel={() => setSelectedState(null)}
          >
            <FetchOTP />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={selectedState === "qfa"}
            maskClosable
            onCancel={() => setSelectedState(null)}
          >
            <TriggerQfa />
          </Modal>

          <Modal
            destroyOnClose
            centered
            footer={null}
            visible={showStatusModal}
            width="800px"
            maskClosable
            onCancel={() => setShowStatusModal(false)}
            title={
              <Row>
                <span>
                  {" "}
                  Running: <strong>{selectedTestTitle}</strong>
                </span>
              </Row>
            }
          >
            <ul>
              <li>
                <h2>
                  Creating HCP{" "}
                  {HCPCreated ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <LoadingOutlined twoToneColor="#eb2f96" />
                  )}{" "}
                </h2>
                {HCPCreated && (
                  <div>
                    <h3>A HCP has been generated</h3>
                    <p>Name: {rHcpData.name}</p>
                    <p>Email: {rHcpData.email}</p>
                    <p>Phone: {rHcpData.phone}</p>
                    <p>Status: {rHcpData.stage}</p>
                    <p>Timezone: {rHcpData.tmz}</p>
                  </div>
                )}
              </li>
              <li>
                <h2>
                  Creating HCF{" "}
                  {HCFCreated ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <LoadingOutlined twoToneColor="#eb2f96" />
                  )}
                </h2>
                {HCFCreated && (
                  <div>
                    <h3>A Facilty has been generated</h3>
                    <p>Facility Name: {rHcfData.name}</p>
                    <p>Facility Email: {rHcfData.email}</p>
                    <p>Facility Phone: {rHcfData.phone}</p>
                    <p>Facility Timezone: {rHcfData.tmz}</p>
                    <p>Facility Status: {rHcfData.status}</p>
                  </div>
                )}
              </li>
              <li>
                <h2>
                  Creating Shift{" "}
                  {ShiftCreated ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <LoadingOutlined twoToneColor="#eb2f96" />
                  )}
                </h2>
                {ShiftCreated && (
                  <div>
                    <h3>A shift has been created</h3>
                    <h3>
                      Shift assigned to {rHcpData.name}({rHcpData.email})
                    </h3>
                    <h3>Shift CLOCK_IN executed.</h3>
                    <p>Shift Id: {rShiftData._id}</p>
                    <p>Pay: {rShiftData.pay}</p>
                    <p>Start: {rShiftData.start}</p>
                    <p>End: {rShiftData.end}</p>
                    <p>Time: {rShiftData.time}</p>
                    <p>Facility Id: {rShiftData.facilityId}</p>
                  </div>
                )}
              </li>
            </ul>
          </Modal>
        </ShouldRender>
        <ShouldRender condition={!panelVisible}>
          <div className="minimizedPanel">
            <h2
              className="title"
              id={"authorize_button"}
              onClick={(e) => {
                setPanelVisible(true);
              }}
            >
              TP
            </h2>
          </div>
        </ShouldRender>
      </ApolloProvider>
    </>
  );
};

export { TestPanel };
