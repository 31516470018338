import { useEffect, useRef } from "react";

/**
 * The rational behind adding the component is, the actual `useEffect` clean-up function also runs on every function call of the
 * `useEffect` callback when dependencies are changed.
 * Ex:
 * React.useEffect(() => {
 *  console.info('Called when the dependency value are changed.');
 *  return () => {
 *    console.info('This is also called when the dependency values are changed.');
 *  };
 * }, [count]);
 * A short demonstration of this can be found (here)[https://stackblitz.com/edit/react-ts-srbvjg?file=App.tsx], open console to see the loggers.
 * @param callback callback function that need to be called, when the component in which this custom-hook is rendered is unmounting.
 * @param dependencies dependencies the component should listen to that ensures, the correct context of the variables/values is maintained.
 */
const useComponentWillUnmount = (callback, dependencies) => {
  const componentUnmounted = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      componentUnmounted.current = true;
    };
  }, []);

  useEffect(
    () => () => {
      if (componentUnmounted.current) callback?.();
    },
    [componentUnmounted.current, ...dependencies]
  );
};

export { useComponentWillUnmount };
