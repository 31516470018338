import { Button, Result } from "antd";
import React, { useEffect } from "react";

import meta from "../../utils/meta";
import { useDispatch } from "react-redux";
import { logout } from "../../modules/session";
import { useHistory } from "react-router-dom";

const AgentExperience = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workerOpenShiftsLink, workerWebApp } = meta();

  useEffect(() => {
    dispatch(logout());
    history.replace("/enter");
  }, []);

  return (
    <Result
      status="404"
      title="Mobile App"
      subTitle={
        <div>
          {
            "Clipboard Health Mobile App becomes the required method for requesting and picking up shifts. Having trouble downloading the app? Use: "
          }
          <a href={workerWebApp}>{workerWebApp}</a>
        </div>
      }
      extra={
        <Button type="primary" href={workerOpenShiftsLink}>
          Open App
        </Button>
      }
    />
  );
};

export { AgentExperience };
