import { FacilityTypes } from "./facility";

const WorkerTypes = {
  CNA: "CNA",
  LVN: "LVN",
  RN: "RN",
  NP: "NP",
  CAREGIVER: "CAREGIVER",
  PACU_RN: "PACU RN",
  OR_RN: "OR RN",
  SPD_TECH: "SPD TECH",
  SURG_TECH: "SURG TECH",
  CRNA: "CRNA",
  PHLEBOTOMIST: "Phlebotomist",
  MLT: "MLT",
  MEDICAL_ASSISTANT: "Medical Assistant",
  PT: "PT",
  PTA: "PTA",
  HHA: "HHA",
  EMT: "EMT",
  RESPIRATORY_THERAPIST: "Respiratory Therapist",
  PARAMEDIC: "Paramedic",
  NON_CLINICAL: "Non Clinical",
  PHARM_TECH: "Pharm Tech",
  MEDICAL_TECHNICIAN: "Medical Technician",
  QMAP: "QMAP",
  MEDICAL_AIDE: "Medical Aide",
  SITE_LEAD: "Site Lead",
};

const workerTypesOptions = [
  {
    label: "CAREGIVER",
    value: "CAREGIVER",
  },
  {
    label: "CNA",
    value: "CNA",
  },
  {
    label: "LVN / LPN",
    value: "LVN",
  },
  {
    label: "RN",
    value: "RN",
  },
  {
    label: "NP",
    value: "NP",
  },
  {
    label: "EMT",
    value: "EMT",
  },
  {
    label: "Respiratory Therapist",
    value: "Respiratory Therapist",
  },
  {
    label: "Paramedic",
    value: "Paramedic",
  },
  {
    label: "Non Clinical",
    value: "Non Clinical",
  },
  {
    label: "Pharm Tech",
    value: "Pharm Tech",
  },
  {
    label: "Medical Technician",
    value: "Medical Technician",
  },
];

const longTermCareWorkerTypesOptions = [
  {
    label: "CAREGIVER",
    value: "CAREGIVER",
  },
  {
    label: "CNA",
    value: "CNA",
  },
  {
    label: "LVN / LPN",
    value: "LVN",
  },
  {
    label: "RN",
    value: "RN",
  },
  {
    label: "NP",
    value: "NP",
  },
  {
    label: "Medical Technician",
    value: "Medical Technician",
  },
  {
    label: "QMAP",
    value: "QMAP",
  },
  {
    label: "Medical Aide",
    value: "Medical Aide",
  },
  {
    label: "Site Lead",
    value: "Site Lead",
  },
];

const psychHospitalWorkerTypesOptions = [
  {
    label: "CAREGIVER",
    value: "CAREGIVER",
  },
  {
    label: "CNA",
    value: "CNA",
  },
  {
    label: "LVN / LPN",
    value: "LVN",
  },
  {
    label: "RN",
    value: "RN",
  },
  {
    label: "NP",
    value: "NP",
  },
  {
    label: "Medical Technician",
    value: "Medical Technician",
  },
];

const HospitalWorkerOptions = [
  {
    label: "CNA",
    value: "CNA",
  },
  {
    label: "LVN / LPN",
    value: "LVN",
  },
  {
    label: "RN",
    value: "RN",
  },
  {
    label: "NP",
    value: "NP",
  },
  {
    label: "Phlebotomist",
    value: "Phlebotomist",
  },
  {
    label: "Medical Assistant",
    value: "Medical Assistant",
  },
];

const surgicalWorkerTypesOptions = [
  {
    label: "PACU RN",
    value: "PACU RN",
  },
  {
    label: "OR RN",
    value: "OR RN",
  },
  {
    label: "SPD TECH",
    value: "SPD TECH",
  },
  {
    label: "SURG TECH",
    value: "SURG TECH",
  },
  {
    label: "CRNA",
    value: "CRNA",
  },
];

const medicalLabWorkerOptions = [
  {
    label: "Phlebotomist",
    value: "Phlebotomist",
  },
  {
    label: "MLT",
    value: "MLT",
  },
  {
    label: "Medical Assistant",
    value: "Medical Assistant",
  },
  {
    label: "CNA",
    value: "CNA",
  },
  {
    label: "LVN / LPN",
    value: "LVN",
  },
  {
    label: "RN",
    value: "RN",
  },
  {
    label: "EMT",
    value: "EMT",
  },
  {
    label: "Respiratory Therapist",
    value: "Respiratory Therapist",
  },
  {
    label: "Paramedic",
    value: "Paramedic",
  },
  {
    label: "Non Clinical",
    value: "Non Clinical",
  },
  {
    label: "Pharm Tech",
    value: "Pharm Tech",
  },
  {
    label: "Medical Technician",
    value: "Medical Technician",
  },
  {
    label: "Medical Aide",
    value: "Medical Aide",
  },
];

const HHCAndHospiceWorkerTypeOptions = [
  {
    label: "PT",
    value: "PT",
  },
  {
    label: "PTA",
    value: "PTA",
  },
  {
    label: "HHA",
    value: "HHA",
  },
  {
    label: "LVN / LPN",
    value: "LVN",
  },
  {
    label: "RN",
    value: "RN",
  },
];

const workerTypes = workerTypesOptions.map((type) => type.value);
const longTermCareWorkerTypes = longTermCareWorkerTypesOptions.map(
  (type) => type.value
);
const psychHospitalWorkerTypes = psychHospitalWorkerTypesOptions.map(
  (type) => type.value
);
const newWorkerTypes = surgicalWorkerTypesOptions.map((type) => type.value);
const medicalLabWorkerTypes = medicalLabWorkerOptions.map((type) => type.value);
const HHAAndHospiceWorkerTypes = [
  ...HHCAndHospiceWorkerTypeOptions.map((type) => type.value),
  "LVN",
  "RN",
];
const HospitalWorkerTypes = HospitalWorkerOptions.map((type) => type.value);
const workerSpecialities = [
  "General Surgery",
  "Thoracic Surgery",
  "Colon and Rectal Surgery",
  "Obstetrics and Gynecology",
  "Gynecologic Oncology",
  "Neurological Surgery",
  "Ophthalmic Surgery",
  "Oral Surgery and Maxillofacial Surgery",
  "Minor Orthopaedic Surgery",
  "Total Orthopaedic Surgery",
  "Otolaryngology (ENT)",
  "Pediatric Surgery",
  "Plastic Surgery",
  "Urology",
  "Vascular Surgery",
];

const workerTypeObj = {
  CNA: "CNA",
  LVN: "LVN",
  RN: "RN",
  NP: "NP",
  CAREGIVER: "CAREGIVER",
  PACU_RN: "PACU RN",
  OR_RN: "OR RN",
  SPD_TECH: "SPD TECH",
  SURG_TECH: "SURG TECH",
  CRNA: "CRNA",
  PHLEBOTOMIST: "Phlebotomist",
  MLT: "MLT",
  MEDICAL_ASSISTANT: "Medical Assistant",
  PT: "PT",
  PTA: "PTA",
  HHA: "HHA",
};
const workerTypeObjWithLabel = {
  CNA: {
    label: "CNA",
    value: "CNA",
  },
  LVN: {
    label: "LVN / LPN",
    value: "LVN",
  },
  RN: {
    label: "RN",
    value: "RN",
  },
  NP: {
    label: "NP",
    value: "NP",
  },
  CAREGIVER: {
    label: "CAREGIVER",
    value: "CAREGIVER",
  },
  PACU_RN: {
    label: "PACU RN",
    value: "PACU RN",
  },
  OR_RN: {
    label: "OR RN",
    value: "OR RN",
  },
  SPD_TECH: {
    label: "SPD TECH",
    value: "SPD TECH",
  },
  SURG_TECH: {
    label: "SURG TECH",
    value: "SURG TECH",
  },
  CRNA: {
    label: "CRNA",
    value: "CRNA",
  },
  PHLEBOTOMIST: {
    label: "Phlebotomist",
    value: "Phlebotomist",
  },
  MLT: {
    label: "MLT",
    value: "MLT",
  },
  MEDICAL_ASSISTANT: {
    label: "Medical Assistant",
    value: "Medical Assistant",
  },
  PT: {
    label: "PT",
    value: "PT",
  },
  PTA: {
    label: "PTA",
    value: "PTA",
  },
  HHA: {
    label: "HHA",
    value: "HHA",
  },
  MEDICAL_TECHNICIAN: {
    label: "Medical Technician",
    value: "Medical Technician",
  },
  EMT: {
    label: "EMT",
    value: "EMT",
  },
  RESPIRATORY_THERAPIST: {
    label: "Respiratory Therapist",
    value: "Respiratory Therapist",
  },
  PARAMEDIC: {
    label: "Paramedic",
    value: "Paramedic",
  },
  NON_CLINICAL: {
    label: "Non Clinical",
    value: "Non Clinical",
  },
  PHARM_TECH: {
    label: "Pharm Tech",
    value: "Pharm Tech",
  },
  [WorkerTypes.QMAP]: {
    label: WorkerTypes.QMAP,
    value: WorkerTypes.QMAP,
  },
  [WorkerTypes.MEDICAL_AIDE]: {
    label: WorkerTypes.MEDICAL_AIDE,
    value: WorkerTypes.MEDICAL_AIDE,
  },
  [WorkerTypes.SITE_LEAD]: {
    label: WorkerTypes.SITE_LEAD,
    value: WorkerTypes.SITE_LEAD,
  },
};

const workerTypeDefaults = workerTypes.reduce(
  (acc, type) => ({ ...acc, [type]: 0 }),
  {}
);

const allWorkerTypes = Object.values(workerTypeObjWithLabel);
const workerTypesRates = (rates = workerTypeDefaults) =>
  workerTypes.map((type) => `$${rates[type] || 0}`).join(", ");

const FacilityWorkerType = {
  [FacilityTypes.LONG_TERM_CARE]: [...longTermCareWorkerTypes, "NURSE"],
  [FacilityTypes.SURGERY_CENTER]: newWorkerTypes,
  [FacilityTypes.HOSPICE]: HHAAndHospiceWorkerTypes,
  [FacilityTypes.HOME_HEALTHCARE]: HHAAndHospiceWorkerTypes,
  [FacilityTypes.PSYCH_HOSPITAL]: [...psychHospitalWorkerTypes, "NURSE"],
  [FacilityTypes.MEDICAL_LAB]: [...medicalLabWorkerTypes],
  [FacilityTypes.HOSPITAL]: HospitalWorkerTypes,
};

const WORKER_STAGES_ENUM = {
  ONBOARDING: "ONBOARDING",
  ENROLLED: "ENROLLED",
  PROBATION: "PROBATION",
  PAUSED: "PAUSED",
  SUSPENDED: "SUSPENDED",
};

const WORKER_STAGES = Object.values(WORKER_STAGES_ENUM);

const WORKER_STAGES_REASONS_ENUM = {
  [WORKER_STAGES_ENUM.PROBATION]: {
    ATTENDANCE_AND_TIMELINE: "ATTENDANCE_AND_TIMELINE",
    PROFESSIONAL_ETIQUETTE: "PROFESSIONAL_ETIQUETTE",
    CLINICAL_HEALTHCARE_TRAINING: "CLINICAL_HEALTHCARE_TRAINING",
    BEDSIDE_MANNER: "BEDSIDE_MANNER",
    OTHER: "OTHER",
    NCNS: "NCNS",
  },
  [WORKER_STAGES_ENUM.PAUSED]: {
    CORE_DOCUMENTS_OUTDATED: "CORE_DOCUMENTS_OUTDATED",
    PENDING_INCIDENT_REVIEW: "PENDING_INCIDENT_REVIEW",
    OTHER: "OTHER",
    NCNS: "NCNS",
    ATTENDANCE_RELATED_SUSPENSION_REVIEW:
      "ATTENDANCE_RELATED_SUSPENSION_REVIEW",
  },
  [WORKER_STAGES_ENUM.SUSPENDED]: {
    ATTENDANCE_ISSUES: "ATTENDANCE_ISSUES",
    EXCESSIVE_DNRS: "EXCESSIVE_DNRS",
    MINOR_MAJOR_OFFENSE: "MINOR_MAJOR_OFFENSE",
    HCP_CHOSE_TO_SUSPEND: "HCP_CHOSE_TO_SUSPEND",
    UNQUALIFIED: "UNQUALIFIED",
    OTHER: "OTHER",
    NCNS: "NCNS",
  },
};

const WORKER_STAGES_REASONS = {
  [WORKER_STAGES_ENUM.PROBATION]: [
    ...Object.values(WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION]),
  ],
  [WORKER_STAGES_ENUM.PAUSED]: [
    ...Object.values(WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PAUSED]),
  ],
  [WORKER_STAGES_ENUM.SUSPENDED]: [
    ...Object.values(WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED]),
  ],
};

const WORKER_STAGES_REASONS_DATA = {
  [WORKER_STAGES_ENUM.PROBATION]: {
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION]
      .ATTENDANCE_AND_TIMELINE]: {
      description: "Attendance & Timeliness Issues",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION]
      .PROFESSIONAL_ETIQUETTE]: {
      description: "Professional Etiquette Needs Improvement",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION]
      .CLINICAL_HEALTHCARE_TRAINING]: {
      description: "Clinical & Healthcare Training Needs Improvement",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].BEDSIDE_MANNER]: {
      description: "Bedside Manner Needs Improvement",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].OTHER]: {
      description: "Other Complaints, please explain",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].NCNS]: {
      description: "No Call No Show",
      mandatoryNote: false,
    },
  },
  [WORKER_STAGES_ENUM.PAUSED]: {
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PAUSED]
      .CORE_DOCUMENTS_OUTDATED]: {
      description: "Core Documents Outdated",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PAUSED]
      .PENDING_INCIDENT_REVIEW]: {
      description: "Pending Incident Review ",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PAUSED].OTHER]: {
      description: "Other, please explain",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PAUSED]
      .ATTENDANCE_RELATED_SUSPENSION_REVIEW]: {
      description: "Pending HCP Attendance-Related Suspension Review",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PAUSED].NCNS]: {
      description: "No Call No Show",
      mandatoryNote: false,
    },
  },
  [WORKER_STAGES_ENUM.SUSPENDED]: {
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED]
      .ATTENDANCE_ISSUES]: {
      description: "Attendance Issues",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED].EXCESSIVE_DNRS]: {
      description: "Excessive Do-Not-Return Requests",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED]
      .MINOR_MAJOR_OFFENSE]: {
      description: "Minor or Major Offense",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED]
      .HCP_CHOSE_TO_SUSPEND]: {
      description: "Healthcare Professional chose to Suspend, explain why",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED].UNQUALIFIED]: {
      description: "Unqualified",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED].OTHER]: {
      description: "Other, please explain",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.SUSPENDED].NCNS]: {
      description: "No Call No Show",
      mandatoryNote: false,
    },
  },
};

const hcpNamAssignmentCases = {
  NEAREST_FACILITY: "NEAREST_FACILITY",
  LEGACY_FACILITY: "LEGACY_FACILITY",
  NO_LOCATION: "NO_LOCATION",
  NO_NEAREST_FACILITY: "NO_NEAREST_FACILITY",
  MANUAL: "MANUAL",
};

const hcpNamAssignmentCaseList = Object.values(hcpNamAssignmentCases);

const getWorkerShiftTypeLabel = (shiftType) => {
  const label = workerTypeObjWithLabel[shiftType]
    ? workerTypeObjWithLabel[shiftType].label
    : shiftType;
  return typeof label === "string" ? label : JSON.stringify(label);
};

export {
  workerTypes,
  workerTypeDefaults,
  workerTypesRates,
  workerSpecialities,
  newWorkerTypes,
  allWorkerTypes,
  workerTypeObj,
  WorkerTypes,
  FacilityWorkerType,
  WORKER_STAGES_ENUM,
  WORKER_STAGES,
  WORKER_STAGES_REASONS_ENUM,
  WORKER_STAGES_REASONS,
  WORKER_STAGES_REASONS_DATA,
  hcpNamAssignmentCases,
  hcpNamAssignmentCaseList,
  workerTypesOptions,
  medicalLabWorkerOptions,
  surgicalWorkerTypesOptions,
  medicalLabWorkerTypes,
  HHCAndHospiceWorkerTypeOptions,
  HospitalWorkerOptions,
  getWorkerShiftTypeLabel,
  psychHospitalWorkerTypesOptions,
  longTermCareWorkerTypesOptions,
};
